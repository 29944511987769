import React from 'react';
import { FieldProps, Field } from 'react-final-form';

/**
 * Оборачивает контрол в Field из `react-final-form`.
 * @param Control Тип контрола.
 */
export default function createFieldControl<
  TValue,
  TElement extends HTMLElement,
  TProps extends {}
>(
  Control: React.ComponentType<TProps>,
  fieldProps: Partial<FieldProps<TValue, TElement>> = {}
): React.FC<FieldProps<TValue, TElement> & TProps> {
  return props => (
    <Field
      {...props}
      {...fieldProps}
      render={({ input, ...restProps }) => (
        <Control {...(input as any)} {...restProps} />
      )}
    />
  );
}
