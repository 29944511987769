import { createReducer } from 'typesafe-actions';
import { CollectionCategoriesState, CollectionCategoriesAction } from './types';
import { fetchCollectionCategoriesAsync } from './actions';

export const initialState: CollectionCategoriesState = {
  isPending: false,
  isFulfilled: false
};

export default createReducer<
  CollectionCategoriesState,
  CollectionCategoriesAction
>(initialState)
  .handleAction(fetchCollectionCategoriesAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(
    fetchCollectionCategoriesAsync.success,
    (state, { payload }) => ({
      ...state,
      isPending: false,
      isFulfilled: true,
      categories: payload
    })
  );
