import { createReducer } from 'typesafe-actions';
import { ApplicationState, ApplicationAction } from './types';
import {
  fetchApplicationAsync,
  fetchApplicationCollectionsAsync,
  fetchApplicationDictionariesAsync,
  editApplicationAsync
} from './actions';

export const initialState: ApplicationState = {
  isPending: false,
  collectionsIds: [],
  collectionsCount: 0,
  dictionariesIds: [],
  dictionariesCount: 0
};

export default createReducer<ApplicationState, ApplicationAction>(initialState)
  .handleAction(fetchApplicationAsync.request, (state, { payload }) => {
    return state.id !== payload
      ? {
          ...initialState,
          id: payload,
          isPending: true
        }
      : {
          ...state,
          isPending: true,
          error: initialState.error
        };
  })
  .handleAction(fetchApplicationAsync.failure, (state, { payload }) => ({
    ...state,
    isPending: false,
    error: payload
  }))
  .handleAction(
    [fetchApplicationAsync.success, editApplicationAsync.success],
    state => ({
      ...state,
      isPending: false
    })
  )
  .handleAction(
    [
      fetchApplicationCollectionsAsync.request,
      fetchApplicationDictionariesAsync.request
    ],
    state => ({
      ...state,
      isPending: true
    })
  )
  .handleAction(
    fetchApplicationCollectionsAsync.success,
    (state, { payload }) => ({
      ...state,
      isPending: false,
      collectionsCount: payload.count ?? state.collectionsCount,
      collectionsIds: payload.records.map(collection => collection.id)
    })
  )
  .handleAction(
    fetchApplicationDictionariesAsync.success,
    (state, { payload }) => ({
      ...state,
      isPending: false,
      dictionariesCount: payload.count ?? state.dictionariesCount,
      dictionariesIds: payload.records.map(dictionary => dictionary.id)
    })
  );
