import { createAsyncAction } from 'typesafe-actions';
import { AppThunk } from 'store';
import * as api from 'services/api';
import CountableRecords from 'types/CountableRecords';
import { toCountableRecords } from 'services/network';
import PaginationParams from 'types/PaginationParams';

export const fetchApplicationRequestsAsync = createAsyncAction(
  'admin/FETCH_APPLICATION_REQUESTS_REQUEST',
  'admin/FETCH_APPLICATION_REQUESTS_SUCCESS',
  'admin/FETCH_APPLICATION_REQUESTS_FAILURE'
)<void, CountableRecords<OpenAPI.BaseApplicationRequest>, any>();

export const updateApplicationRequestAsync = createAsyncAction(
  'admin/UPDATE_APPLICATION_REQUEST_REQUEST',
  'admin/UPDATE_APPLICATION_REQUEST_SUCCESS',
  'admin/UPDATE_APPLICATION_REQUEST_FAILURE'
)<void, OpenAPI.BaseApplicationRequest, any>();

export const fetchApplicationRequests = (
  token: string,
  params?: PaginationParams
): AppThunk<Promise<
  CountableRecords<OpenAPI.BaseApplicationRequest>
>> => async dispatch => {
  try {
    dispatch(fetchApplicationRequestsAsync.request());
    const response = await api.getAdminApplicationRequests(token, params);
    const data = toCountableRecords(response);
    dispatch(fetchApplicationRequestsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationRequestsAsync.failure(error));
    throw error;
  }
};

export const updateApplicationRequest = (
  token: string,
  id: OpenAPI.BaseApplicationRequest['id'],
  values: OpenAPI.UpdateApplicationRequest
): AppThunk<Promise<OpenAPI.BaseApplicationRequest>> => async dispatch => {
  try {
    dispatch(updateApplicationRequestAsync.request());
    const response = await api.updateAdminApplicationRequest(token, id, values);
    dispatch(updateApplicationRequestAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(updateApplicationRequestAsync.failure(error));
    throw error;
  }
};
