import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import PersonApplicationDTO from 'types/PersonApplicationDTO';
import * as api from 'services/api';
import { AppThunk } from 'store';

export const fetchApplicationsAsync = createAsyncAction(
  'user/FETCH_APPLICATIONS_REQUEST',
  'user/FETCH_APPLICATIONS_SUCCESS',
  'user/FETCH_APPLICATIONS_FAILURE'
)<void, PersonApplicationDTO[], any>();

export const setApplicationFilter = createStandardAction(
  'user/SET_APPLICAITON_FILTER'
)<PersonApplicationDTO['id'] | undefined>();

/**
 * Загружает приложения, связанные с персоной.
 * @param token Токен персоны.
 */
export const fetchApplications = (
  token: string
): AppThunk<Promise<PersonApplicationDTO[]>> => async dispatch => {
  try {
    dispatch(fetchApplicationsAsync.request());
    const response = await api.getPersonApplications(token);
    dispatch(fetchApplicationsAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationsAsync.failure(error));
    throw error;
  }
};
