/**
 * Открывает страницу с авторизацией.
 */
export const openAuthPage = () => window.location.replace(`/api/auth/begin`);

/**
 * Выполняет logout на leader-id.ru.
 * Hack.
 */
export const logoutLeaderId = () =>
  fetch('https://leader-id.ru/logout/', { credentials: 'include' });
