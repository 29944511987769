import ApplicationDTO from 'types/ApplicationDTO';
import { useSelector } from 'react-redux';
import { AppState } from 'store/rootReducer';

/**
 * Хук для получения информации о приложении.
 * @param id ID приложения.
 */
export default function useApplication(id?: ApplicationDTO['id']) {
  return useSelector(
    (state: AppState) => state.developer.applications.byId[id!]
  );
}
