import { createReducer } from 'typesafe-actions';
import { ReportsState, ReportsAction } from './types';
import { fetchReportsAsync, readReportAsync } from './actions';

export const initialState: ReportsState = {
  isPending: false,
  count: 0,
  byId: {},
  ids: []
};

export default createReducer<ReportsState, ReportsAction>(initialState)
  .handleAction(fetchReportsAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(fetchReportsAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    count: payload.count ?? state.count,
    ids: payload.records.map(report => report.id),
    byId: payload.records.reduce(
      (acc, report) => ({ ...acc, [report.id]: report }),
      state.byId
    )
  }))
  .handleAction(fetchReportsAsync.failure, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(readReportAsync.success, (state, { payload }) => ({
    ...state,
    byId: state.byId[payload]
      ? {
          ...state.byId,
          [payload]: {
            ...state.byId[payload],
            viewed_at: new Date().toISOString()
          }
        }
      : state.byId
  }));
