import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';

/**
 * Возвращает список приложений.
 */
export const getApplications = createSelector(
  (state: AppState) => state.developer.applications,
  applicationsState =>
    applicationsState.ids.map(id => applicationsState.byId[id]!)
);
