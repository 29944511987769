import { createReducer } from 'typesafe-actions';
import { PersonApplicationsState, PersonApplicationsAction } from './types';
import { fetchPersonApplicationsAsync } from './actions';

export const initialState: PersonApplicationsState = {
  byPersonId: {}
};

export default createReducer<PersonApplicationsState, PersonApplicationsAction>(
  initialState
).handleAction(
  fetchPersonApplicationsAsync.success,
  (state, { payload: [id, applications] }) => ({
    ...state,
    byPersonId: {
      ...state.byPersonId,
      [id]: applications
    }
  })
);
