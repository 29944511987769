import CollectionValuesDTO from 'types/CollectionValuesDTO';
import CollectionObjectDTO from 'types/CollectionObjectDTO';

/**
 * Конвертирует массив значений в массив объектов.
 * @param values Массив значений с полем.
 */
export const toCollectionObjects = (
  values: CollectionValuesDTO[]
): CollectionObjectDTO[] => {
  const objectsMap = values.reduce<
    Partial<
      Record<
        CollectionObjectDTO[0],
        Record<CollectionValuesDTO[0], CollectionValuesDTO[1]>
      >
    >
  >((acc, [field, values]) => {
    values.forEach(value => {
      const objectId = value.object_id || '';
      const fields = acc[objectId] || {};
      fields[field] = [...(fields[field] || []), value];
      acc[objectId] = fields;
    });

    return acc;
  }, {});

  return Object.entries(objectsMap).map(([key, fields]) => [
    key,
    Object.entries(fields!)
  ]);
};

/**
 * Получает количество значений.
 * @param values Значения.
 */
export const getValuesCount = (values: CollectionValuesDTO[]) =>
  values.reduce((count, [_, fieldValues]) => count + fieldValues.length, 0);
