import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';

import Routes from 'types/Routes';

import PagePlaceholder from 'components/PagePlaceholder';
import { onUnauthorized } from 'services/network';

import configureStore, { history } from './store';
import AppRoutes from './AppRoutes';
import AppPopups from './AppPopups';
import AppMeta from './AppMeta';

const { store, persistor } = configureStore();

// Если токен истек.
onUnauthorized(() => history.replace(Routes.LOGOUT));

const App: React.FC = () => (
  // Suspense for i18n xhr.
  <Suspense fallback={<PagePlaceholder />}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <HelmetProvider>
            <AppMeta />
            <AppRoutes />
            <AppPopups />
          </HelmetProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </Suspense>
);

export default App;
