import { createAsyncAction } from 'typesafe-actions';
import { AppThunk } from 'store';
import * as api from 'services/api';
import AdminApplicationDTO from 'types/AdminApplicationDTO';
import CountableRecords from 'types/CountableRecords';
import AdminCollectionDTO from 'types/AdminCollectionDTO';
import { toCountableRecords } from 'services/network';
import AdminCollectionsParams from 'types/AdminCollectionsParams';
import AdminDictionaryDTO from 'types/AdminDictionaryDTO';
import AdminDictionariesParams from 'types/AdminDictionariesParams';

export const fetchApplicationAsync = createAsyncAction(
  'admin/FETCH_APPLICATION_REQUEST',
  'admin/FETCH_APPLICATION_SUCCESS',
  'admin/FETCH_APPLICATION_FAILURE'
)<OpenAPI.Parameters.ApplicationId, OpenAPI.ApplicationFull, any>();

export const fetchApplicationCollectionsAsync = createAsyncAction(
  'admin/FETCH_APPLICATION_COLLECTIONS_REQUEST',
  'admin/FETCH_APPLICATION_COLLECTIONS_SUCCESS',
  'admin/FETCH_APPLICATION_COLLECTIONS_FAILURE'
)<void, CountableRecords<AdminCollectionDTO>, any>();

export const fetchApplicationDictionariesAsync = createAsyncAction(
  'admin/FETCH_APPLICATION_DICTIONARIES_REQUEST',
  'admin/FETCH_APPLICATION_DICTIONARIES_SUCCESS',
  'admin/FETCH_APPLICATION_DICTIONARIES_FAILURE'
)<void, CountableRecords<AdminDictionaryDTO>, any>();

/**
 * Загружает приложения.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const fetchApplication = (
  id: OpenAPI.Parameters.ApplicationId,
  token: string
): AppThunk<Promise<OpenAPI.ApplicationFull>> => async dispatch => {
  try {
    dispatch(fetchApplicationAsync.request(id));
    const response = await api.getAdminApplication(id, token);
    dispatch(fetchApplicationAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает коллекции приложения.
 * @param id ID приложения.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const fetchApplicationCollections = (
  id: AdminApplicationDTO['id'],
  token: string,
  params?: AdminCollectionsParams
): AppThunk<Promise<
  CountableRecords<AdminCollectionDTO>
>> => async dispatch => {
  try {
    dispatch(fetchApplicationCollectionsAsync.request());
    const response = await api.getAdminApplicationCollections(
      id,
      token,
      params
    );
    const data = toCountableRecords(response);
    dispatch(fetchApplicationCollectionsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationCollectionsAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает справочники приложения.
 * @param id ID приложения.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const fetchApplicationDictionaries = (
  id: AdminApplicationDTO['id'],
  token: string,
  params?: AdminDictionariesParams
): AppThunk<Promise<
  CountableRecords<AdminDictionaryDTO>
>> => async dispatch => {
  try {
    dispatch(fetchApplicationDictionariesAsync.request());
    const response = await api.getAdminApplicationDictionaries(
      id,
      token,
      params
    );
    const data = toCountableRecords(response);
    dispatch(fetchApplicationDictionariesAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationDictionariesAsync.failure(error));
    throw error;
  }
};
