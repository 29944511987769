import { createReducer } from 'typesafe-actions';
import { CreateRequestState, CreateRequestAction } from './types';
import {
  openCreateRequest,
  closeCreateRequest,
  createRequestAsync
} from './actions';

export const initialState: CreateRequestState = {};

export default createReducer<CreateRequestState, CreateRequestAction>(
  initialState
)
  .handleAction(openCreateRequest, (state, { payload }) => ({
    ...state,
    data: payload
  }))
  .handleAction(closeCreateRequest, () => initialState)
  .handleAction(createRequestAsync.success, (state, { payload }) => ({
    ...state,
    request: payload
  }))
  .handleAction(createRequestAsync.failure, (state, { payload }) => ({
    ...state,
    error: payload
  }));
