import { createReducer } from 'typesafe-actions';
import { DictionaryCategoriesState, DictionaryCategoriesAction } from './types';
import { fetchDictionaryCategoriesAsync } from './actions';

export const initialState: DictionaryCategoriesState = {
  isPending: false,
  isFulfilled: false
};

export default createReducer<
  DictionaryCategoriesState,
  DictionaryCategoriesAction
>(initialState)
  .handleAction(fetchDictionaryCategoriesAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(
    fetchDictionaryCategoriesAsync.success,
    (state, { payload }) => ({
      ...state,
      isPending: false,
      isFulfilled: true,
      categories: payload
    })
  );
