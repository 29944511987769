import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { AuthState } from './auth/types';
import { PersonState } from './person/types';

import auth, { authPersistConfig } from './auth/reducer';
import person, { personPersistConfig } from './person/reducer';
import admin, { AdminState } from './admin/reducer';
import user, { UserState } from './user/reducer';
import developer, { DeveloperState } from './developer/reducer';

export type AppState = {
  router: RouterState;
  auth: AuthState & PersistPartial;
  person: PersonState & PersistPartial;
  admin: AdminState;
  user: UserState;
  developer: DeveloperState;
};

const rootReducer = (history: History) =>
  combineReducers<AppState>({
    router: connectRouter(history),
    auth: persistReducer(authPersistConfig, auth),
    person: persistReducer(personPersistConfig, person),
    user,
    developer,
    admin
  });

export default rootReducer;
