import TreeModel from 'tree-model';
import arrayToTree from 'array-to-tree';
import DictionaryElementDTO from 'types/DictionaryElementDTO';
import omit from 'lodash/omit';

/** Имя свойства, в котором находится ID родителя. */
const PARENT_PROPERTY = 'parent';

export type DictionaryElementNode = TreeModel.Node<DictionaryElementDTO> & {
  model: DictionaryElementDTO;
  children?: Array<DictionaryElementNode>;
};

/**
 * Описывает разницу между двумя наборами элементов.
 */
export type DictionaryElementsDiff = {
  create: DictionaryElementDTO[];
  update: DictionaryElementDTO[];
  delete: DictionaryElementDTO[];
  deprecate: DictionaryElementDTO[];
};

/**
 * Добавляет поле children на основе `PARENT_PROPERTY`.
 * @param elements Массив элементов.
 */
export const withChildren = (elements: DictionaryElementDTO[]) =>
  arrayToTree(elements, { parentProperty: PARENT_PROPERTY });

/**
 * Приводит массив элементов к древовидной структуре.
 * @param elements Массив элементов.
 */
export const toTree = (elements: DictionaryElementDTO[]) => {
  const root = {
    id: 0,
    name: '',
    children: withChildren(elements)
  };

  return new TreeModel().parse(root) as DictionaryElementNode;
};

/**
 * Устанавливает новые данные элемента.
 * Если элемент был обновлён, то возвращает его обновленную версию.
 * @param root Корневой узел.
 * @param element Данные элемента.
 */
export const updateElementNode = (
  root: DictionaryElementNode,
  element: DictionaryElementDTO
): DictionaryElementNode | undefined => {
  const node: DictionaryElementNode | undefined = root.first(
    node => (node as DictionaryElementNode).model.id === element.id
  ) as any;

  if (node) {
    node.model = element;
    return node;
  }
};

/**
 * Возвращает массив всех элементов из корневого узла.
 * @param root Корневой узел.
 */
export const toElementsArray = (
  root: DictionaryElementNode
): DictionaryElementDTO[] => {
  const nodes = root.all(node => !node.isRoot());
  return nodes.map(node => (node as DictionaryElementNode).model);
};

/**
 * Создает узел элемента.
 * @param data Данные элемента.
 */
export const createElementNode = (data: Partial<DictionaryElementDTO> = {}) =>
  new TreeModel().parse({
    id: -Date.now(),
    name: '',
    ...data
  }) as DictionaryElementNode;

/**
 * Проверяет, что элемент является локальным.
 * @param element Элемент.
 */
export const isLocalElement = (element: DictionaryElementDTO) => element.id < 0;

/**
 * Сравнивает два массива элементов и возвращает разницу.
 * TODO: write tests.
 * @param original Оригинал.
 * @param compare Новая версия для сравнения.
 */
export const getElementsDiff = (
  original: DictionaryElementDTO[] = [],
  compare: DictionaryElementDTO[] = []
): DictionaryElementsDiff => {
  const diff: DictionaryElementsDiff = {
    create: [],
    update: [],
    delete: [],
    deprecate: []
  };
  const originalMap: Partial<Record<
    string,
    DictionaryElementDTO
  >> = original.reduce((acc, el) => ({ ...acc, [el.id]: el }), {});
  const compareMap: Partial<Record<
    string,
    DictionaryElementDTO
  >> = compare.reduce((acc, el) => ({ ...acc, [el.id]: el }), {});
  const compareKeys = Object.keys(compareMap);

  compareKeys.forEach(compareId => {
    const originalElement = originalMap[compareId];
    const compareElement = compareMap[compareId]!;

    if (!originalElement) {
      return diff.create.push(compareElement);
    }

    if (originalElement.name !== compareElement.name) {
      diff.update.push(compareElement);
    }
    if (originalElement.deprecated !== compareElement.deprecated) {
      diff.deprecate.push(compareElement);
    }
  });

  const deletedMap: Record<string, DictionaryElementDTO> = omit(
    originalMap,
    compareKeys
  );

  // Сейчас используется children_action=remove при удалении (потом скорее всего будет выбор).
  // Нам не нужно возвращать детей при удалении родителя.
  diff.delete = Object.values(deletedMap).filter(
    element => !element.parent || !deletedMap[element.parent]
  );

  return diff;
};

export const getMergeActions = (diff: DictionaryElementsDiff) => {
  const actions: OpenAPI.RequestMergeAction[] = [];
  const newElementsMap = withChildren(diff.create).reduce((acc, element) => {
    const parent = acc[element.parent!];

    if (parent) {
      parent.push(element);
    } else {
      acc[element.parent!] = [element];
    }

    return acc;
  }, {} as Partial<Record<string, DictionaryElementDTO[]>>);

  Object.entries(newElementsMap).forEach(([key, elements]) => {
    actions.push({
      action: 'add',
      element: Number(key) || undefined,
      new_elements: elements
    });
  });

  diff.update.forEach(element => {
    actions.push({
      action: 'change',
      element: element.id,
      new_name: element.name
    });
  });

  diff.deprecate.forEach(element => {
    actions.push({
      action: 'deprecate',
      element: element.id
    });
  });

  return actions;
};
