import { createAsyncAction } from 'typesafe-actions';
import { AppThunk } from 'store';
import CountableRecords from 'types/CountableRecords';
import AdminMergeDTO from 'types/AdminMergeDTO';
import MergesParams from 'types/MergesParams';

import * as api from 'services/api';
import { toCountableRecords } from 'services/network';

export const fetchMergesAsync = createAsyncAction(
  'admin/FETCH_MERGES_REQUEST',
  'admin/FETCH_MERGES_SUCCESS',
  'admin/FETCH_MERGES_FAILURE'
)<void, CountableRecords<AdminMergeDTO>, any>();

export const fetchMergeAsync = createAsyncAction(
  'admin/FETCH_MERGE_REQUEST',
  'admin/FETCH_MERGE_SUCCESS',
  'admin/FETCH_MERGE_FAILURE'
)<void, AdminMergeDTO, any>();

export const acceptMergeAsync = createAsyncAction(
  'admin/ACCEPT_MERGE_REQUEST',
  'admin/ACCEPT_MERGE_SUCCESS',
  'admin/ACCEPT_MERGE_FAILURE'
)<void, AdminMergeDTO['id'], any>();

export const declineMergeAsync = createAsyncAction(
  'admin/DECLINE_MERGE_REQUEST',
  'admin/DECLINE_MERGE_SUCCESS',
  'admin/DECLINE_MERGE_FAILURE'
)<void, AdminMergeDTO['id'], any>();

/**
 * Загружает merges.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const fetchMerges = (
  token: string,
  params?: MergesParams
): AppThunk<Promise<CountableRecords<AdminMergeDTO>>> => async dispatch => {
  try {
    dispatch(fetchMergesAsync.request());
    const response = await api.getAdminMerges(token, params);
    const data = toCountableRecords(response);
    dispatch(fetchMergesAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchMergesAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает merge.
 * @param id Merge id.
 * @param token Токен пользователя.
 */
export const fetchMerge = (
  id: AdminMergeDTO['id'],
  token: string
): AppThunk<Promise<AdminMergeDTO>> => async dispatch => {
  try {
    dispatch(fetchMergeAsync.request());
    const mergeResponse = await api.getAdminMerge(id, token);
    const dictionaryResponse = await api.getAdminDictionary(
      mergeResponse.data.dictionary,
      token
    );
    const data: AdminMergeDTO = {
      ...mergeResponse.data,
      dictionary_object: dictionaryResponse.data as AdminMergeDTO['dictionary_object']
    };
    dispatch(fetchMergeAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchMergeAsync.failure(error));
    throw error;
  }
};

/**
 * Принимает merge.
 * @param id Merge id.
 * @param token Токен пользователя.
 */
export const acceptMerge = (
  id: AdminMergeDTO['id'],
  token: string
): AppThunk<Promise<void>> => async dispatch => {
  try {
    dispatch(acceptMergeAsync.request());
    await api.acceptAdminMerge(id, token);
    dispatch(acceptMergeAsync.success(id));
  } catch (error) {
    console.error(error);
    dispatch(acceptMergeAsync.failure(error));
    throw error;
  }
};

/**
 * Отклоняет merge.
 * @param id Merge id.
 * @param token Токен пользователя.
 */
export const declineMerge = (
  id: AdminMergeDTO['id'],
  token: string
): AppThunk<Promise<void>> => async dispatch => {
  try {
    dispatch(declineMergeAsync.request());
    await api.declineAdminMerge(id, token);
    dispatch(declineMergeAsync.success(id));
  } catch (error) {
    console.error(error);
    dispatch(declineMergeAsync.failure(error));
    throw error;
  }
};
