import { createAction, createStandardAction } from 'typesafe-actions';
import { ReportPopupPayload } from 'components/ReportPopup/ReportPopup';

/**
 * Открывает репорт.
 * @param payload Report payload.
 */
export const openReport = createStandardAction('user/OPEN_REPORT')<
  ReportPopupPayload
>();

/**
 * Закрывает репорт.
 */
export const closeReport = createAction('user/CLOSE_REPORT');
