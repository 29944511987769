import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DictionaryDTO from 'types/DictionaryDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import DictionaryElementDTO from 'types/DictionaryElementDTO';

import Modal from 'components/Modal';
import DictionaryListItem from 'components/DictionaryListItem';
import RequestCounter from 'components/RequestCounter';
import CreateRequestForm from 'components/CreateRequestForm';
import ErrorMessage from 'components/ErrorMessage';

import { toTree, toElementsArray } from 'services/elements';

import './create-request-popup.scss';

type Props = {
  onSubmit?: React.ComponentProps<typeof CreateRequestForm>['onSubmit'];
  dictionary: DictionaryDTO;
  application: ApplicationDTO;
  actions?: OpenAPI.RequestMergeAction[];
  error?: React.ReactNode;
} & React.ComponentProps<typeof Modal>;

const CreateRequestPopup: React.FC<Props> = ({
  className,
  onSubmit,
  dictionary,
  application,
  error,
  actions = [],
  ...restProps
}) => {
  const { t } = useTranslation();
  const counters = useMemo(
    () =>
      actions.reduce(
        (acc, action) => {
          if (action.action === 'add') {
            const newElementsTree = toTree(
              action.new_elements as DictionaryElementDTO[]
            );
            acc[action.action] += toElementsArray(newElementsTree).length;
          } else {
            acc[action.action]++;
          }

          return acc;
        },
        { add: 0, change: 0, deprecate: 0 }
      ),
    [actions]
  );

  return (
    <Modal className="create-request-popup" {...restProps}>
      <div className="create-request-popup__header">
        <div className="create-request-popup__title">
          {t('Создание реквеста на изменение справочника')}
        </div>
        <DictionaryListItem
          className="create-request-popup__dictionary"
          dictionary={dictionary}
          application={application}
        />
        <div className="create-request-popup__counters">
          <RequestCounter
            className="create-request-popup__counter create-request-popup__counter--add"
            title={t('Строк\nдобавлено')}
            value={counters.add}
          />
          <RequestCounter
            className="create-request-popup__counter create-request-popup__counter--change"
            title={t('Строк\nизменено')}
            value={counters.change}
          />
          <RequestCounter
            className="create-request-popup__counter create-request-popup__counter--deprecate"
            title={t('Строк помечено\nнеактуальными')}
            value={counters.deprecate}
          />
        </div>
      </div>
      <CreateRequestForm
        className="create-request-popup__form"
        onSubmit={onSubmit}
      />
      {error && (
        <ErrorMessage className="create-request-popup__error">
          {error}
        </ErrorMessage>
      )}
    </Modal>
  );
};

export default CreateRequestPopup;
