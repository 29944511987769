import { createReducer, ActionType } from 'typesafe-actions';
import { ApplicationState, ApplicationAction } from './types';
import {
  fetchApplicationAsync,
  fetchApplicationCollectionsAsync,
  fetchApplicationDictionariesAsync
} from './actions';
import { readReportAsync } from '../reports/actions';

export const initialState: ApplicationState = {
  isPending: false,
  collections: { count: 0, records: [] },
  dictionaries: { count: 0, records: [] }
};

export default createReducer<
  ApplicationState,
  ApplicationAction | ActionType<typeof readReportAsync['success']>
>(initialState)
  .handleAction(fetchApplicationAsync.request, (state, { payload }) => ({
    ...state,
    isPending: true,
    data: state.data?.id === payload ? state.data : initialState.data
  }))
  .handleAction(fetchApplicationAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    data: payload
  }))
  .handleAction(fetchApplicationAsync.failure, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(readReportAsync.success, (state, { payload }) => ({
    ...state,
    data: state.data && {
      ...state.data,
      messages_application_list: state.data.messages_application_list?.map(
        message =>
          message.id === payload
            ? { ...message, viewed_at: new Date().toISOString() }
            : message
      )
    }
  }))
  .handleAction(
    fetchApplicationCollectionsAsync.success,
    (state, { payload }) => ({
      ...state,
      collections: {
        count: payload.count,
        records: payload.records.map(collection => collection.id)
      }
    })
  )
  .handleAction(
    fetchApplicationDictionariesAsync.success,
    (state, { payload }) => ({
      ...state,
      dictionaries: {
        count: payload.count,
        records: payload.records.map(dictionary => dictionary.id)
      }
    })
  );
