import React from 'react';
import clsx from 'clsx';
import { FieldMetaState } from 'react-final-form';

import './input.scss';

type Props = React.ComponentProps<'input'> & {
  meta?: FieldMetaState<string>;
  error?: string;
};

const Input: React.FC<Props> = ({ className, meta, error, ...restProps }) => (
  <input
    className={clsx(
      'input',
      {
        'input--error': error || (meta && meta.touched && meta.error)
      },
      className
    )}
    type="text"
    {...restProps}
  />
);

export default Input;
