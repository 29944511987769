import React from 'react';
import clsx from 'clsx';
import ReactSelect from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { Props as SelectProps } from 'react-select/src/Select';

import ApplicationDTO from 'types/ApplicationDTO';

import './header-application-select.scss';

type Props = SelectProps<ApplicationDTO>;

const components: Partial<SelectComponents<ApplicationDTO>> = {
  IndicatorSeparator: null,
  DropdownIndicator: ({ selectProps, hasValue }) => (
    <i
      className={clsx(
        'header-application-select__dropdown',
        selectProps.menuIsOpen && 'header-application-select__dropdown--opened',
        hasValue && 'header-application-select__dropdown--has-value'
      )}
    />
  ),
  SingleValue: ({ selectProps }) => {
    const application = selectProps.value as ApplicationDTO | undefined;
    return (
      <div className="header-application-select__single-value">
        {application && (
          <div className="header-application-select__application">
            <div
              className="header-application-select__application-logo"
              style={{ backgroundImage: `url(${application.logo})` }}
            />
            <div className="header-application-select__application-name">
              {application.name}
            </div>
          </div>
        )}
      </div>
    );
  },
  Option: ({ innerRef, innerProps, data, isSelected, isFocused }) => {
    const application = data as ApplicationDTO;
    return (
      <div
        className={clsx(
          'header-application-select__option',
          isSelected && 'header-application-select__option--selected',
          isFocused && 'header-application-select__option--focused'
        )}
        ref={innerRef}
        {...innerProps}
      >
        {application && (
          <div className="header-application-select__application">
            <div
              className="header-application-select__application-logo"
              style={{ backgroundImage: `url(${application.logo})` }}
            />
            <div className="header-application-select__application-name">
              {application.name}
            </div>
          </div>
        )}
      </div>
    );
  }
};

const HeaderApplicationSelect: React.FC<Props> = ({
  className,
  ...restProps
}) => {
  return (
    <ReactSelect
      components={components}
      className={clsx('header-application-select', className)}
      classNamePrefix="header-application-select"
      isSearchable={false}
      getOptionValue={option => String(option.id)}
      getOptionLabel={option => option.name as string}
      {...restProps}
    />
  );
};

export default HeaderApplicationSelect;
