import { createReducer, ActionType } from 'typesafe-actions';
import {
  editCollectionAccessAsync,
  fetchCollectionAccessAsync
} from 'store/developer/collectionAccess/actions';
import {
  fetchCollectionAsync,
  editCollectionAsync
} from 'store/developer/collection/actions';
import { fetchApplicationCollectionsAsync } from 'store/developer/application/actions';

import { CollectionsState, CollectionsAction } from './types';
import { fetchCollectionsAsync, createCollectionAsync } from './actions';

export const initialState: CollectionsState = {
  isPending: false,
  error: null,
  count: 0,
  byId: {},
  ids: []
};

export default createReducer<
  CollectionsState,
  | CollectionsAction
  | ActionType<
      | typeof editCollectionAccessAsync['success']
      | typeof editCollectionAsync['success']
      | typeof fetchCollectionAsync['success']
      | typeof fetchApplicationCollectionsAsync['success']
      | typeof fetchCollectionAccessAsync['success']
    >
>(initialState)
  .handleAction(createCollectionAsync.request, state => ({
    ...state,
    isPending: true,
    error: null
  }))
  .handleAction(fetchCollectionsAsync.request, state => ({
    ...state,
    isPending: true,
    error: null
  }))
  .handleAction(fetchCollectionsAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    error: null,
    count: payload.count ?? state.count,
    byId: payload.records.reduce(
      (acc, collection) => ({ ...acc, [collection.id]: collection }),
      state.byId
    ),
    ids: payload.records.map(collection => collection.id)
  }))
  .handleAction(createCollectionAsync.success, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(createCollectionAsync.failure, (state, { payload }) => ({
    ...state,
    isPending: false,
    error: payload
  }))
  .handleAction(
    [
      fetchCollectionAsync.success,
      editCollectionAccessAsync.success,
      editCollectionAsync.success,
      fetchCollectionAccessAsync.success
    ],
    (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.id]: {
          ...state.byId[payload.id],
          ...payload
        }
      }
    })
  )
  .handleAction(
    fetchApplicationCollectionsAsync.success,
    (state, { payload }) => ({
      ...state,
      byId: payload.records.reduce(
        (acc, collection) => ({
          ...acc,
          [collection.id]: { ...acc[collection.id], ...collection }
        }),
        state.byId
      )
    })
  );
