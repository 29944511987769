import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';

/** Возвращает токен персоны */
export const getPersonToken = createSelector(
  (state: AppState) => state.auth,
  auth => auth.response?.token
);

export const getTokenRole = createSelector(
  (state: AppState) => state.auth,
  ({ response }) => response?.role
);
