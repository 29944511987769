import CollectionDTO from 'types/CollectionDTO';
import CollectionAccessType from 'types/CollectionAccessType';
import i18n from 'i18n';
import vars from 'static/styles/vars.scss';

/**
 * Возвращает цвет типа доступа.
 * @param access_type Тип доступа.
 */
export const getAccessTypeColor = (access_type: CollectionDTO['access_type']) =>
  ({
    public_read: '#4A4A4A',
    public_write: vars['color-primary'],
    private: vars['color-secondary']
  }[access_type]);

/**
 * Возвращает название типа доступа.
 * @param access_type Тип доступа.
 */
export const getAccessTypeTitle = (access_type: CollectionDTO['access_type']) =>
  ({
    public_read: i18n.t('Чтение'),
    public_write: i18n.t('Запись'),
    private: i18n.t('Уникальный')
  }[access_type]);

/**
 * Возвращает опции для выбора типа доступа к коллекции.
 */
export const getAccessTypeOptions = (): Array<{
  label: string;
  value: CollectionAccessType;
}> => [
  { label: i18n.t('Чтение'), value: 'public_read' },
  { label: i18n.t('Чтение и запись'), value: 'public_write' },
  { label: i18n.t('Уникальные'), value: 'private' }
];

/**
 * Возвращает опции для выбора типа доступа к коллекции (radio).
 * @param access_type Текущий тип доступа.
 */
export const getAccessTypeRadioOptions = (
  access_type?: CollectionAccessType
) => {
  const options: Array<{
    label: string;
    value: CollectionAccessType;
  }> = [
    { label: i18n.t('Приватная'), value: 'private' },
    { label: i18n.t('Только чтение'), value: 'public_read' },
    { label: i18n.t('Запись'), value: 'public_write' }
  ];

  return options.map(option => ({
    ...option,
    disabled: access_type
      ? getAccessTypeOrder(option.value) < getAccessTypeOrder(access_type)
      : false
  }));
};

/**
 * Возвращает опции для выбора типа поля коллекции.
 */
export const getFieldTypeOptions = (): Array<{
  label: string;
  value: OpenAPI.RequestField['type'];
}> => [
  { label: 'String', value: 'string' },
  { label: 'Integer', value: 'integer' },
  { label: 'Float', value: 'float' },
  { label: 'Date', value: 'date' },
  { label: 'DateTime', value: 'datetime' },
  { label: 'Dictionary', value: 'dictionary' },
  { label: 'File', value: 'file' }
];

/**
 * Возвращает количество полей коллекции.
 * @param collection Коллекция.
 */
export const getFieldsCount = (collection: CollectionDTO) =>
  collection.fields ? collection.fields.length : collection.fields_count || 0;

/**
 * Возвращает порядок прав доступа.
 * private -> public_read -> public_write
 * @param access_type
 */
export const getAccessTypeOrder = (access_type: CollectionDTO['access_type']) =>
  ({
    private: 0,
    public_read: 1,
    public_write: 2
  }[access_type]);
