import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withTypes } from 'react-final-form';

import PersonCollectionDTO from 'types/PersonCollectionDTO';
import CollectionValueDTO from 'types/CollectionValueDTO';

import CloseableModal from 'components/CloseableModal';
import Heading from 'components/Heading';
import Label from 'components/Label';
import FieldTextarea from 'components/FieldTextarea';
import Button from 'components/Button';
import Loader from 'components/Loader';
import FieldInput from 'components/FieldInput';

import * as validate from 'utils/validate';

import './report-popup.scss';

export type SupportMessageValues = Pick<
  OpenAPI.RequestSupportMessage,
  'message' | 'subject'
>;

export type ReportPopupPayload = {
  application?: PersonCollectionDTO['provider'];
  collection?: Partial<PersonCollectionDTO>;
  value?: CollectionValueDTO;
  field?: OpenAPI.ResponseField;
};

type Props = {
  payload?: ReportPopupPayload;
  onSubmit?: (values: OpenAPI.RequestSupportMessage) => any;
} & React.ComponentProps<typeof CloseableModal>;

const { Form } = withTypes<SupportMessageValues>();

const ReportPopup: React.FC<Props> = ({
  payload,
  onRequestClose,
  onSubmit,
  ...restProps
}) => {
  const { t, i18n } = useTranslation();

  const handleSubmit = useCallback(
    async (values: SupportMessageValues) =>
      onSubmit?.({
        ...values,
        application_id: payload?.application?.id,
        collection_id: payload?.collection?.id,
        object_id: payload?.value?.object_id,
        value_id: payload?.value?.id
      }),
    [onSubmit, payload]
  );

  const initialValues = useMemo<SupportMessageValues | undefined>(() => {
    if (typeof payload?.value !== 'undefined' && payload.collection) {
      return {
        subject: i18n.t('Некорректные данные в коллекции «{{collection}}»', {
          collection: payload.collection.name
        }),
        message: `${
          payload.value.object_id
            ? `Object ID: ${payload.value.object_id}\n`
            : ''
        }${i18n.t(
          'Значение ({{value}}) в поле «{{field}}» является некорректным.',
          { value: payload.value.value, field: payload.field?.name }
        )}`
      };
    }

    if (payload?.collection) {
      return {
        subject: i18n.t('Некорректные данные в коллекции «{{collection}}»', {
          collection: payload.collection.name
        }),
        message: ''
      };
    }

    if (payload?.application) {
      return {
        subject: i18n.t('Жалоба на приложение «{{application}}»', {
          application: payload.application.name
        }),
        message: ''
      };
    }
  }, [i18n, payload]);

  return (
    <CloseableModal
      className="report-popup"
      onRequestClose={onRequestClose}
      {...restProps}
    >
      <Heading className="report-popup__title">
        {t('Укажите проблему с данными')}
      </Heading>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Label className="report-popup__label" htmlFor="subject">
              {t('Тема сообщения')}
            </Label>
            <FieldInput
              className="report-popup__input"
              id="subject"
              name="subject"
            />
            <Label className="report-popup__label" htmlFor="message">
              {t('Ваше сообщение')}
            </Label>
            <FieldTextarea
              autoFocus
              validate={validate.required}
              id="message"
              name="message"
              className="report-popup__textarea"
            />
            <div className="report-popup__footer">
              <Button
                type="submit"
                disabled={invalid || submitting}
                theme="primary"
                variant="contained"
              >
                {t('Отправить сообщение')}
              </Button>
              {submitting && (
                <React.Fragment>
                  <Loader className="report-popup__pending-loader" />
                  {t('Отправляем')}..
                </React.Fragment>
              )}
            </div>
          </form>
        )}
      />
    </CloseableModal>
  );
};

export default ReportPopup;
