import { createAsyncAction } from 'typesafe-actions';
import { AppThunk } from 'store';
import * as api from 'services/api';

type SuccessPayload = [
  OpenAPI.Parameters.CollectionId,
  OpenAPI.ResponseField[]
];

export const fetchFieldsAsync = createAsyncAction(
  'admin/FETCH_COLLECTION_FIELDS_REQUEST',
  'admin/FETCH_COLLECTION_FIELDS_SUCCESS',
  'admin/FETCH_COLLECTION_FIELDS_FAILURE'
)<void, SuccessPayload, any>();

export const fetchFields = (
  id: OpenAPI.Parameters.CollectionId,
  token: string
): AppThunk<Promise<SuccessPayload>> => async dispatch => {
  try {
    dispatch(fetchFieldsAsync.request());
    const response = await api.getAdminCollectionFields(id, token);
    const data: SuccessPayload = [id, response.data];
    dispatch(fetchFieldsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchFieldsAsync.failure(error));
    throw error;
  }
};
