import { createAsyncAction } from 'typesafe-actions';

import CollectionDTO from 'types/CollectionDTO';
import ApplicationDTO from 'types/ApplicationDTO';

import * as api from 'services/api';
import CollectionFieldDTO from 'types/CollectionFieldDTO';
import { getErrorMessage } from 'services/network';
import { AppThunk } from 'store';

export const editCollectionAsync = createAsyncAction(
  'developer/EDIT_COLLECTION_REQUEST',
  'developer/EDIT_COLLECTION_SUCCESS',
  'developer/EDIT_COLLECTION_FAILURE'
)<void, CollectionDTO, any>();

export const fetchCollectionAsync = createAsyncAction(
  'developer/FETCH_COLLECTION_REQUEST',
  'developer/FETCH_COLLECTION_SUCCESS',
  'developer/FETCH_COLLECTION_FAILURE'
)<CollectionDTO['id'], CollectionDTO, any>();

export const editCollection = (
  id: CollectionDTO['id'],
  collection: OpenAPI.RequestCollection,
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<CollectionDTO | undefined>> => async (
  dispatch,
  getState
) => {
  try {
    const data = getState().developer.collections.byId[id];

    if (!data) return;

    dispatch(editCollectionAsync.request());

    // Создаём новые поля.
    const newFieldsPromises = (collection.fields || [])
      .filter(field => typeof (field as CollectionFieldDTO).id === 'undefined')
      .map(field => api.createCollectionField(data.id, field, access_token));

    await Promise.all(newFieldsPromises);

    // Обновляем имя/описание/категорию.
    const response = await api.updateCollection(
      data.id,
      collection,
      access_token
    );

    dispatch(editCollectionAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(editCollectionAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

export const changeField = (
  id: CollectionDTO['id'],
  fieldID: OpenAPI.ResponseField['id'],
  fieldName: OpenAPI.ResponseField['name'],
  fieldDescription: OpenAPI.ResponseField['description'],
  access_token: ApplicationDTO['access_token']
): AppThunk => async (dispatch, getState) => {
  try {
    const data = getState().developer.collections.byId[id];

    if (!data) return;

    // Обновляем данные поля коллекции
    await api.changeCollectionField(
      data.id,
      fieldID,
      { name: fieldName, description: fieldDescription },
      access_token
    );
  } catch (error) {
    console.error(error);
    dispatch(editCollectionAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

export const fetchCollection = (
  id: CollectionDTO['id'],
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<CollectionDTO>> => async dispatch => {
  try {
    dispatch(fetchCollectionAsync.request(id));
    const response = await api.getCollection(id, access_token);
    dispatch(fetchCollectionAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionAsync.failure(error));
    throw error;
  }
};
