import React from 'react';
import clsx from 'clsx';

import './heading.scss';

type Props = {
  component?: React.ComponentType | React.ElementType;
} & React.HTMLAttributes<HTMLHeadingElement>;

const Heading: React.FC<Props> = ({
  component: Component = 'h2',
  className,
  ...restProps
}) => (
  <Component className={clsx('heading', className)} {...(restProps as any)} />
);

export default Heading;
