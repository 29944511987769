import i18n from 'i18n';

// Validation functions.
export const MIN_NAME_LENGTH = 3;

/**
 * Проверяет, что обязательное поле является заполненным.
 * @param value Значение поля.
 */
export const required = (value: any) => {
  const message = i18n.t('Обязательно');

  switch (typeof value) {
    case 'string':
      return value.trim() ? undefined : message;
    default:
      return value ? undefined : message;
  }
};

/**
 * Валидирует имя.
 * @param value Имя.
 */
export const name = (value: any) => {
  switch (typeof value) {
    case 'string':
      return value.trim().length < MIN_NAME_LENGTH
        ? i18n.t('Минимальная длина имени: {{length}} символа', {
            length: MIN_NAME_LENGTH
          })
        : undefined;
    default:
      return true;
  }
};

export const number = (value: any) =>
  value && isNaN(Number(value)) ? i18n.t('Должно быть числом') : undefined;
