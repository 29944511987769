import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import RequestDTO from 'types/RequestDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import * as api from 'services/api';
import { toCountableRecords } from 'services/network';
import CountableRecords from 'types/CountableRecords';
import RequestsFilter from 'types/RequestsFilter';
import { AppThunk } from 'store';

export const fetchRequestsAsync = createAsyncAction(
  'developer/FETCH_REQUESTS_REQUEST',
  'developer/FETCH_REQUESTS_SUCCESS',
  'developer/FETCH_REQUESTS_FAILURE'
)<void, CountableRecords<RequestDTO>, any>();

export const acceptRequestAsync = createAsyncAction(
  'developer/ACCEPT_REQUEST_REQUEST',
  'developer/ACCEPT_REQUEST_SUCCESS',
  'developer/ACCEPT_REQUEST_FAILURE'
)<void, RequestDTO['id'], any>();

export const declineRequestAsync = createAsyncAction(
  'developer/DECLINE_REQUEST_REQUEST',
  'developer/DECLINE_REQUEST_SUCCESS',
  'developer/DECLINE_REQUEST_FAILURE'
)<void, RequestDTO['id'], any>();

/**
 * Загружает реквесты.
 * @param access_token Токен приложения.
 */
export const fetchRequests = (
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<CountableRecords<RequestDTO>>> => async dispatch => {
  try {
    dispatch(fetchRequestsAsync.request());
    const data: CountableRecords<RequestDTO> = { count: 0, records: [] };

    do {
      const response = await api.getReceivedRequests(access_token, {
        offset: data.records.length
      });
      const responseData = toCountableRecords(response);

      if (!responseData.records.length) break;

      data.count = responseData.count ?? data.count;
      data.records = [...data.records, ...responseData.records];
    } while (data.count! > data.records.length);

    dispatch(fetchRequestsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchRequestsAsync.failure(error));
    throw error;
  }
};

export const fetchRequestAsync = createAsyncAction(
  'developer/FETCH_REQUEST_ACTIONS_REQUEST',
  'developer/FETCH_REQUEST_ACTIONS_SUCCESS',
  'developer/FETCH_REQUEST_ACTIONS_FAILURE'
)<void, RequestDTO, any>();

export const fetchRequest = (
  id: RequestDTO['id'],
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<RequestDTO>> => async dispatch => {
  try {
    dispatch(fetchRequestAsync.request());
    const requestResponse = await api.getRequest(id, access_token);
    const actionsResponse = await api.getRequestActions(
      requestResponse.data.id,
      access_token
    );
    const data = { ...requestResponse.data, actions: actionsResponse.data };
    dispatch(fetchRequestAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchRequestAsync.failure(error));
    throw error;
  }
};

/**
 * Принимает входящий реквест.
 * @param id ID реквеста.
 * @param access_token Токен приложения.
 */
export const acceptRequest = (
  id: RequestDTO['id'],
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<void>> => async dispatch => {
  try {
    dispatch(acceptRequestAsync.request());
    await api.acceptRequest(id, access_token);
    dispatch(acceptRequestAsync.success(id));
  } catch (error) {
    console.error(error);
    dispatch(acceptRequestAsync.failure(error));
    throw error;
  }
};

/**
 * Отменяет входящий реквест.
 * @param id
 * @param access_token
 */
export const declineRequest = (
  id: RequestDTO['id'],
  access_token: ApplicationDTO['access_token']
): AppThunk => async dispatch => {
  try {
    dispatch(declineRequestAsync.request());
    await api.declineRequest(id, access_token);
    dispatch(declineRequestAsync.success(id));
  } catch (error) {
    console.error(error);
    dispatch(declineRequestAsync.failure(error));
    throw error;
  }
};

/**
 * Устанавливает фильтр для реквестов.
 * @param filter Фильтр.
 */
export const setRequestsFilter = createStandardAction(
  'developer/SET_REQUESTS_FILTER'
)<RequestsFilter>();
