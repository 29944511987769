import { createReducer, ActionType } from 'typesafe-actions';
import {
  editApplicationAsync,
  fetchApplicationAsync,
  fetchApplicationCollectionsAsync,
  fetchApplicationDictionariesAsync
} from 'store/developer/application/actions';
import { fetchCollectionsAsync } from 'store/developer/collections/actions';
import { fetchDictionariesAsync } from 'store/developer/dictionaries/actions';
import ApplicationDTO from 'types/ApplicationDTO';

import { ApplicationsState, ApplicationsAction } from './types';
import { fetchApplicationsListAsync } from './actions';

export const initialState: ApplicationsState = {
  isPending: false,
  isFulfilled: false,
  error: null,
  byId: {},
  ids: []
};

export default createReducer<
  ApplicationsState,
  | ApplicationsAction
  | ActionType<
      | typeof editApplicationAsync['success']
      | typeof fetchApplicationAsync['success']
      | typeof fetchApplicationCollectionsAsync['success']
      | typeof fetchApplicationDictionariesAsync['success']
      | typeof fetchCollectionsAsync['success']
      | typeof fetchDictionariesAsync['success']
    >
>(initialState)
  .handleAction(fetchApplicationsListAsync.request, state => ({
    ...state,
    error: initialState.error,
    isPending: true
  }))
  .handleAction(fetchApplicationsListAsync.failure, (state, { payload }) => ({
    ...state,
    isPending: false,
    error: payload
  }))
  .handleAction(fetchApplicationsListAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    isFulfilled: true,
    byId: payload.reduce(
      (acc, application) => ({ ...acc, [application.id]: application }),
      {}
    ),
    ids: payload.map(application => application.id)
  }))
  .handleAction(
    [editApplicationAsync.success, fetchApplicationAsync.success],
    (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.id]: {
          ...state.byId[payload.id],
          ...payload,
          access_token:
            state.byId[payload.id]?.access_token ?? payload.access_token
        }
      }
    })
  )
  .handleAction(
    [
      fetchApplicationCollectionsAsync.success,
      fetchApplicationDictionariesAsync.success,
      fetchCollectionsAsync.success,
      fetchDictionariesAsync.success
    ],
    (state, { payload }) => ({
      ...state,
      byId: (payload.records as Array<{
        application_object: ApplicationDTO;
      }>).reduce(
        (acc, record) => ({
          ...acc,
          [record.application_object.id]: {
            ...acc[record.application_object.id],
            ...record.application_object
          }
        }),
        state.byId
      )
    })
  );
