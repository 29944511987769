import React from 'react';
import clsx from 'clsx';
import './button.scss';

type Props = {
  /**
   * Тип кнопки.
   * По умолчанию: `text`.
   */
  variant?: 'text' | 'outlined' | 'contained' | 'fab';
  /**
   * Тема кнопки.
   * По умолчанию: `default`.
   */
  theme?: 'primary' | 'default';
  /**
   * Отключает тему кнопки.
   * Используйте, если хотите переопределить цвета и другие атрибуты.
   * По умолчанию: `false`.
   */
  disableTheme?: boolean;
  /**
   * Убирает встроенный padding.
   * По умолчанию: `false`.
   */
  disablePadding?: boolean;
} & React.ComponentProps<'button'>;

const Button: React.FC<Props> = ({
  className,
  variant = 'text',
  theme = 'default',
  disablePadding = false,
  disableTheme = false,
  children,
  ...restProps
}) => (
  <button
    type="button"
    className={clsx(
      'button',
      `button--${variant}`,
      !disableTheme && `button--${theme}`,
      disablePadding && `button--disable-padding`,
      className
    )}
    {...restProps}
  >
    {children}
  </button>
);

export default Button;
