import { createReducer } from 'typesafe-actions';
import { StatisticsState, StatisticsAction } from './types';
import {
  fetchStatisticsAsync,
  setStatisticsMetric,
  setStatisticsPeriod
} from './actions';

export const initialState: StatisticsState = {
  isPending: false,
  period: 'year',
  metric: 'reading_requests'
};

export default createReducer<StatisticsState, StatisticsAction>(initialState)
  .handleAction(fetchStatisticsAsync.request, state => ({
    ...state,
    error: initialState.error,
    isPending: true
  }))
  .handleAction(fetchStatisticsAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    metrics: payload
  }))
  .handleAction(fetchStatisticsAsync.failure, (state, { payload }) => ({
    ...state,
    isPending: false,
    error: payload
  }))
  .handleAction(setStatisticsMetric, (state, { payload }) => ({
    ...state,
    metric: payload
  }))
  .handleAction(setStatisticsPeriod, (state, { payload }) => ({
    ...state,
    period: payload
  }));
