import React from 'react';
import ReactModal from 'react-modal';
import clsx from 'clsx';

import './modal.scss';

type Props = React.ComponentProps<typeof ReactModal>;

const Modal: React.FC<Props> = ({
  children,
  className,
  overlayClassName,
  bodyOpenClassName,
  ...restProps
}) => (
  <ReactModal
    className={clsx('modal', className)}
    overlayClassName={clsx('modal__overlay', overlayClassName)}
    bodyOpenClassName={clsx('modal__body', bodyOpenClassName)}
    ariaHideApp={false}
    closeTimeoutMS={500}
    {...restProps}
  >
    {children}
  </ReactModal>
);
export default Modal;
