import {
  createAsyncAction,
  createStandardAction,
  createAction
} from 'typesafe-actions';
import { AppThunk } from 'store';
import * as api from 'services/api';

export const fetchApplicationFilterAsync = createAsyncAction(
  'admin/FETCH_APPLICATION_FILTER_REQUEST',
  'admin/FETCH_APPLICATION_FILTER_SUCCESS',
  'admin/FETCH_APPLICATION_FILTER_FAILURE'
)<void, OpenAPI.ApplicationInline[], any>();

export const changeApplicationFilter = createStandardAction(
  'admin/CHANGE_APPLICATION_FILTER'
)<Array<OpenAPI.ApplicationInline['id']>>();

export const showAllApplicationsFilter = createAction(
  'admin/SHOW_ALL_APPLICATION_FILTERS'
);

/**
 * Загружает приложения для фильтра.
 * @param token Токен пользователя.
 */
export const fetchApplicationFilter = (
  token: string
): AppThunk<Promise<OpenAPI.ApplicationInline[]>> => async dispatch => {
  try {
    dispatch(fetchApplicationFilterAsync.request());
    const response = await api.getAdminApplications(token);
    dispatch(fetchApplicationFilterAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationFilterAsync.failure(error));
    throw error;
  }
};
