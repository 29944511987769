import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CreateRequestFormValues } from 'components/CreateRequestForm/CreateRequestForm';
import MessagePopup from 'components/MessagePopup';

import { AppState } from 'store/rootReducer';
import {
  closeCreateRequest,
  createRequest
} from 'store/developer/createRequest/actions';
import { getCurrentApplication } from 'store/developer/currentApplication/selectors';

import { getElementsDiff, getMergeActions } from 'services/elements';

import useDictionary from 'hooks/useDictionary';
import useApplication from 'hooks/useApplication';

import CreateRequestPopup from './CreateRequestPopup';

type Props = Omit<
  React.ComponentProps<typeof CreateRequestPopup>,
  'dictionary' | 'application' | 'isOpen' | 'actions' | 'error'
>;

const ConnectedCreateRequestPopup: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const data = useSelector(
    (state: AppState) => state.developer.createRequest.data
  );
  const error = useSelector(
    (state: AppState) => state.developer.createRequest.error
  );
  const request = useSelector(
    (state: AppState) => state.developer.createRequest.request
  );
  const currentApplication = useSelector(getCurrentApplication);
  const dictionary = useDictionary(data && data.id);
  const application = useApplication(dictionary && dictionary.application);
  const onRequestClose = useCallback(() => dispatch(closeCreateRequest()), [
    dispatch
  ]);
  const { t } = useTranslation();
  const actions = useMemo(() => {
    if (data && dictionary) {
      const diff = getElementsDiff(dictionary.elements, data.elements);
      return getMergeActions(diff);
    }

    return [];
  }, [data, dictionary]);

  const handleSubmit = useCallback(
    (values: CreateRequestFormValues) => {
      if (data && currentApplication) {
        dispatch(
          createRequest(
            data.id,
            { ...values, actions },
            currentApplication.access_token
          )
        );
      }
    },
    [actions, currentApplication, data, dispatch]
  );

  if (request) {
    return (
      <MessagePopup
        isOpen
        title={t('Ваш запрос успешно отправлен')}
        submitText={t('Отлично')}
        onRequestClose={onRequestClose}
      />
    );
  }

  return dictionary && application ? (
    <CreateRequestPopup
      {...props}
      onRequestClose={onRequestClose}
      isOpen
      dictionary={dictionary}
      application={application}
      onSubmit={handleSubmit}
      actions={actions}
      error={error}
    />
  ) : null;
};

export default ConnectedCreateRequestPopup;
