import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as api from 'services/api';
import PersonCollectionValueParams from 'types/PersonCollectionValuesParams';
import CollectionValueDTO from 'types/CollectionValueDTO';

import { AppThunk } from 'store';

export const fetchCollectionValuesAsync = createAsyncAction(
  'user/FETCH_COLLECTION_VALUES_REQUEST',
  'user/FETCH_COLLECTION_VALUES_SUCCESS',
  'user/FETCH_COLLECTION_VALUES_FAILURE'
)<void, OpenAPI.PrivateResponsePersonValues[], any>();

export const fetchFileValueSuccess = createStandardAction(
  'user/FETCH_FILE_VALUE_SUCCESS'
)<OpenAPI.FileValue, CollectionValueDTO['id']>();
/**
 * Загружает данные коллекции, ассоциированные с персоной.
 * @param params Параметры.
 * @param token Токен персоны.
 */
export const fetchCollectionValues = (
  params: PersonCollectionValueParams,
  token: string
): AppThunk<Promise<
  OpenAPI.PrivateResponsePersonValues[]
>> => async dispatch => {
  try {
    dispatch(fetchCollectionValuesAsync.request());
    const response = await api.getListCollectionValues(params, token);
    dispatch(fetchCollectionValuesAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionValuesAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает значение с файлом.
 * @param valueID ID значения.
 * @param fileId ID файла.
 * @param token Токен персоны.
 */
export const fetchFileValue = (
  valueId: CollectionValueDTO['id'],
  fileId: OpenAPI.Parameters.FileId,
  token: string
): AppThunk<Promise<OpenAPI.FileValue>> => async dispatch => {
  try {
    const response = await api.getPersonCollectionFile(fileId, token);
    dispatch(fetchFileValueSuccess(response.data, valueId));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
