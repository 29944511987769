import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import { AppThunk } from 'store';
import AdminPersonDTO from 'types/AdminPersonDTO';

type SuccessPayload = [AdminPersonDTO['id'], OpenAPI.AppPersonApplication[]];

export const fetchPersonApplicationsAsync = createAsyncAction(
  'admin/FETCH_PERSON_APPLICATIONS_REQUEST',
  'admin/FETCH_PERSON_APPLICATIONS_SUCCESS',
  'admin/FETCH_PERSON_APPLICATIONS_FAILURE'
)<void, SuccessPayload, any>();

/**
 * Загружает приложения, связанные с персоной.
 * @param id ID персоны.
 * @param token Токен пользователя.
 */
export const fetchPersonApplications = (
  id: AdminPersonDTO['id'],
  token: string
): AppThunk<Promise<SuccessPayload>> => async dispatch => {
  try {
    dispatch(fetchPersonApplicationsAsync.request());
    const response = await api.getAdminPersonApplications(id, token);
    const data: SuccessPayload = [id, response.data];
    dispatch(fetchPersonApplicationsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchPersonApplicationsAsync.failure(error));
    throw error;
  }
};
