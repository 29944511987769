import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router';

import Routes from 'types/Routes';
import PersonRole from 'types/PersonRole';

import PrivateRoute from 'components/PrivateRoute';
import PagePlaceholder from 'components/PagePlaceholder';

const IndexPage = React.lazy(() => import('pages/IndexPage'));
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'));
const LogoutPage = React.lazy(() => import('pages/LogoutPage'));
const AuthCompletePage = React.lazy(() => import('pages/AuthCompletePage'));
const PlaygroundPage =
  process.env.NODE_ENV === 'development' &&
  React.lazy(() => import('pages/Playground'));

const ApplicationsPage = React.lazy(() => import('pages/ApplicationsPage'));
const CollectionsPage = React.lazy(() => import('pages/CollectionsPage'));
const DictionariesPage = React.lazy(() => import('pages/DictionariesPage'));
const ApplicationPage = React.lazy(() => import('pages/ApplicationPage'));
const CollectionsAddPage = React.lazy(() => import('pages/CollectionsAddPage'));
const DictionariesAddPage = React.lazy(() =>
  import('pages/DictionariesAddPage')
);
const CollectionsEditPage = React.lazy(() =>
  import('pages/CollectionsEditPage')
);
const DictionariesEditPage = React.lazy(() =>
  import('pages/DictionariesEditPage')
);
const ApplicationsRequestsPage = React.lazy(() =>
  import('pages/ApplicationsRequestsPage')
);
const RequestPage = React.lazy(() => import('pages/RequestPage'));

const UserCollectionPage = React.lazy(() => import('pages/UserCollectionPage'));
const CollectionsManagementPage = React.lazy(() =>
  import('pages/CollectionsManagementPage')
);

const AdminRequestsPage = React.lazy(() => import('pages/AdminRequestsPage'));
const AdminMergePage = React.lazy(() => import('pages/AdminMergePage'));
const AdminApplicationsPage = React.lazy(() =>
  import('pages/AdminApplicationsPage')
);
const AdminDictionaryPage = React.lazy(() =>
  import('pages/AdminDictionaryPage')
);
const AdminApplicationPage = React.lazy(() =>
  import('pages/AdminApplicationPage')
);
const AdminPersonsPage = React.lazy(() => import('pages/AdminPersonsPage'));
const AdminPersonApplicationsPage = React.lazy(() =>
  import('pages/AdminPersonApplicationsPage')
);
const AdminPersonCollectionsPage = React.lazy(() =>
  import('pages/AdminPersonCollectionsPage')
);
const AdminCollectionPage = React.lazy(() =>
  import('pages/AdminCollectionPage')
);
const AdminPersonValuesPage = React.lazy(() =>
  import('pages/AdminPersonValuesPage')
);
const AdminApplicationRequestsPage = React.lazy(() =>
  import('pages/AdminApplicationRequestsPage')
);

const USER: PersonRole[] = ['user'];
const DEVELOPER: PersonRole[] = ['developer'];
const ADMIN_OR_MODERATOR: PersonRole[] = ['moderator', 'administrator'];

const AppRoutes: React.FC = () => (
  <Suspense fallback={<PagePlaceholder />}>
    <Switch>
      <PrivateRoute path={Routes.INDEX} exact component={IndexPage} />

      {/* Moderator/Admin */}
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_APPLICATION_REQUESTS}
        component={AdminApplicationRequestsPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_REQUESTS_MERGE}
        component={AdminMergePage}
      />
      <PrivateRoute
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_REQUESTS}
        component={AdminRequestsPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_APPLICATIONS}
        component={AdminApplicationsPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_APPLICATION_COLLECTION}
        component={AdminCollectionPage}
      />
      <PrivateRoute
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_APPLICATION}
        component={AdminApplicationPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_DICTIONARY}
        component={AdminDictionaryPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_PERSONS}
        component={AdminPersonsPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_PERSON_APPLICATIONS}
        component={AdminPersonApplicationsPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_APP_PERSON_COLLECTIONS}
        component={AdminPersonCollectionsPage}
      />
      <PrivateRoute
        exact
        roles={ADMIN_OR_MODERATOR}
        path={Routes.ADMIN_APP_PERSON_COLLECTION}
        component={AdminPersonValuesPage}
      />

      {/* Developer */}
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_REQUEST}
        exact
        component={RequestPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_APPLICATIONS}
        exact
        component={ApplicationsPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_APPLICATION}
        exact
        component={ApplicationPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_COLLECTIONS}
        exact
        component={CollectionsPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_COLLECTION_EDIT}
        exact
        component={CollectionsEditPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_COLLECTION_ADD}
        exact
        component={CollectionsAddPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_DICTIONARIES}
        exact
        component={DictionariesPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_DICTIONARY_EDIT}
        exact
        component={DictionariesEditPage}
      />
      <PrivateRoute
        roles={DEVELOPER}
        path={Routes.DEVELOPER_DICTIONARY_ADD}
        exact
        component={DictionariesAddPage}
      />

      {/* User */}
      <PrivateRoute
        roles={USER}
        path={Routes.USER_APPLICATIONS_REQUESTS}
        exact
        component={ApplicationsRequestsPage}
      />
      <PrivateRoute
        roles={USER}
        path={Routes.USER_COLLECTIONS_MANAGEMENT}
        exact
        component={CollectionsManagementPage}
      />
      <PrivateRoute
        roles={USER}
        path={Routes.USER_COLLECTION}
        exact
        component={UserCollectionPage}
      />

      {PlaygroundPage && (
        <Route path={Routes.PLAYGROUND} exact component={PlaygroundPage} />
      )}
      <Route path={Routes.LOGOUT} exact component={LogoutPage} />
      <Route path={Routes.AUTH_COMPLETE} exact component={AuthCompletePage} />

      <PrivateRoute component={NotFoundPage} />
    </Switch>
  </Suspense>
);

export default AppRoutes;
