import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import { ReactComponent as ErrorIcon } from './assets/error.svg';

import './error-message.scss';

type Props = {
  title?: React.ReactNode;
} & Omit<React.ComponentProps<'div'>, 'title'>;

const ErrorMessage: React.FC<Props> = ({
  className,
  children,
  title,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <div className={clsx('error-message', className)} {...restProps}>
      <div className="error-message__header">
        <ErrorIcon className="error-message__icon" />
        <div className="error-message__title">
          {title || t('Ошибка при отправке')}
        </div>
      </div>
      <div className="error-message__body">{children}</div>
    </div>
  );
};

export default ErrorMessage;
