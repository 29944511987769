import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';

/**
 * Возвращает текущее приложение.
 */
export const getCurrentApplication = createSelector(
  [
    (state: AppState) => state.developer.applications.byId,
    (state: AppState) => state.developer.currentApplication.id
  ],
  (applications, id) => applications[id!]
);
