import { createAsyncAction } from 'typesafe-actions';
import DictionaryDTO from 'types/DictionaryDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import * as api from 'services/api';
import DictionariesSearchParams from 'types/DictionariesSearchParams';
import CountableRecords from 'types/CountableRecords';
import { toCountableRecords } from 'services/network';
import { AppThunk } from 'store';

export const fetchDictionariesAsync = createAsyncAction(
  'developer/FETCH_DICTIONARIES_REQUEST',
  'developer/FETCH_DICTIONARIES_SUCCESS',
  'developer/FETCH_DICTIONARIES_FAILURE'
)<void, CountableRecords<DictionaryDTO>, any>();

export const createDictionaryAsync = createAsyncAction(
  'developer/CREATE_DICTIONARY_REQUEST',
  'developer/CREATE_DICTIONARY_SUCCESS',
  'developer/CREATE_DICTIONARY_FAILURE'
)<void, DictionaryDTO, any>();

/**
 * Загружает список справочников приложения.
 * @param access_token Токен приложения.
 */
export const fetchDictionaries = (
  access_token: ApplicationDTO['access_token'],
  params: DictionariesSearchParams = {}
): AppThunk<Promise<CountableRecords<DictionaryDTO>>> => async dispatch => {
  try {
    dispatch(fetchDictionariesAsync.request());
    const response = await api.getApplicationDictionaries(access_token, params);
    const data = toCountableRecords(response);
    dispatch(fetchDictionariesAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchDictionariesAsync.failure(error));
    throw error;
  }
};

/**
 * Создаёт справочник.
 * @param dictionary Данные справочника.
 * @param access_token Токен приложения.
 */
export const createDictionary = (
  dictionary: OpenAPI.RequestDraftDictionary,
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<DictionaryDTO>> => async dispatch => {
  try {
    dispatch(createDictionaryAsync.request());
    const response = await api.createDictionary(dictionary, access_token);
    dispatch(createDictionaryAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(createDictionaryAsync.failure(error));
    throw error;
  }
};
