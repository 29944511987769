import { createReducer } from 'typesafe-actions';
import storage from 'redux-persist/lib/storage';
import { AuthState, AuthAction } from './types';
import { logout, authPersonAsync } from './actions';

export const initialState: AuthState = {
  isPending: false,
  error: null
};

export const authPersistConfig = {
  key: 'auth',
  whitelist: ['response'],
  storage
};

export default createReducer<AuthState, AuthAction>(initialState)
  .handleAction(authPersonAsync.request, state => ({
    ...state,
    isPending: true,
    error: initialState.error
  }))
  .handleAction(authPersonAsync.failure, (state, { payload }) => ({
    ...state,
    isPending: false,
    error: payload
  }))
  .handleAction(authPersonAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    response: payload
  }))
  .handleAction(logout, () => initialState);
