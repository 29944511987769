import React from 'react';
import clsx from 'clsx';

import './badge.scss';

type Props = React.ComponentProps<'span'>;

const Badge: React.FC<Props> = ({ className, ...restProps }) => (
  <span className={clsx('badge', className)} {...restProps} />
);

export default Badge;
