import { combineReducers, AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import pick from 'lodash/pick';

import { ApplicationState } from './application/types';
import { ApplicationsState } from './applications/types';
import { CurrentApplicationState } from './currentApplication/types';
import { CreateRequestState } from './createRequest/types';
import { CollectionState } from './collection/types';
import { CollectionsState } from './collections/types';
import { DictionariesState } from './dictionaries/types';
import { DictionaryState } from './dictionary/types';
import { DictionaryCategoriesState } from './dictionaryCategories/types';
import { CollectionCategoriesState } from './collectionCategories/types';
import { StatisticsState } from './statistics/types';
import { RequestsState } from './requests/types';
import { CollectionAccessState } from './collectionAccess/types';

import application from './application/reducer';
import applications from './applications/reducer';
import currentApplication, {
  currentApplicationPersistConfig
} from './currentApplication/reducer';
import createRequest from './createRequest/reducer';
import collection from './collection/reducer';
import collections from './collections/reducer';
import dictionaries from './dictionaries/reducer';
import dictionary from './dictionary/reducer';
import dictionaryCategories from './dictionaryCategories/reducer';
import collectionCategories from './collectionCategories/reducer';
import statistics from './statistics/reducer';
import requests from './requests/reducer';
import collectionAccess from './collectionAccess/reducer';
import { setCurrentApplication } from './currentApplication/actions';

export type DeveloperState = {
  application: ApplicationState;
  applications: ApplicationsState;
  currentApplication: CurrentApplicationState & PersistPartial;
  createRequest: CreateRequestState;
  collection: CollectionState;
  collections: CollectionsState;
  dictionaries: DictionariesState;
  dictionary: DictionaryState;
  dictionaryCategories: DictionaryCategoriesState;
  collectionCategories: CollectionCategoriesState;
  statistics: StatisticsState;
  requests: RequestsState;
  collectionAccess: CollectionAccessState;
};

const developerReducer = combineReducers<DeveloperState>({
  application,
  applications,
  currentApplication: persistReducer(
    currentApplicationPersistConfig,
    currentApplication
  ),
  createRequest,
  collection,
  collections,
  dictionaries,
  dictionary,
  dictionaryCategories,
  collectionCategories,
  statistics,
  requests,
  collectionAccess
});

export default (state: DeveloperState | undefined, action: AnyAction) => {
  if (action.type === setCurrentApplication.toString() && state) {
    state = pick(
      state,
      'dictionaryCategories',
      'collectionCategories',
      'currentApplication',
      'applications'
    ) as DeveloperState;
  }

  return developerReducer(state, action);
};
