import React from 'react';
import clsx from 'clsx';

import './radio.scss';

type Props = React.ComponentProps<'input'>;

const Radio: React.FC<Props> = ({ className, children, ...restProps }) => (
  <React.Fragment>
    <input className={clsx('radio', className)} type="radio" {...restProps} />
    <span>{children}</span>
  </React.Fragment>
);

export default Radio;
