import React from 'react';
import clsx from 'clsx';

import Modal from 'components/Modal';
import Button from 'components/Button';

import { ReactComponent as CloseIcon } from './assets/close.svg';
import './closeable-modal.scss';

type Props = React.ComponentProps<typeof Modal>;

const CloseableModal: React.FC<Props> = ({
  children,
  className,
  ...restProps
}) => (
  <Modal className={clsx('closeable-modal', className)} {...restProps}>
    {children}
    <Button
      onClick={restProps.onRequestClose}
      className="closeable-modal__close"
      disablePadding
      disableTheme
    >
      <CloseIcon />
    </Button>
  </Modal>
);

export default CloseableModal;
