import React, { useCallback } from 'react';
import { withTypes } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import FormField from 'components/FormField/FormField';
import Label from 'components/Label';
import FieldTextarea from 'components/FieldTextarea';

import './create-request-form.scss';

export type CreateRequestFormValues = {
  description: OpenAPI.RequestDictMerge['description'];
};

type Props = {
  initialValues?: CreateRequestFormValues;
  onSubmit?: (values: CreateRequestFormValues) => any;
  className?: string;
};

const { Form } = withTypes<CreateRequestFormValues>();

const CreateRequestForm: React.FC<Props> = ({
  initialValues,
  className,
  onSubmit
}) => {
  const handleSubmit = useCallback(
    (values: CreateRequestFormValues) =>
      typeof onSubmit === 'function' && onSubmit(values),
    [onSubmit]
  );
  const { t } = useTranslation();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx('create-request-form', className)}
        >
          <div className="create-request-form__body">
            <FormField className="create-request-form__description">
              <Label htmlFor="description">{t('Описание')}</Label>
              <FieldTextarea
                id="description"
                name="description"
                className="create-request-form__description-textarea"
              />
            </FormField>
          </div>
          <Button
            variant="contained"
            theme="primary"
            type="submit"
            className="create-request-form__submit"
          >
            {t('Отправить запрос')}
          </Button>
        </form>
      )}
    />
  );
};

export default CreateRequestForm;
