import React from 'react';
import CollectionAccessPopup from 'components/CollectionAccessPopup';
import CreateRequestPopup from 'components/CreateRequestPopup';
import ReportPopup from 'components/ReportPopup';
import { useSelector } from 'react-redux';
import { getPersonRole } from 'store/person/selectors';

const AppPopups: React.FC = () => {
  switch (useSelector(getPersonRole)) {
    case 'developer':
      return (
        <React.Fragment>
          <CollectionAccessPopup />
          <CreateRequestPopup />
        </React.Fragment>
      );
    case 'user':
      return <ReportPopup />;
    default:
      return null;
  }
};

export default AppPopups;
