import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import { AppThunk } from 'store';
import AdminPersonDTO from 'types/AdminPersonDTO';

type SuccessPayload = [AdminPersonDTO['id'], OpenAPI.AppPersonCollection[]];

export const fetchAppPersonCollectionsAsync = createAsyncAction(
  'admin/FETCH_APP_PERSON_COLLECTIONS_REQUEST',
  'admin/FETCH_APP_PERSON_COLLECTIONS_SUCCESS',
  'admin/FETCH_APP_PERSON_COLLECTIONS_FAILURE'
)<void, SuccessPayload, any>();

/**
 * Загружает коллекции, связанные с AppPerson.
 * @param id ID персоны.
 * @param token Токен пользователя.
 */
export const fetchAppPersonCollections = (
  id: AdminPersonDTO['id'],
  token: string
): AppThunk<Promise<SuccessPayload>> => async dispatch => {
  try {
    dispatch(fetchAppPersonCollectionsAsync.request());
    const response = await api.getAdminAppPersonCollections(id, token);
    const data: SuccessPayload = [id, response.data];
    dispatch(fetchAppPersonCollectionsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchAppPersonCollectionsAsync.failure(error));
    throw error;
  }
};
