import React from 'react';
import clsx from 'clsx';
import ReactSelect from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { Styles } from 'react-select/src/styles';

import './select.scss';

type Props = React.ComponentProps<typeof ReactSelect> & {
  transparent?: boolean;
};

const components: Partial<SelectComponents<any>> = {
  IndicatorSeparator: null,
  DropdownIndicator: ({ selectProps }) => (
    <i
      className={clsx(
        'select__dropdown',
        selectProps.menuIsOpen && 'select__dropdown--opened'
      )}
    />
  )
};

const styles: Partial<Styles> = {
  control: () => ({}),
  singleValue: () => ({}),
  valueContainer: styles => ({
    ...styles,
    padding: 0
  })
};

const Select: React.FC<Props> = ({ className, transparent, ...restProps }) => (
  <ReactSelect
    components={components}
    styles={styles}
    className={clsx('select', transparent && 'select--transparent', className)}
    classNamePrefix="select"
    isSearchable={false}
    {...restProps}
  />
);

export default Select;
