import { createAsyncAction, createAction } from 'typesafe-actions';
import AdminPersonDTO from 'types/AdminPersonDTO';
import AdminCollectionDTO from 'types/AdminCollectionDTO';
import { AppThunk } from 'store';
import * as api from 'services/api';
import CountableRecords from 'types/CountableRecords';
import { toCountableRecords } from 'services/network';
import { getValuesCount } from 'services/values';

export const resetAppPersonValuesState = createAction(
  'admin/RESET_APP_PERSON_VALUES_STATE'
);

export const fetchAppPersonValuesMoreAsync = createAsyncAction(
  'admin/FETCH_APP_PERSON_VALUES_MORE_REQUEST',
  'admin/FETCH_APP_PERSON_VALUES_MORE_SUCCESS',
  'admin/FETCH_APP_PERSON_VALUES_MORE_FAILURE'
)<void, CountableRecords<OpenAPI.ResponseValues>, any>();

/**
 * Загружает значения коллекции персоны.
 * @param appPersonId ID AppPerson.
 * @param collectionId ID коллекции.
 * @param token Токен пользователя.
 */
export const fetchAppPersonValuesMore = (
  appPersonId: AdminPersonDTO['id'],
  collectionId: AdminCollectionDTO['id'],
  token: string
): AppThunk<Promise<CountableRecords<OpenAPI.ResponseValues>>> => async (
  dispatch,
  getState
) => {
  try {
    const { values } = getState().admin.appPersonValues;
    dispatch(fetchAppPersonValuesMoreAsync.request());
    const response = await api.getAdminCollectionValues(collectionId, token, {
      app_person: appPersonId,
      offset: values && getValuesCount(values)
    });
    const data = toCountableRecords(response);
    dispatch(fetchAppPersonValuesMoreAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchAppPersonValuesMoreAsync.failure(error));
    throw error;
  }
};

export const fetchAppPersonValuesAsync = createAsyncAction(
  'admin/FETCH_APP_PERSON_VALUES_REQUEST',
  'admin/FETCH_APP_PERSON_VALUES_SUCCESS',
  'admin/FETCH_APP_PERSON_VALUES_FAILURE'
)<void, CountableRecords<OpenAPI.ResponseValues>, any>();

/**
 * Загружает значения коллекции персоны.
 * @param appPersonId ID AppPerson.
 * @param collectionId ID коллекции.
 * @param token Токен пользователя.
 */
export const fetchAppPersonValues = (
  appPersonId: AdminPersonDTO['id'],
  collectionId: AdminCollectionDTO['id'],
  token: string
): AppThunk<Promise<
  CountableRecords<OpenAPI.ResponseValues>
>> => async dispatch => {
  try {
    dispatch(fetchAppPersonValuesAsync.request());
    const response = await api.getAdminCollectionValues(collectionId, token, {
      app_person: appPersonId
    });
    const data = toCountableRecords(response);
    dispatch(fetchAppPersonValuesAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchAppPersonValuesAsync.failure(error));
    throw error;
  }
};
