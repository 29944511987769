import { createAsyncAction } from 'typesafe-actions';

import PersonCollectionDTO from 'types/PersonCollectionDTO';

import * as api from 'services/api';
import PersonCollectionsParams from 'types/PersonCollectionsParams';
import { AppThunk } from 'store';

export const fetchCollectionsAsync = createAsyncAction(
  'user/FETCH_COLLECTIONS_REQUEST',
  'user/FETCH_COLLECTIONS_SUCCESS',
  'user/FETCH_COLLECTIONS_FAILURE'
)<void, PersonCollectionDTO[], any>();

export const fetchCollectionAsync = createAsyncAction(
  'user/FETCH_COLLECTION_REQUEST',
  'user/FETCH_COLLECTION_SUCCESS',
  'user/FETCH_COLLECTION_FAILURE'
)<void, PersonCollectionDTO, any>();

/**
 * Загружает коллекции, ассоциированные с персоной.
 * @param token Токен персоны.
 */
export const fetchCollections = (
  params: PersonCollectionsParams = {},
  token: string
): AppThunk<Promise<PersonCollectionDTO[]>> => async dispatch => {
  try {
    dispatch(fetchCollectionsAsync.request());
    const response = await api.getListCollectionsWithData(params, token);
    dispatch(fetchCollectionsAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionsAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает коллекцию персоны.
 * @param applicationId ID приложения.
 * @param collectionId ID коллекции.
 * @param token Токен персоны.
 */
export const fetchCollection = (
  applicationId: PersonCollectionDTO['provider']['id'],
  collectionId: PersonCollectionDTO['id'],
  token: string
): AppThunk<Promise<PersonCollectionDTO>> => async dispatch => {
  try {
    dispatch(fetchCollectionAsync.request());
    const response = await api.getPersonCollection(
      collectionId,
      applicationId,
      token
    );
    dispatch(fetchCollectionAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionAsync.failure(error));
    throw error;
  }
};
