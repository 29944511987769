import { createStore, applyMiddleware, AnyAction } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import rootReducer, { AppState } from './rootReducer';

export const history = createBrowserHistory();

export type AppStore = ReturnType<typeof configureStore>['store'];
export type AppDispatch = ThunkDispatch<AppState, {}, AnyAction>;
export type AppThunk<T = void> = ThunkAction<T, AppState, {}, AnyAction>;

export default function configureStore(initialState?: AppState) {
  const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), thunkMiddleware)
    )
  );

  const persistor = persistStore(store);

  return { store, persistor };
}
