import React, { useMemo, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import ApplicationDTO from 'types/ApplicationDTO';
import CollectionAccessType from 'types/CollectionAccessType';

import Select from 'components/Select';

import './collection-permission.scss';

type Props = {
  permission: OpenAPI.RequestCollectionPermission;
  application: ApplicationDTO;
  onDelete?: (permission: Props['permission']) => any;
  onChange?: (permission: Props['permission'], can_write: boolean) => any;
  accessType?: CollectionAccessType;
} & Omit<React.ComponentProps<'div'>, 'onChange'>;

type PermissionOption = { label: string; value?: boolean };

const getOptionLabel = (option: any) => (option as PermissionOption).label;
const getOptionValue = (option: any) => option.value;

const CollectionPermission: React.FC<Props> = ({
  application,
  permission,
  className,
  onDelete,
  onChange,
  accessType,
  ...restProps
}) => {
  const { t } = useTranslation();
  const options = useMemo<PermissionOption[]>(
    () => [
      ...(accessType === 'public_read'
        ? []
        : [{ label: t('Только чтение'), value: false }]),
      { label: t('Запись'), value: true },
      { label: t('Удалить') }
    ],
    [accessType, t]
  );

  const currentValue = useMemo(
    () =>
      options.find(option => option.value === permission.can_write) ||
      options[0],
    [options, permission.can_write]
  );

  const handleChange = useCallback(
    (option: any) => {
      if (typeof option.value === 'undefined') {
        return typeof onDelete === 'function' && onDelete(permission);
      }

      return (
        typeof onChange === 'function' && onChange(permission, option.value)
      );
    },
    [onChange, onDelete, permission]
  );

  useEffect(() => {
    if (currentValue.value !== permission.can_write) {
      handleChange(currentValue);
    }
  }, [currentValue, handleChange, permission.can_write]);

  return (
    <div className={clsx('collection-permission', className)} {...restProps}>
      <div className="collection-permission__application">
        {application.name}
      </div>
      <Select
        className="collection-permission__select"
        options={options}
        transparent
        value={currentValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default CollectionPermission;
