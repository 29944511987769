import { createAsyncAction } from 'typesafe-actions';

import ApplicationDTO from 'types/ApplicationDTO';
import CollectionsSearchParams from 'types/CollectionsSearchParams';
import CollectionDTO from 'types/CollectionDTO';
import CountableRecords from 'types/CountableRecords';

import * as api from 'services/api';
import { getErrorMessage, toCountableRecords } from 'services/network';
import { AppThunk } from 'store';

export const fetchCollectionsAsync = createAsyncAction(
  'developer/FETCH_COLLECTIONS_REQUEST',
  'developer/FETCH_COLLECTIONS_SUCCESS',
  'developer/FETCH_COLLECTIONS_FAILURE'
)<void, CountableRecords<CollectionDTO>, any>();

export const createCollectionAsync = createAsyncAction(
  'developer/CREATE_COLLECTION_REQUEST',
  'developer/CREATE_COLLECTION_SUCCESS',
  'developer/CREATE_COLLECTION_FAILURE'
)<void, CollectionDTO, any>();

export const sendFileAsync = createAsyncAction(
  'developer/SEND_FILE_REQUEST',
  'developer/SEND_FILE_SUCCESS',
  'developer/SEND_FILE_FAILURE'
)<void, OpenAPI.ResponseInsertedValues, any>();

/**
 * Загружает коллекции.
 * @param access_token Токен приложения.
 * @param params Параметры поиска.
 */
export const fetchCollections = (
  access_token: ApplicationDTO['access_token'],
  params: CollectionsSearchParams = {}
): AppThunk<Promise<CountableRecords<CollectionDTO>>> => async dispatch => {
  try {
    dispatch(fetchCollectionsAsync.request());
    const response = await api.getApplicationCollections(access_token, params);
    const data = toCountableRecords(response);
    dispatch(fetchCollectionsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionsAsync.failure(error));
    throw error;
  }
};

/**
 * Создаёт коллекцию.
 * @param collection Данные коллекции.
 * @param access_token Токен приложения.
 */
export const createCollection = (
  collection: OpenAPI.RequestCollection,
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<CollectionDTO>> => async dispatch => {
  try {
    dispatch(createCollectionAsync.request());
    const response = await api.createCollection(collection, access_token);
    dispatch(createCollectionAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(createCollectionAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

/**
 * Отправляет файл для добавления новых значений в коллекцию.
 * @param id ID коллекции.
 * @param file Файл со значениями.
 * @param access_token Токен приложения.
 */
export const sendFile = (
  id: CollectionDTO['id'],
  file: OpenAPI.Parameters.File,
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<OpenAPI.ResponseInsertedValues>> => async dispatch => {
  try {
    dispatch(sendFileAsync.request());
    const response = await api.sendFileWithNewValues(id, file, access_token);
    dispatch(sendFileAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(sendFileAsync.failure(getErrorMessage(error)));
    throw error;
  }
};
