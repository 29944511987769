import React from 'react';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Heading from 'components/Heading';

import './message-popup.scss';

type Props = {
  title?: React.ReactNode;
  submitText?: React.ReactNode;
} & React.ComponentProps<typeof Modal>;

const MessagePopup: React.FC<Props> = ({ title, submitText, ...restProps }) => (
  <Modal className="message-popup" {...restProps}>
    <Heading className="message-popup__title">{title}</Heading>
    <Button
      className="message-popup__submit"
      theme="primary"
      variant="contained"
      onClick={restProps.onRequestClose}
    >
      {submitText}
    </Button>
  </Modal>
);

export default MessagePopup;
