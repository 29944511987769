import React from 'react';
import clsx from 'clsx';

import './form-field.scss';

type Props = React.ComponentProps<'div'>;

const FormField: React.FC<Props> = ({ className, children }) => (
  <div className={clsx('form-field', className)}>{children}</div>
);

export default FormField;
