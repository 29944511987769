import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import 'moment/locale/ru';
import { EN } from './languages';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: localStorage.getItem('lang') || EN,
    fallbackLng: EN,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    keySeparator: false,
    nsSeparator: false
  });

i18n.on('languageChanged', lang => {
  localStorage.setItem('lang', lang);
  moment.locale(lang);
});

i18n.on('initialized', () => {
  moment.locale(i18n.language);
});

export default i18n;
