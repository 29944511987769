import { createReducer } from 'typesafe-actions';
import { ApplicationRequestsState, ApplicationRequestsAction } from './types';
import {
  fetchApplicationRequestsAsync,
  updateApplicationRequestAsync
} from './actions';

export const initialState: ApplicationRequestsState = {
  isPending: false,
  count: 0,
  byId: {},
  ids: []
};

export default createReducer<
  ApplicationRequestsState,
  ApplicationRequestsAction
>(initialState)
  .handleAction(fetchApplicationRequestsAsync.request, state => ({
    ...state,
    error: initialState.error,
    isPending: true
  }))
  .handleAction(
    fetchApplicationRequestsAsync.failure,
    (state, { payload }) => ({
      ...state,
      error: payload,
      isPending: false
    })
  )
  .handleAction(
    fetchApplicationRequestsAsync.success,
    (state, { payload }) => ({
      ...state,
      isPending: false,
      count: payload.count!,
      ids: payload.records.map(request => request.id),
      byId: payload.records.reduce(
        (acc, request) => ({ ...acc, [request.id]: request }),
        state.byId
      )
    })
  )
  .handleAction(
    updateApplicationRequestAsync.success,
    (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.id]: payload
      }
    })
  );
