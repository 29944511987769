import { createAsyncAction } from 'typesafe-actions';
import uniqBy from 'lodash/uniqBy';
import ApplicationDTO from 'types/ApplicationDTO';
import DictionaryDTO from 'types/DictionaryDTO';

import * as api from 'services/api';
import DictionaryElementDTO from 'types/DictionaryElementDTO';
import { getElementsDiff } from 'services/elements';
import { getErrorMessage } from 'services/network';
import { AppThunk } from 'store';

export const editDictionaryAsync = createAsyncAction(
  'developer/EDIT_DICTIONARY_REQUEST',
  'developer/EDIT_DICTIONARY_SUCCESS',
  'developer/EDIT_DICTIONARY_FAILURE'
)<void, DictionaryDTO, any>();

export const fetchDictionaryAsync = createAsyncAction(
  'developer/FETCH_DICTIONARY_REQUEST',
  'developer/FETCH_DICTIONARY_SUCCESS',
  'developer/FETCH_DICTIONARY_FAILURE'
)<DictionaryDTO['id'], DictionaryDTO, any>();

/**
 * Обновляет данные справочника.
 * @param dictionary Новые данные справочника.
 * @param elements Элементы справочника.
 * @param access_token Токен приложения.
 */
export const editDictionary = (
  id: DictionaryDTO['id'],
  dictionary: OpenAPI.UpdateDictionary,
  elements: DictionaryElementDTO[],
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<DictionaryDTO | undefined>> => async (
  dispatch,
  getState
) => {
  try {
    const data = getState().developer.dictionaries.byId[id];

    if (!data) return;

    dispatch(editDictionaryAsync.request());

    // Update elements here.
    const diff = getElementsDiff(data.elements || [], elements);

    for (let i = 0; i < diff.create.length; i++) {
      const draftElement = diff.create[i];
      const response = await api.createDictionaryElement(
        data.id,
        { ...draftElement, children: undefined },
        access_token
      );
      const created = response.data[0];

      // Заменяем временный ID поля реальным.
      diff.create = diff.create.map(element =>
        element.parent === draftElement.id
          ? { ...element, parent: created.id }
          : element
      );
    }

    const update = uniqBy([...diff.update, ...diff.deprecate], 'id');

    await Promise.all(
      update.map(element =>
        api.updateDictionaryElement(data.id, element.id, element, access_token)
      )
    );
    await Promise.all(
      diff.delete.map(element =>
        api.deleteDictionaryElement(data.id, element.id, access_token)
      )
    );

    // Update dictionary info.
    await api.updateDictionary(data.id, dictionary, access_token);
    const response = await api.getDictionary(data.id, access_token);
    dispatch(editDictionaryAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(editDictionaryAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

/**
 * Загружает информацию о справочнике.
 * @param id ID справочника.
 * @param access_token Токен приложения.
 */
export const fetchDictionary = (
  id: DictionaryDTO['id'],
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<DictionaryDTO>> => async dispatch => {
  try {
    dispatch(fetchDictionaryAsync.request(id));
    const response = await api.getDictionary(id, access_token);
    dispatch(fetchDictionaryAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchDictionaryAsync.failure(getErrorMessage(error)));
    throw error;
  }
};
