import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import { AppThunk } from 'store';

export const fetchDictionaryCategoriesAsync = createAsyncAction(
  'developer/FETCH_DICTIONARY_CATEGORIES_REQUEST',
  'developer/FETCH_DICTIONARY_CATEGORIES_SUCCESS',
  'developer/FETCH_DICTIONARY_CATEGORIES_FAILURE'
)<void, OpenAPI.ResponseCategory[], any>();

/**
 * Загружает список категорий для справочников.
 */
export const fetchDictionaryCategories = (): AppThunk<Promise<
  OpenAPI.ResponseCategory[] | undefined
>> => async dispatch => {
  try {
    dispatch(fetchDictionaryCategoriesAsync.request());
    const response = await api.getDictionaryCategories();
    dispatch(fetchDictionaryCategoriesAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchDictionaryCategoriesAsync.failure(error));
  }
};
