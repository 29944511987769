import React from 'react';

import { ReactComponent as Logo } from 'components/Header/assets/logo.svg';
import './page-placeholder.scss';

type Props = {};

const PagePlaceholder: React.FC<Props> = () => (
  <div className="page-placeholder">
    <Logo className="page-placeholder__logo" />
  </div>
);

export default PagePlaceholder;
