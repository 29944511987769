import { createReducer, ActionType } from 'typesafe-actions';
import { DictionariesState, DictionariesAction } from './types';
import { fetchDictionaryAsync } from '../dictionary/actions';
import { fetchApplicationDictionariesAsync } from '../application/actions';

export const initialState: DictionariesState = {
  byId: {}
};

export default createReducer<
  DictionariesState,
  | DictionariesAction
  | ActionType<
      | typeof fetchDictionaryAsync['success']
      | typeof fetchApplicationDictionariesAsync['success']
    >
>(initialState)
  .handleAction(fetchDictionaryAsync.success, (state, { payload }) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: payload
    }
  }))
  .handleAction(
    fetchApplicationDictionariesAsync.success,
    (state, { payload }) => ({
      ...state,
      byId: payload.records.reduce(
        (acc, dictionary) => ({
          ...acc,
          [dictionary.id]: {
            ...acc[dictionary.id],
            ...dictionary
          }
        }),
        state.byId
      )
    })
  );
