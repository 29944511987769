import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getRoleTitle } from 'services/roles';
import Button from 'components/Button';
import lowerFirst from 'lodash/lowerFirst';
import clsx from 'clsx';

import PersonRole from 'types/PersonRole';
import Routes from 'types/Routes';

import './person-menu.scss';

type Props = {
  role: PersonRole;
  availableRoles?: PersonRole[];
  onChangeRole?: (role: PersonRole) => any;
} & Omit<React.ComponentProps<'div'>, 'role'>;

const PersonMenu = React.forwardRef<HTMLDivElement, Props>(
  ({ role, className, onChangeRole, availableRoles }, ref) => {
    const { t } = useTranslation();
    const options = useMemo(() => availableRoles?.filter(r => r !== role), [
      availableRoles,
      role
    ]);
    const handleChangeRole = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        const { value: role } = event.currentTarget.dataset;
        onChangeRole?.(role as PersonRole);
      },
      [onChangeRole]
    );

    return (
      <div ref={ref} className={clsx('person-menu', className)}>
        <div className="person-menu__content">
          <div className="person-menu__current-role">
            {t('Сейчас вы авторизованы как')}{' '}
            <b>{lowerFirst(getRoleTitle(role))}</b>
          </div>
          {options?.map(option => (
            <Button
              data-value={option}
              onClick={handleChangeRole}
              className="person-menu__role"
              key={option}
              disabled={availableRoles?.indexOf(option) === -1}
              theme="primary"
            >
              {t('Войти как')} {lowerFirst(getRoleTitle(option))}
            </Button>
          ))}
        </div>
        <Link className="person-menu__logout" to={Routes.LOGOUT}>
          <Button
            className="person-menu__logout-button"
            disablePadding
            theme="primary"
          >
            {t('Выйти')}
          </Button>
        </Link>
      </div>
    );
  }
);

export default PersonMenu;
