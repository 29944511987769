import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/rootReducer';
import { closeReport } from 'store/user/report/actions';
import { getPersonToken } from 'store/auth/selectors';
import * as api from 'services/api';

import ReportPopup from './ReportPopup';

type Props = Omit<
  React.ComponentProps<typeof ReportPopup>,
  'isOpen' | 'onRequestClose' | 'payload' | 'onSubmit'
>;

const ConnectedReportPopup: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const token = useSelector(getPersonToken)!;
  const payload = useSelector((state: AppState) => state.user.report.payload);
  const handleClose = useCallback(() => dispatch(closeReport()), [dispatch]);
  const handleSubmit = useCallback<
    NonNullable<React.ComponentProps<typeof ReportPopup>['onSubmit']>
  >(
    async values => {
      await api.createSupportRequest(values, token).finally(handleClose);
    },
    [handleClose, token]
  );
  const isOpen = !!payload;

  return isOpen ? (
    <ReportPopup
      isOpen={isOpen}
      payload={payload}
      onRequestClose={handleClose}
      onSubmit={handleSubmit}
      {...props}
    />
  ) : null;
};

export default ConnectedReportPopup;
