import { createReducer } from 'typesafe-actions';
import { ApplicationsState, ApplicationsAction } from './types';
import {
  fetchApplicationsAsync,
  fetchDevelopersAsync,
  setApplicationsParams
} from './actions';

export const initialState: ApplicationsState = {
  isPending: false,
  byId: {},
  ids: []
};

export default createReducer<ApplicationsState, ApplicationsAction>(
  initialState
)
  .handleAction(fetchApplicationsAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(fetchApplicationsAsync.failure, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(fetchApplicationsAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    byId: payload.reduce(
      (acc, application) => ({ ...acc, [application.id]: application }),
      state.byId
    ),
    ids: payload.map(application => application.id)
  }))
  .handleAction(fetchDevelopersAsync.success, (state, { payload }) => ({
    ...state,
    developers: payload
  }))
  .handleAction(setApplicationsParams, (state, { payload }) => ({
    ...state,
    params: payload
  }));
