import { createAsyncAction } from 'typesafe-actions';
import { AppThunk } from 'store';

import * as api from 'services/api';
import AdminDictionaryDTO from 'types/AdminDictionaryDTO';
import { getErrorMessage } from 'services/network';

export const fetchDictionaryAsync = createAsyncAction(
  'admin/FETCH_DICTIONARY_REQUEST',
  'admin/FETCH_DICTIONARY_SUCCESS',
  'admin/FETCH_DICTIONARY_FAILURE'
)<void, AdminDictionaryDTO, any>();

/**
 * Загружает справочник.
 * @param id ID справочника.
 * @param token Токен пользователя.
 */
export const fetchDictionary = (
  id: AdminDictionaryDTO['id'],
  token: string
): AppThunk<Promise<AdminDictionaryDTO>> => async dispatch => {
  try {
    dispatch(fetchDictionaryAsync.request());
    const response = await api.getAdminDictionary(id, token);
    dispatch(fetchDictionaryAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchDictionaryAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

export const createMergeAsync = createAsyncAction(
  'admin/CREATE_MERGE_REQUEST',
  'admin/CREATE_MERGE_SUCCESS',
  'admin/CREATE_MERGE_FAILURE'
)<void, OpenAPI.ResponseDictMergeWithActions, any>();

/**
 * Создает merge.
 * @param id ID справочника.
 * @param merge Содержимое merge.
 * @param token Токен пользователя.
 */
export const createMerge = (
  id: AdminDictionaryDTO['id'],
  merge: OpenAPI.RequestDictMerge,
  token: string
): AppThunk<Promise<
  OpenAPI.ResponseDictMergeWithActions
>> => async dispatch => {
  try {
    dispatch(createMergeAsync.request());
    const response = await api.createAdminMerge(id, merge, token);
    dispatch(createMergeAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(createMergeAsync.failure(getErrorMessage(error)));
    throw error;
  }
};
