import React, { useMemo, useEffect } from 'react';
import { Route } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import intersection from 'lodash/intersection';
import { getPersonToken } from 'store/auth/selectors';
import PersonRole from 'types/PersonRole';
import { getAvailableRoles, getPersonRole } from 'store/person/selectors';
import { setPersonRole, fetchPerson } from 'store/person/actions';
import { fetchApplicationsList } from 'store/developer/applications/actions';
import { AppState } from 'store/rootReducer';
import { fetchDictionaryCategories } from 'store/developer/dictionaryCategories/actions';
import { fetchCollectionCategories } from 'store/developer/collectionCategories/actions';
import PagePlaceholder from 'components/PagePlaceholder';
import NotFoundPage from 'pages/NotFoundPage';
import { openAuthPage } from 'services/auth';
import { getApplications } from 'store/developer/applications/selectors';
import { getCurrentApplication } from 'store/developer/currentApplication/selectors';
import { setCurrentApplication } from 'store/developer/currentApplication/actions';
import { AppDispatch } from 'store';

type Props = { roles?: PersonRole[] } & React.ComponentProps<typeof Route>;

function usePrivateRoute() {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector(getPersonToken);
  const dictionaryCategories = useSelector(
    (state: AppState) => state.developer.dictionaryCategories.isFulfilled
  );
  const collectionCategories = useSelector(
    (state: AppState) => state.developer.collectionCategories.isFulfilled
  );
  const applications = useSelector(
    (state: AppState) => state.developer.applications.isFulfilled
  );
  const applicationsList = useSelector(getApplications);
  const currentApplication = useSelector(getCurrentApplication);
  const person = useSelector((state: AppState) => state.person.data);

  // Загружаем категории справочников.
  useEffect(() => {
    if (!dictionaryCategories) {
      dispatch(fetchDictionaryCategories());
    }
  }, [dictionaryCategories, dispatch]);

  // Загружаем категории коллекций.
  useEffect(() => {
    if (!collectionCategories) {
      dispatch(fetchCollectionCategories());
    }
  }, [collectionCategories, dispatch]);

  // Загружаем информацию о пользователе и проверяем токен.
  useEffect(() => {
    if (!person && token) {
      dispatch(fetchPerson(token));
    }
  }, [dispatch, person, token]);

  // Загружаем список приложений.
  useEffect(() => {
    if (!applications && token) {
      dispatch(fetchApplicationsList(token));
    }
  }, [applications, dispatch, token]);

  // Устанавливаем приложение по умолчанию.
  useEffect(() => {
    if (applicationsList.length && !currentApplication) {
      dispatch(setCurrentApplication(applicationsList[0].id));
    }
  }, [applicationsList, currentApplication, dispatch]);

  return useMemo(() => ({ loading: !(person && applications) }), [
    applications,
    person
  ]);
}

const PrivateRoute: React.FC<Props> = ({ roles, ...restProps }) => {
  const { loading } = usePrivateRoute();
  const dispatch = useDispatch();
  const token = useSelector(getPersonToken);
  const currentRole = useSelector(getPersonRole);
  const availableRoles = useSelector(getAvailableRoles);
  const [availableRole] = useMemo(
    () => intersection(roles || [], availableRoles),
    [availableRoles, roles]
  );

  useEffect(() => {
    if (!token) {
      openAuthPage();
    }
  }, [token]);

  useEffect(() => {
    if (loading) return;

    if (availableRole && roles && !roles.includes(currentRole)) {
      // Если персона имеет доступ к роуту этой роли, то ставим её как текущую.
      dispatch(setPersonRole(availableRole));
    } else if (!availableRole && !availableRoles.includes(currentRole)) {
      // Если персона не имеет доступа к роута и текущей роли нет в списке доступных.
      dispatch(setPersonRole(availableRoles[0]));
    }
  }, [availableRole, availableRoles, currentRole, dispatch, loading, roles]);

  if (loading || !token) return <PagePlaceholder />;

  if (!availableRole && roles) return <NotFoundPage />;

  return <Route {...restProps} />;
};

export default PrivateRoute;
