import { createAsyncAction } from 'typesafe-actions';

import * as api from 'services/api';
import ApplicationDTO from 'types/ApplicationDTO';
import { AppThunk } from 'store';

export const fetchApplicationsListAsync = createAsyncAction(
  `developer/FETCH_APPLICATIONS_LIST_REQUEST`,
  'developer/FETCH_APPLICATIONS_LIST_SUCCESS',
  'developer/FETCH_APPLICATIONS_LIST_FAILURE'
)<void, ApplicationDTO[], any>();

/**
 * Загружает список приложений.
 */
export const fetchApplicationsList = (
  token: string
): AppThunk<Promise<ApplicationDTO[]>> => async dispatch => {
  try {
    dispatch(fetchApplicationsListAsync.request());
    const response = await api.getApplicationsList(token);
    dispatch(fetchApplicationsListAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationsListAsync.failure(error));
    return [];
  }
};
