import DictionaryDTO from 'types/DictionaryDTO';
import i18n from 'i18n';
import vars from 'static/styles/vars.scss';

/**
 * Возвращает цвет статуса.
 * @param is_active Статус справочника.
 */
export const getStatusColor = (is_active: DictionaryDTO['is_active']) =>
  is_active ? vars['color-primary'] : '#000';

/**
 * Возвращает название статуса.
 * @param is_active Статус справочника.
 */
export const getStatusTitle = (is_active: DictionaryDTO['is_active']) =>
  is_active ? i18n.t('Опубликовано') : i18n.t('Черновик');

/**
 * Возвращает количество элементов справочника.
 * @param dictionary Справочник.
 */
export const getElementsCount = (dictionary: DictionaryDTO) =>
  dictionary.elements
    ? dictionary.elements.length
    : dictionary.elements_count || 0;

/**
 * Возвращает описание справочника.
 * @param dictionary Справочник.
 */
export const getDescription = (dictionary: DictionaryDTO) =>
  dictionary.description || i18n.t('Описание не указано');
