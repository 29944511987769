import React, { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';

import PersonDTO from 'types/PersonDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import PersonRole from 'types/PersonRole';
import Routes from 'types/Routes';

import HeaderApplicationSelect from 'components/HeaderApplicationSelect';
import HeaderNavItem from 'components/HeaderNavItem';
import PersonAvatar from 'components/PersonAvatar';
import Button from 'components/Button';
import LoadingBar from 'components/LoadingBar';
import PersonMenu from 'components/PersonMenu';

import usePopup from 'hooks/usePopup';

import { ReactComponent as Logo } from './assets/logo.svg';
import './header.scss';

type Props = {
  onLanguage?: () => any;
  isPending?: boolean;
  person?: PersonDTO;
  applications?: ApplicationDTO[];
  onChangeApplication?: React.ComponentProps<
    typeof HeaderApplicationSelect
  >['onChange'];
  currentApplication?: ApplicationDTO;
  language?: React.ReactNode;
  role?: PersonRole;
  onChangeRole?: React.ComponentProps<typeof PersonMenu>['onChangeRole'];
  availableRoles?: React.ComponentProps<typeof PersonMenu>['availableRoles'];
};

const Header: React.FC<Props> = ({
  person,
  onLanguage,
  applications,
  currentApplication,
  onChangeApplication,
  language,
  isPending,
  onChangeRole,
  role,
  availableRoles
}) => {
  const { t } = useTranslation();
  const personMenuRef = useRef<HTMLDivElement>(null);
  const personMenu = usePopup();
  const navItems = useMemo(() => {
    if (!person) return null;

    switch (role) {
      case 'developer':
        return (
          <React.Fragment>
            <HeaderApplicationSelect
              className="header__application"
              options={applications}
              value={currentApplication}
              onChange={onChangeApplication}
            />
            <HeaderNavItem to={Routes.DEVELOPER_COLLECTIONS}>
              {t('Каталог коллекций')}
            </HeaderNavItem>
            <HeaderNavItem to={Routes.DEVELOPER_DICTIONARIES}>
              {t('Справочники')}
            </HeaderNavItem>
            <HeaderNavItem to={Routes.DEVELOPER_APPLICATIONS} exact>
              {t('Мои приложения')}
            </HeaderNavItem>
          </React.Fragment>
        );
      case 'user':
        return (
          <React.Fragment>
            <HeaderNavItem to={Routes.USER_APPLICATIONS_REQUESTS}>
              {t('Запросы от приложений')}
            </HeaderNavItem>
            <HeaderNavItem to={Routes.USER_COLLECTIONS_MANAGEMENT}>
              {t('Управление данными')}
            </HeaderNavItem>
          </React.Fragment>
        );
      case 'moderator':
      case 'administrator':
        return (
          <React.Fragment>
            <HeaderNavItem to={Routes.ADMIN_REQUESTS}>
              {t('Запросы')}
            </HeaderNavItem>
            <HeaderNavItem to={Routes.ADMIN_APPLICATIONS}>
              {t('Приложения')}
            </HeaderNavItem>
            <HeaderNavItem to={Routes.ADMIN_PERSONS}>
              {t('Персоны')}
            </HeaderNavItem>
            <HeaderNavItem to={Routes.ADMIN_APPLICATION_REQUESTS}>
              {t('Новые приложения')}
            </HeaderNavItem>
          </React.Fragment>
        );
    }
  }, [applications, currentApplication, onChangeApplication, person, role, t]);

  useOnClickOutside(personMenuRef, personMenu.close);

  return (
    <div className="header">
      <LoadingBar isPending={isPending} />
      <Link to={Routes.INDEX}>
        <Logo />
      </Link>
      <div className="header__nav">{navItems}</div>
      <Button
        className="header__language"
        onClick={onLanguage}
        theme="primary"
        disablePadding
      >
        {language}
      </Button>
      {person && (
        <div className="header__person">
          <Button
            className="header__person-button"
            onClick={personMenu.open}
            disablePadding
            disableTheme
          >
            <PersonAvatar person={person} />
          </Button>
          {personMenu.isOpen && role && (
            <PersonMenu
              ref={personMenuRef}
              onChangeRole={onChangeRole}
              role={role}
              availableRoles={availableRoles}
              className="header__person-menu"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
