import axios from 'axios';
import qs from 'query-string';

import DictionaryElementDTO from 'types/DictionaryElementDTO';
import StatisticsParams from 'types/StatisticsParams';
import RequestsParams from 'types/RequestsParams';
import PersonCollectionsParams from 'types/PersonCollectionsParams';
import PersonCollectionValuesParams from 'types/PersonCollectionValuesParams';
import CollectionsSearchParams from 'types/CollectionsSearchParams';
import DictionariesSearchParams from 'types/DictionariesSearchParams';
import SupportMessagesParams from 'types/SupportMessagesParams';
import AdminApplicationsParams from 'types/AdminApplicationsParams';
import MergesParams from 'types/MergesParams';
import AdminDictionaryDTO from 'types/AdminDictionaryDTO';
import AdminCollectionsParams from 'types/AdminCollectionsParams';
import AdminDevelopersParams from 'types/AdminDevelopersParams';
import AdminPersonsParams from 'types/AdminPersonsParams';
import AdminValuesParams from 'types/AdminValuesParams';
import PaginationParams from 'types/PaginationParams';

import { getAuthHeaders } from './network';

/**
 * Запрашивает токен персоны с указанным кодом.
 * @param code OAuth код авторизации пользователя.
 */
export const getPersonToken = async (code: OpenAPI.Parameters.Code) =>
  axios.get<string>('/api/auth/complete', { params: { code } });

/**
 * Запрашивает список приложений.
 */
export const getApplicationsList = async (token: string) =>
  axios.get<OpenAPI.ResponseInfoApplication[]>('/api/applications', {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает информацию о приложении.
 * @param id ID приложения.
 * @param token Токен персоны.
 */
export const getApplication = async (
  id: OpenAPI.ResponseInfoApplication['id'],
  token: string
) =>
  axios.get<OpenAPI.ResponseInfoApplication>(`/api/applications/${id}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает коллекции приложения.
 * @param access_token Токен приложения.
 * @param params Параметры поиска.
 */
export const getApplicationCollections = async (
  access_token: OpenAPI.ResponseInfoApplication['access_token'],
  params: CollectionsSearchParams = {}
) => {
  const response = await axios.get<OpenAPI.PrivateResponseListCollection[]>(
    '/api/private/collections',
    {
      headers: getAuthHeaders(access_token),
      params
    }
  );

  // TODO: Костыль для `x-total-records`. Возвращается только при offset 0.
  if (params.offset) {
    const countResponse = await getApplicationCollections(access_token, {
      ...params,
      offset: 0
    });

    response.headers['x-total-records'] =
      countResponse.headers['x-total-records'];
  }

  return response;
};

/**
 * Запрашивает словари приложения.
 * @param access_token Токен приложения.
 * @param params Параметры поиска.
 */
export const getApplicationDictionaries = async (
  access_token: OpenAPI.ResponseInfoApplication['access_token'],
  params: DictionariesSearchParams = {}
) => {
  const response = await axios.get<OpenAPI.ResponseListDictionary[]>(
    '/api/private/dictionaries',
    {
      headers: getAuthHeaders(access_token),
      params
    }
  );

  // TODO: Костыль для `x-total-records`. Возвращается только при offset 0.
  if (params.offset) {
    const countResponse = await getApplicationDictionaries(access_token, {
      ...params,
      offset: 0
    });

    response.headers['x-total-records'] =
      countResponse.headers['x-total-records'];
  }

  return response;
};

/**
 * Запрашивает справочник.
 * @param id ID справочника.
 * @param access_token Токен приложения.
 */
export const getDictionary = async (
  id: OpenAPI.Parameters.DictionaryId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.get<OpenAPI.ResponseOwnDictionary | OpenAPI.ResponseForeignDictionary>(
    `/api/dictionaries/${id}`,
    { headers: getAuthHeaders(access_token) }
  );

/**
 * Отправляет POST запрос для создания коллекции.
 * @param collection Данные коллекции.
 * @param access_token Токен приложения.
 */
export const createCollection = async (
  collection: OpenAPI.RequestCollection,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post<OpenAPI.ResponseOwnCollection>('/api/collections', collection, {
    headers: getAuthHeaders(access_token)
  });

/**
 * Отправляет POST запрос для создания справочника.
 * @param dictionary Данные справочника.
 * @param access_token Токен приложения.
 */
export const createDictionary = async (
  dictionary: OpenAPI.RequestDraftDictionary,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post<OpenAPI.ResponseOwnDictionary>('/api/dictionaries', dictionary, {
    headers: getAuthHeaders(access_token)
  });

/**
 * Запрашивает категории для справочников.
 */
export const getDictionaryCategories = async () =>
  axios.get<OpenAPI.ResponseCategory[]>('/api/dictionary_categories');

/**
 * Запрашивает категории для коллекций.
 */
export const getCollectionCategories = async () =>
  axios.get<OpenAPI.ResponseCategory[]>('/api/collection_categories');

/**
 * Отправляет запрос на редактирование коллекции.
 * @param id ID коллекции.
 * @param collection Данные коллекции.
 * @param access_token Токен приложения.
 */
export const updateCollection = async (
  id: OpenAPI.Parameters.CollectionId,
  collection: OpenAPI.UpdateCollection,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.put<OpenAPI.ResponseOwnCollection>(
    `/api/collections/${id}`,
    collection,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Запрашивает коллекцию.
 * @param id ID коллекции.
 * @param access_token Токен приложения.
 */
export const getCollection = async (
  id: OpenAPI.Parameters.CollectionId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.get<OpenAPI.ResponseOwnCollection>(`/api/collections/${id}`, {
    headers: getAuthHeaders(access_token)
  });

/**
 * Отправляет запрос на создание поля коллекции.
 * @param collectionId ID коллекции.
 * @param field Данные поля.
 * @param access_token Токен приложения.
 */
export const createCollectionField = async (
  collectionId: OpenAPI.Parameters.CollectionId,
  field: OpenAPI.RequestField,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post<OpenAPI.ResponseField>(
    `/api/collections/${collectionId}/fields`,
    field,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на редактирование поля коллекции.
 * @param collectionId ID коллекции.
 * @param fieldID ID поля.
 * @param field Данные поля.
 * @param access_token Токен приложения.
 */
export const changeCollectionField = async (
  collectionId: OpenAPI.Parameters.CollectionId,
  fieldID: OpenAPI.Parameters.FieldId,
  field: OpenAPI.UpdateField,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.put<OpenAPI.ResponseField>(
    `/api/collections/${collectionId}/fields/${fieldID}`,
    field,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Запрашивает информацию о персоне.
 * @param token Токен персоны.
 */
export const getPerson = async (token: string) =>
  axios.get<{
    Id: number;
    FirstName: string;
    LastName: string;
    FatherName?: string;
    Photo?: { Original: string };
  }>('/api/private/person', {
    headers: getAuthHeaders(token)
  });

/**
 *
 * @param id ID приложения.
 * @param data Информация приложения.
 * @param access_token Токен приложения.
 */
export const updateApplication = async (
  id: OpenAPI.Parameters.ApplicationId,
  application: OpenAPI.RequestUpdateApplication,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.put<OpenAPI.ResponseInfoApplication>(
    `/api/applications/${id}`,
    application,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на редактирование справочника.
 * @param id ID справочника.
 * @param dictionary Данные справочника.
 * @param access_token Токен приложения.
 */
export const updateDictionary = async (
  id: OpenAPI.Parameters.DictionaryId,
  dictionary: OpenAPI.UpdateDictionary,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.put<OpenAPI.ResponseOwnDictionary>(
    `/api/dictionaries/${id}`,
    dictionary,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на создание элемента справочника.
 * @param id ID справочника.
 * @param element Данные элемента.
 * @param access_token Токен приложения.
 */
export const createDictionaryElement = async (
  id: OpenAPI.Parameters.DictionaryId,
  element: DictionaryElementDTO,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post<OpenAPI.ResponseOwnDictionary['elements']>(
    `/api/dictionaries/${id}/elements`,
    [element],
    {
      headers: getAuthHeaders(access_token),
      params: element.parent && {
        parent_element_id: element.parent
      }
    }
  );

/**
 * Отправляет запрос на обновление элемента справочника.
 * @param dictionaryId ID справочника.
 * @param elementId ID элемента.
 * @param element Новые данные элемента.
 * @param access_token Токен приложения.
 */
export const updateDictionaryElement = async (
  dictionaryId: OpenAPI.Parameters.DictionaryId,
  elementId: OpenAPI.Parameters.ElementId,
  element: OpenAPI.UpdateDictElement,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.put<OpenAPI.ResponseOwnDictElement>(
    `/api/dictionaries/${dictionaryId}/elements/${elementId}`,
    element,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на удаление элемента справочника.
 * @param dictionaryId ID справочника.
 * @param elementId ID элемента.
 * @param access_token Токен приложения.
 */
export const deleteDictionaryElement = async (
  dictionaryId: OpenAPI.Parameters.DictionaryId,
  elementId: OpenAPI.Parameters.ElementId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.delete<void>(
    `/api/dictionaries/${dictionaryId}/elements/${elementId}`,
    {
      headers: getAuthHeaders(access_token),
      params: {
        children_action: 'remove'
      }
    }
  );

/**
 * Запрашивает разрешения коллекции.
 * @param collectionId ID коллекции.
 * @param access_token Токен приложения.
 */
export const getCollectionPermissions = async (
  collectionId: OpenAPI.Parameters.CollectionId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.get<OpenAPI.ResponseCollectionPermission[]>(
    `/api/collections/${collectionId}/permissions`,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на создание прав доступа.
 * @param collectionId ID коллекции.
 * @param permission Права доступа.
 * @param access_token Токен приложения.
 */
export const createCollectionPermission = async (
  collectionId: OpenAPI.Parameters.CollectionId,
  permission: OpenAPI.RequestCollectionPermission,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post<OpenAPI.ResponseCollectionPermission>(
    `/api/collections/${collectionId}/permissions`,
    permission,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на удаление прав доступа.
 * @param collectionId ID коллекции.
 * @param permissionId ID прав.
 * @param access_token Токен приложения.
 */
export const deleteCollectionPermission = async (
  collectionId: OpenAPI.Parameters.CollectionId,
  permissionId: OpenAPI.Parameters.PermissionId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.delete<OpenAPI.ResponseCollectionPermission>(
    `/api/collections/${collectionId}/permissions/${permissionId}`,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на добавление новых значений в коллекцию.
 * @param collectionId ID коллекции.
 * @param file Файл со значениями.
 * @param access_token Токен приложения.
 */
export const sendFileWithNewValues = async (
  collectionId: OpenAPI.Parameters.CollectionId,
  file: OpenAPI.Parameters.File,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post<OpenAPI.ResponseInsertedValues>(
    `/api/collections/${collectionId}/values-from-file`,
    file,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на создание приложения.
 * @param application Данные приложения.
 * @param token Токен персоны.
 */
export const createApplicationRequest = (
  application: OpenAPI.BodyCreateApplicationRequestApiApplicationRequestsPost,
  token: string
) => {
  const form = new FormData();
  Object.entries(application).forEach(([key, value]) =>
    form.append(key, value)
  );

  return axios.post<OpenAPI.ResponseCollectionPermission>(
    `/api/application-requests`,
    form,
    {
      headers: getAuthHeaders(token)
    }
  );
};

/**
 * Запрашивает полученные мерж-реквесты.
 * @param access_token Токен приложения.
 * @param params Параметры реквестов.
 */
export const getReceivedRequests = async (
  access_token: OpenAPI.ResponseInfoApplication['access_token'],
  params: RequestsParams = {}
) =>
  axios.get<OpenAPI.MergeRequestExpanded[]>('/api/merges/received', {
    headers: getAuthHeaders(access_token),
    params
  });

/**
 * Запрашивает реквест.
 * @param id ID реквеста.
 * @param access_token Токен приложения.
 */
export const getRequest = async (
  id: OpenAPI.Parameters.MergeId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.get<OpenAPI.MergeRequestExpanded>(`/api/merges/${id}`, {
    headers: getAuthHeaders(access_token)
  });

/**
 * Запрашивает действия реквеста.
 * @param requestId ID реквеста.
 * @param access_token Токен приложения.
 */
export const getRequestActions = async (
  requestId: OpenAPI.Parameters.MergeId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.get<OpenAPI.ResponseMergeAction[]>(`/api/merges/${requestId}/actions`, {
    headers: getAuthHeaders(access_token)
  });

/**
 * Отправляет запрос на создание merge реквеста.
 * @param dictionaryId ID справочника.
 * @param merge Реквест.
 * @param access_token Токен приложения.
 */
export const createRequest = async (
  dictionaryId: OpenAPI.Parameters.DictionaryId,
  merge: OpenAPI.RequestDictMerge,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post<OpenAPI.ResponseDictMerge>(
    `/api/dictionaries/${dictionaryId}/merges`,
    merge,
    {
      headers: getAuthHeaders(access_token)
    }
  );

/**
 * Отправляет запрос на принятие входящего реквеста.
 * @param mergeId ID реквеста.
 * @param access_token Токен приложения.
 */
export const acceptRequest = async (
  mergeId: OpenAPI.Parameters.MergeId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post(`/api/merges/${mergeId}/accept`, undefined, {
    headers: getAuthHeaders(access_token)
  });

/**
 * Отправляет запрос на отмену входящего реквеста.
 * @param mergeId ID реквеста.
 * @param access_token Токен приложения.
 */
export const declineRequest = async (
  mergeId: OpenAPI.Parameters.MergeId,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.post(`/api/merges/${mergeId}/decline`, undefined, {
    headers: getAuthHeaders(access_token)
  });

/**
 * Запрашивает статистику приложения
 * @param params Параметры статистики.
 * @param access_token Токен приложения.
 */
export const getStatistics = (
  params: StatisticsParams,
  access_token: OpenAPI.ResponseInfoApplication['access_token']
) =>
  axios.get<OpenAPI.ResponseMetric[]>('/api/private/statistics', {
    params,
    headers: getAuthHeaders(access_token)
  });

/**
 * Запрашивает персоны приложений совпадающие с пользователем
 * @param token Токен персоны.
 */
export const getPersonMatching = async (token: string) =>
  axios.get<OpenAPI.PrivateResponseAppPerson[]>(
    '/api/private/person/matching',
    {
      headers: getAuthHeaders(token)
    }
  );

/**
 * Отправляет подтверждение связи с совпадающей персоной приложения.
 * @param appPersonId ID персоны приложения.
 * @param token Токен персоны.
 */
export const acceptPersonMatching = async (
  appPersonId: OpenAPI.Parameters.AppPersonId,
  token: string
) =>
  axios.post(`/api/private/person/matching/${appPersonId}/accept`, undefined, {
    headers: getAuthHeaders(token)
  });

/**
 * Отклоняет связь с совпадающей персоной приложения.
 * @param appPersonId ID персоны приложения.
 * @param token Токен персоны.
 */
export const declinePersonMatching = async (
  appPersonId: OpenAPI.Parameters.AppPersonId,
  token: string
) =>
  axios.post(`/api/private/person/matching/${appPersonId}/decline`, undefined, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает коллекции в которых есть данные ассоциированные с персоной.
 * @param token Токен персоны.
 */
export const getListCollectionsWithData = async (
  params: PersonCollectionsParams = {},
  token: string
) =>
  axios.get<OpenAPI.PrivateResponsePersonCollectionWithDate[]>(
    '/api/private/person/collections',
    {
      headers: getAuthHeaders(token),
      params
    }
  );

/**
 * Чтение информации о коллекции, в которой есть ассоциированные с пользователем данные.
 * @param collectionId ID коллекции.
 * @param providerId ID провайдера.
 * @param token Токен персоны.
 */
export const getPersonCollection = async (
  collectionId: OpenAPI.Parameters.CollectionId,
  providerId: OpenAPI.Parameters.ProviderId,
  token: string
) =>
  axios.get<OpenAPI.PrivateResponsePersonCollectionWithDate>(
    `/api/private/person/collections/${collectionId}/providers/${providerId}`,
    {
      headers: getAuthHeaders(token)
    }
  );

/**
 * Запрашивает данные коллекции, которые ассоциированны с персоной.
 * @param params Параметры.
 * @param token Токен персоны.
 */
export const getListCollectionValues = async (
  { collection_id: collectionId, ...params }: PersonCollectionValuesParams,
  token: string
) =>
  axios.get<OpenAPI.PrivateResponsePersonValues[]>(
    `/api/private/person/collections/${collectionId}/values`,
    {
      headers: getAuthHeaders(token),
      params
    }
  );

/**
 * Отправляет сообщение в поддержку LeaderID.
 * @param message Данные сообщения.
 * @param token Токен персоны.
 */
export const createSupportRequest = async (
  message: OpenAPI.RequestSupportMessage,
  token: string
) => {
  return axios.post(`/api/private/person/support_messages`, message, {
    headers: getAuthHeaders(token)
  });
};

/**
 * Запрашивает приложения персоны, которые имеют связь с персоной AppPerson.
 * @param token Токен персоны.
 */
export const getPersonApplications = async (token: string) =>
  axios.get<OpenAPI.ApplicationInline[]>('/api/private/person/applications', {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает информацию о значение-файле, ассоциированном с персоной.
 * @param fileId ID файла.
 * @param token Токен пользователя.
 */
export const getPersonCollectionFile = async (
  fileId: OpenAPI.Parameters.FileId,
  token: string
) =>
  axios.get<OpenAPI.FileValue>(`/api/private/person/files/${fileId}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает список обращений в поддержку.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const getAdminSupportMessages = async (
  token: string,
  params: SupportMessagesParams = {}
) =>
  axios.get<OpenAPI.ResponseListSupportMessage[]>(
    '/api/private/admin/support_messages',
    {
      headers: getAuthHeaders(token),
      params: {
        total_records: true,
        ...params
      },
      paramsSerializer: qs.stringify
    }
  );

/**
 * Отправляет запрос на чтение сообщения.
 * @param token Токен пользователя.
 * @param messageId ID сообщения
 */
export const readAdminSupportMessage = async (
  token: string,
  messageId: OpenAPI.Parameters.MessageId
) =>
  axios.get<void>(`/api/private/admin/support_messages/${messageId}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Отправляет запрос на получение счетчиков дашборда админа.
 * @param token Токен пользователя.
 */
export const getAdminDashboardCounters = async (token: string) =>
  axios.get<OpenAPI.ResponseDashboardCounters>('/api/private/admin/dashboard', {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает список merge-реквестов.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const getAdminMerges = async (token: string, params?: MergesParams) =>
  axios.get<OpenAPI.ResponseMergeWithDictionary[]>(
    '/api/private/admin/merges',
    {
      headers: getAuthHeaders(token),
      params: {
        total_records: true,
        ...params
      },
      paramsSerializer: qs.stringify
    }
  );

/**
 * Запрашивает merge-реквест.
 * @param id ID реквеста.
 * @param token Токен пользователя.
 */
export const getAdminMerge = async (
  id: OpenAPI.Parameters.MergeId,
  token: string
) =>
  axios.get<OpenAPI.MergeRequestDetailed>(`/api/private/admin/merges/${id}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает справочник.
 * @param id ID справочника.
 * @param token Токен пользователя.
 */
export const getAdminDictionary = async (
  id: OpenAPI.Parameters.DictionaryId,
  token: string
) =>
  axios.get<AdminDictionaryDTO>(`/api/private/admin/dictionaries/${id}`, {
    headers: getAuthHeaders(token),
    params: {
      get_application: true
    }
  });

/**
 * Запрашивает список приложений для админа.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const getAdminApplications = async (
  token: string,
  params?: AdminApplicationsParams
) =>
  axios.get<OpenAPI.ApplicationDetailed[] | OpenAPI.ApplicationInline[]>(
    '/api/private/admin/applications',
    {
      headers: getAuthHeaders(token),
      params,
      paramsSerializer: qs.stringify
    }
  );

/**
 * Принимает merge request.
 * @param id Merge id.
 * @param token Токен пользователя.
 */
export const acceptAdminMerge = async (
  id: OpenAPI.Parameters.MergeId,
  token: string
) =>
  axios.post<void>(`/api/private/admin/merges/${id}/accept`, undefined, {
    headers: getAuthHeaders(token)
  });

/**
 * Отклоняет merge request.
 * @param id Merge id.
 * @param token Токен пользователя.
 */
export const declineAdminMerge = async (
  id: OpenAPI.Parameters.MergeId,
  token: string
) =>
  axios.post<void>(`/api/private/admin/merges/${id}/decline`, undefined, {
    headers: getAuthHeaders(token)
  });

/**
 * Отправляет запрос на создание merge реквеста.
 * @param dictionaryId ID справочника.
 * @param merge Реквест.
 * @param token Токен пользователя.
 */
export const createAdminMerge = async (
  dictionaryId: OpenAPI.Parameters.DictionaryId,
  merge: OpenAPI.RequestDictMerge,
  token: string
) =>
  axios.post<OpenAPI.ResponseDictMergeWithActions>(
    `/api/private/admin/merges`,
    merge,
    {
      params: {
        dictionary_id: dictionaryId
      },
      headers: getAuthHeaders(token)
    }
  );

/**
 * Запрашивает приложение для админа.
 * @param id ID приложения.
 * @param token Токен пользователя.
 */
export const getAdminApplication = async (
  id: OpenAPI.Parameters.ApplicationId,
  token: string
) =>
  axios.get<OpenAPI.ApplicationFull>(`/api/private/admin/applications/${id}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает коллекции приложения для админа.
 * @param id ID приложения.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const getAdminApplicationCollections = async (
  id: OpenAPI.Parameters.ApplicationId,
  token: string,
  params?: AdminCollectionsParams
) =>
  axios.get<OpenAPI.ApplicationCollection[]>(
    `/api/private/admin/applications/${id}/collections`,
    {
      headers: getAuthHeaders(token),
      params: {
        ...params,
        total_records: true
      }
    }
  );

/**
 * Запрашивает коллекцию для админа.
 * @param id ID коллекции.
 * @param token Токен пользователя.
 */
export const getAdminCollection = async (
  id: OpenAPI.Parameters.CollectionId,
  token: string
) =>
  axios.get<OpenAPI.Collection>(`/api/private/admin/collections/${id}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает справочники приложения для админа.
 * @param id ID приложения.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const getAdminApplicationDictionaries = async (
  id: OpenAPI.Parameters.ApplicationId,
  token: string,
  params?: AdminCollectionsParams
) =>
  axios.get<OpenAPI.DictionaryWithCounter[]>(
    `/api/private/admin/applications/${id}/dictionaries`,
    {
      headers: getAuthHeaders(token),
      params: {
        ...params,
        total_records: true
      }
    }
  );

/**
 * Запрашивает разработчиков.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const getAdminDevelopers = async (
  token: string,
  params?: AdminDevelopersParams
) =>
  axios.get<OpenAPI.Developer[]>(`/api/private/admin/developers`, {
    headers: getAuthHeaders(token),
    params
  });

/**
 * Запрашивает список персон для админа.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const getAdminPersons = async (
  token: string,
  params?: AdminPersonsParams
) =>
  axios.get<OpenAPI.PersonDetailed[]>(`/api/private/admin/persons`, {
    headers: getAuthHeaders(token),
    params: {
      ...params,
      total_records: true
    }
  });

/**
 * Запрашивает список App Persons для админа.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const getAdminAppPersons = async (
  token: string,
  params?: AdminPersonsParams
) =>
  axios.get<OpenAPI.AppPersonDetailed[]>(`/api/private/admin/app_persons`, {
    headers: getAuthHeaders(token),
    params: {
      ...params,
      total_records: true
    }
  });

/**
 * Запрашивает персону.
 * @param id ID персоны.
 * @param token Токен пользователя.
 */
export const getAdminPerson = async (
  id: OpenAPI.Parameters.PersonId,
  token: string
) =>
  axios.get<OpenAPI.Person>(`/api/private/admin/persons/${id}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает связанные приложения персоны.
 * @param id ID персоны.
 * @param token Токен пользователя.
 */
export const getAdminPersonApplications = async (
  id: OpenAPI.Parameters.PersonId,
  token: string
) =>
  axios.get<OpenAPI.AppPersonApplication[]>(
    `/api/private/admin/persons/${id}/applications`,
    {
      headers: getAuthHeaders(token)
    }
  );

/**
 * Запрашивает коллекции AppPerson.
 * @param id ID AppPerson.
 * @param token Токен пользователя.
 */
export const getAdminAppPersonCollections = async (
  id: OpenAPI.Parameters.AppPersonId,
  token: string
) =>
  axios.get<OpenAPI.AppPersonCollection[]>(
    `/api/private/admin/app_persons/${id}/collections`,
    {
      headers: getAuthHeaders(token)
    }
  );

/**
 * Запрашивает AppPerson.
 * @param id ID AppPerson.
 * @param token Токен пользователя.
 */
export const getAdminAppPerson = async (
  id: OpenAPI.Parameters.PersonId,
  token: string
) =>
  axios.get<OpenAPI.AppPersonExpanded>(`/api/private/admin/app_persons/${id}`, {
    headers: getAuthHeaders(token)
  });

/**
 * Запрашивает поля коллекции для админа.
 * @param id ID коллекции.
 * @param token Токен пользователя.
 */
export const getAdminCollectionFields = async (
  id: OpenAPI.Parameters.CollectionId,
  token: string
) =>
  axios.get<OpenAPI.ResponseField[]>(
    `/api/private/admin/collections/${id}/fields`,
    {
      headers: getAuthHeaders(token)
    }
  );

/**
 * Запрашивает значения коллекции.
 * @param id ID коллекции.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const getAdminCollectionValues = async (
  id: OpenAPI.Parameters.CollectionId,
  token: string,
  params?: AdminValuesParams
) =>
  axios.get<OpenAPI.ResponseValues[]>(
    `/api/private/admin/collections/${id}/values`,
    {
      headers: getAuthHeaders(token),
      params: {
        ...params,
        total_records: true
      },
      paramsSerializer: qs.stringify
    }
  );

/**
 * Запрашивает функции сравнения.
 * @param token Токен пользователя.
 */
export const getAdminCompareFunctions = async (token: string) =>
  axios.get<OpenAPI.ValuesCompareFunctions[]>(
    `/api/private/admin/collections/values-compare-functions`,
    {
      headers: getAuthHeaders(token)
    }
  );

/**
 * Запрашивает список заявок на создание приложений.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const getAdminApplicationRequests = async (
  token: string,
  params?: PaginationParams
) =>
  axios.get<OpenAPI.BaseApplicationRequest[]>(
    '/api/private/application-requests',
    {
      params: {
        total_records: true,
        ...params
      },
      headers: getAuthHeaders(token)
    }
  );

/**
 * Отправляет запрос на обновление запроса на создание приложения.
 * @param token Токен пользователя.
 * @param id ID запроса.
 * @param values Данные запроса.
 */
export const updateAdminApplicationRequest = async (
  token: string,
  id: OpenAPI.Parameters.ApplicationRequestId,
  values: OpenAPI.UpdateApplicationRequest
) =>
  axios.put<OpenAPI.BaseApplicationRequest>(
    `/api/private/application-requests/${id}`,
    values,
    {
      headers: getAuthHeaders(token)
    }
  );
