import { ComponentProps } from 'react';
import Textarea from 'components/Textarea';
import createFieldControl from 'helpers/createFieldControl';

/**
 * Обертка над `Textarea` для использования c `react-final-form`.
 */
const FieldTextarea = createFieldControl<
  string,
  HTMLTextAreaElement,
  ComponentProps<typeof Textarea>
>(Textarea, { type: 'text' });

export default FieldTextarea;
