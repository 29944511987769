import React from 'react';
import ReactSelect from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { Props as SelectProps } from 'react-select/src/Select';
import clsx from 'clsx';

import ApplicationDTO from 'types/ApplicationDTO';

import './application-search.scss';

type Props = SelectProps<ApplicationDTO>;

const components: Partial<SelectComponents<ApplicationDTO>> = {
  IndicatorSeparator: null,
  DropdownIndicator: null,
  Option: ({ innerRef, innerProps, data, isSelected, isFocused }) => {
    const application = data as ApplicationDTO;
    return (
      <div
        className={clsx(
          'application-search__option',
          isSelected && 'application-search__option--selected',
          isFocused && 'application-search__option--focused'
        )}
        ref={innerRef}
        {...innerProps}
      >
        {application && (
          <div className="application-search__application">
            <div
              className="application-search__application-logo"
              style={{ backgroundImage: `url(${application.logo})` }}
            />
            <div className="application-search__application-name">
              {application.name}
            </div>
          </div>
        )}
      </div>
    );
  }
};

const ApplicationSearch: React.FC<Props> = ({ className, ...restProps }) => (
  <ReactSelect
    className={clsx('application-search', className)}
    classNamePrefix="application-search"
    placeholder="Поиск приложений"
    components={components}
    getOptionValue={option => String(option.id)}
    getOptionLabel={option => option.name as string}
    {...restProps}
  />
);

export default ApplicationSearch;
