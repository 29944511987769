import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import { AppThunk } from 'store';

export const fetchCollectionCategoriesAsync = createAsyncAction(
  'developer/FETCH_COLLECTION_CATEGORIES_REQUEST',
  'developer/FETCH_COLLECTION_CATEGORIES_SUCCESS',
  'developer/FETCH_COLLECTION_CATEGORIES_FAILURE'
)<void, OpenAPI.ResponseCategory[], any>();

/**
 * Загружает список категорий для справочников.
 */
export const fetchCollectionCategories = (): AppThunk<Promise<
  OpenAPI.ResponseCategory[] | undefined
>> => async dispatch => {
  try {
    dispatch(fetchCollectionCategoriesAsync.request());
    const response = await api.getCollectionCategories();
    dispatch(fetchCollectionCategoriesAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionCategoriesAsync.failure(error));
  }
};
