import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import AdminApplicationDTO from 'types/AdminApplicationDTO';
import { AppThunk } from 'store';
import AdminApplicationsParams from 'types/AdminApplicationsParams';
import * as api from 'services/api';
import AdminDevelopersParams from 'types/AdminDevelopersParams';

export const setApplicationsParams = createStandardAction(
  'admin/SET_APPLICATIONS_PARAMS'
)<AdminApplicationsParams>();

export const fetchApplicationsAsync = createAsyncAction(
  'admin/FETCH_APPLICATIONS_REQUEST',
  'admin/FETCH_APPLICATIONS_SUCCESS',
  'admin/FETCH_APPLICATIONS_FAILURE'
)<void, AdminApplicationDTO[], any>();

export const fetchDevelopersAsync = createAsyncAction(
  'admin/FETCH_DEVELOPERS_REQUEST',
  'admin/FETCH_DEVELOPERS_SUCCESS',
  'admin/FETCH_DEVELOPERS_FAILURE'
)<void, OpenAPI.Developer[], any>();

/**
 * Загружает приложения.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const fetchApplications = (
  token: string,
  params?: AdminApplicationsParams
): AppThunk<Promise<AdminApplicationDTO[]>> => async dispatch => {
  try {
    dispatch(fetchApplicationsAsync.request());
    const response = await api.getAdminApplications(token, {
      get_detailed_info: true,
      ...params
    });
    dispatch(
      fetchApplicationsAsync.success(response.data as AdminApplicationDTO[])
    );
    return response.data as AdminApplicationDTO[];
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationsAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает разработчиков.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const fetchDevelopers = (
  token: string,
  params?: AdminDevelopersParams
): AppThunk<Promise<OpenAPI.Developer[]>> => async dispatch => {
  try {
    dispatch(fetchDevelopersAsync.request());
    const response = await api.getAdminDevelopers(token, params);
    dispatch(fetchDevelopersAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchDevelopersAsync.failure(error));
    throw error;
  }
};
