enum Routes {
  // Public.
  INDEX = '/',
  LOGOUT = '/logout',
  AUTH_COMPLETE = '/auth/complete',
  PLAYGROUND = '/playground',
  // User.
  USER_APPLICATIONS_REQUESTS = '/user/applications-requests',
  USER_COLLECTIONS_MANAGEMENT = '/user/collections-management',
  USER_COLLECTION = '/user/collections-management/:applicationId(\\d+)/:collectionId(\\d+)',
  // Developer.
  DEVELOPER_COLLECTIONS = '/developer/collections',
  DEVELOPER_DICTIONARIES = '/developer/dictionaries',
  DEVELOPER_APPLICATIONS = '/developer/applications',
  DEVELOPER_APPLICATION = '/developer/applications/:id(\\d+)',
  DEVELOPER_REQUEST = '/developer/requests/:id(\\d+)?',
  DEVELOPER_COLLECTION_EDIT = '/developer/collections/edit/:id(\\d+)',
  DEVELOPER_COLLECTION_ADD = '/developer/collections/add',
  DEVELOPER_DICTIONARY_EDIT = '/developer/dictionaries/edit/:id(\\d+)',
  DEVELOPER_DICTIONARY_ADD = '/developer/dictionaries/add',
  DEVELOPER_USAGE = '/developer/usage',
  DEVELOPER_DOCS = '/developer/docs',
  DEVELOPER_SDK = '/developer/sdk',
  DEVELOPER_LAWYER = '/developer/lawyer',
  DEVELOPER_POLICY = '/developer/policy',
  DEVELOPER_SUPPORT = '/developer/support',
  // Administrator/Moderator.
  ADMIN_REQUESTS = '/admin/requests',
  ADMIN_REQUESTS_REPORTS = '/admin/requests/reports',
  ADMIN_REQUESTS_MERGES = '/admin/requests/merges',
  ADMIN_REQUESTS_MERGE = '/admin/requests/merges/:id(\\d+)',
  ADMIN_APPLICATIONS = '/admin/applications',
  ADMIN_APPLICATION = '/admin/applications/:id(\\d+)',
  ADMIN_APPLICATION_COLLECTIONS = '/admin/applications/:id(\\d+)/collections',
  ADMIN_APPLICATION_COLLECTION = '/admin/applications/:applicationId(\\d+)/collections/:collectionId(\\d+)',
  ADMIN_APPLICATION_DICTIONARIES = '/admin/applications/:id(\\d+)/dictionaries',
  ADMIN_DICTIONARY = '/admin/applications/dictionaries/:id(\\d+)',
  ADMIN_PERSONS = '/admin/persons',
  ADMIN_PERSON_APPLICATIONS = '/admin/persons/:id(\\d+)/applications',
  ADMIN_APP_PERSON_COLLECTIONS = '/admin/persons/:id(\\d+)/collections',
  ADMIN_APP_PERSON_COLLECTION = '/admin/persons/:appPersonId(\\d+)/collections/:collectionId(\\d+)',
  ADMIN_APPLICATION_REQUESTS = '/admin/application-requests'
}

export default Routes;
