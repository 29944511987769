import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import clsx from 'clsx';

import Badge from 'components/Badge';

import './header-nav-item.scss';

type Props = { count?: number } & NavLinkProps;

const HeaderNavItem: React.FC<Props> = ({
  className,
  children,
  to,
  count,
  ...restProps
}) => (
  <NavLink
    to={to}
    activeClassName="header-nav-item--active"
    className={clsx('header-nav-item', className)}
    {...restProps}
  >
    {children}
    {!!count && <Badge>{count}</Badge>}
  </NavLink>
);

export default HeaderNavItem;
