import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import DictionaryDTO from 'types/DictionaryDTO';
import ApplicationDTO from 'types/ApplicationDTO';

import Card from 'components/Card';
import DataRow from 'components/DataRow';
import { DataItem } from 'components/DataRow/DataRow';

import {
  getStatusColor,
  getStatusTitle,
  getElementsCount
} from 'services/dictionaries';
import { getCategoryName } from 'services/categories';

import './dictionary-list-item.scss';

type Props = {
  dictionary: DictionaryDTO;
  application: ApplicationDTO;
} & React.ComponentProps<typeof Card>;

const DictionaryListItem: React.FC<Props> = ({
  dictionary,
  application,
  className,
  ...restProps
}) => {
  const { t } = useTranslation();
  const dataItems = useMemo<DataItem[]>(
    () => [
      {
        header: t('Владелец'),
        headerProps: {
          className: 'dictionary-list-item__data-header'
        },
        body: application.name,
        style: { width: '35%' }
      },
      {
        header: t('Категория'),
        headerProps: {
          className: 'dictionary-list-item__data-header'
        },
        body: getCategoryName(dictionary.category_object),
        style: { width: '35%' }
      },
      {
        header: t('Статус'),
        headerProps: {
          className: 'dictionary-list-item__data-header'
        },
        body: getStatusTitle(dictionary.is_active),
        bodyProps: {
          style: { color: getStatusColor(dictionary.is_active) }
        },
        style: { width: '20%' }
      },
      {
        header: t('Строк'),
        headerProps: {
          className: 'dictionary-list-item__data-header'
        },
        body: getElementsCount(dictionary),
        style: { width: '10%', textAlign: 'right' }
      }
    ],
    [application.name, dictionary, t]
  );
  return (
    <Card className={clsx('dictionary-list-item', className)} {...restProps}>
      <div className="dictionary-list-item__name">{dictionary.name}</div>
      <DataRow className="dictionary-list-item__data" items={dataItems} />
    </Card>
  );
};

export default DictionaryListItem;
