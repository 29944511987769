import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { getPageTitle } from 'services/helmet';

const AppMeta: React.FC = () => {
  const { i18n } = useTranslation();
  const htmlAttributes = useMemo(() => ({ lang: i18n.language }), [
    i18n.language
  ]);

  return <Helmet htmlAttributes={htmlAttributes} title={getPageTitle()} />;
};

export default AppMeta;
