import { createReducer } from 'typesafe-actions';
import { ApplicationFilterState, ApplicationFilterAction } from './types';
import {
  fetchApplicationFilterAsync,
  showAllApplicationsFilter,
  changeApplicationFilter
} from './actions';

export const initialState: ApplicationFilterState = {
  ids: []
};

export default createReducer<ApplicationFilterState, ApplicationFilterAction>(
  initialState
)
  .handleAction(changeApplicationFilter, (state, { payload }) => ({
    ...state,
    ids: payload
  }))
  .handleAction(fetchApplicationFilterAsync.success, (state, { payload }) => ({
    ...state,
    data: payload
  }))
  .handleAction(showAllApplicationsFilter, state => ({
    ...state,
    showAll: true
  }));
