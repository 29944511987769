import { createAction, createAsyncAction } from 'typesafe-actions';
import DictionaryDTO from 'types/DictionaryDTO';
import DictionaryElementDTO from 'types/DictionaryElementDTO';
import RequestDTO from 'types/RequestDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import * as api from 'services/api';
import { getErrorMessage } from 'services/network';
import { AppThunk } from 'store';

export const createRequestAsync = createAsyncAction(
  'developer/CREATE_REQUEST_REQUEST',
  'developer/CREATE_REQUEST_SUCCESS',
  'developer/CREATE_REQUEST_FAILURE'
)<void, RequestDTO, any>();

/**
 * Открывает создание реквеста.
 * @param id ID справочника.
 * @param elements Элементы.
 */
export const openCreateRequest = createAction(
  'developer/OPEN_CREATE_REQUEST',
  action => (id: DictionaryDTO['id'], elements: DictionaryElementDTO[]) =>
    action({ id, elements })
);

/**
 * Закрывает создание реквеста.
 */
export const closeCreateRequest = createAction(
  'developer/CLOSE_CREATE_REQUEST'
);

/**
 * Создает реквест.
 * @param id ID справочника.
 * @param merge Реквест.
 * @param access_token Токен приложения.
 */
export const createRequest = (
  id: DictionaryDTO['id'],
  merge: OpenAPI.RequestDictMerge,
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<RequestDTO>> => async dispatch => {
  try {
    dispatch(createRequestAsync.request());
    const response = await api.createRequest(id, merge, access_token);
    const mergeResponse = await api.getRequest(response.data.id, access_token);
    dispatch(createRequestAsync.success(mergeResponse.data));
    return mergeResponse.data;
  } catch (error) {
    console.error(error);
    dispatch(createRequestAsync.failure(getErrorMessage(error)));
    throw error;
  }
};
