import axios, { AxiosResponse, AxiosError } from 'axios';
import HttpStatus from 'http-status-codes';
import CountableRecords from 'types/CountableRecords';

/**
 * Возвращает сообщение ошибки.
 * @param output Ошибка запроса.
 */
export const getErrorMessage = <T>(output: AxiosError<T>) => {
  const error: OpenAPI.HTTPValidationError | undefined =
    output.response && output.response.data;

  if (typeof error === 'object') {
    if (typeof error.detail === 'string') return error.detail;
    if (Array.isArray(error.detail)) return error.detail[0].msg;
  }

  return 'Unknown error';
};

/**
 * Возвращает заголовки, необходимые для авторизации.
 * @param token Токен приложения или персоны.
 */
export const getAuthHeaders = (token: string | undefined): HeadersInit => ({
  Authorization: `Bearer ${token}`
});

/**
 * Приводит ответ к CountableRecords.
 * @param response Axios response.
 */
export const toCountableRecords = <T>(
  response: AxiosResponse<T[]>
): CountableRecords<T> => ({
  records: response.data,
  count:
    response.headers['x-total-records'] &&
    Number(response.headers['x-total-records'])
});

/**
 * Добавляет обработчик на 401 статус (Unauthorized) ответа.
 * @param handler Обработчик.
 */
export const onUnauthorized = (handler: (error: AxiosError) => any) =>
  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response?.status === HttpStatus.UNAUTHORIZED) {
      handler(error);
    }

    return Promise.reject(error);
  });
