import { createReducer, ActionType } from 'typesafe-actions';
import {
  fetchDictionaryAsync,
  editDictionaryAsync
} from 'store/developer/dictionary/actions';
import { fetchApplicationDictionariesAsync } from 'store/developer/application/actions';

import { DictionariesState, DictionariesAction } from './types';
import { fetchDictionariesAsync, createDictionaryAsync } from './actions';

export const initialState: DictionariesState = {
  isPending: false,
  error: null,
  count: 0,
  byId: {},
  ids: []
};

export default createReducer<
  DictionariesState,
  | DictionariesAction
  | ActionType<
      | typeof fetchDictionaryAsync['success']
      | typeof editDictionaryAsync['success']
      | typeof fetchApplicationDictionariesAsync['success']
    >
>(initialState)
  .handleAction(createDictionaryAsync.request, state => ({
    ...state,
    isPending: true,
    error: initialState.error
  }))
  .handleAction(fetchDictionariesAsync.request, state => ({
    ...state,
    isPending: true,
    error: initialState.error
  }))
  .handleAction(fetchDictionariesAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    count: payload.count ?? state.count,
    byId: payload.records.reduce(
      (acc, dictionary) => ({ ...acc, [dictionary.id]: dictionary }),
      state.byId
    ),
    ids: payload.records.map(dictionary => dictionary.id)
  }))
  .handleAction(createDictionaryAsync.success, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(
    [fetchDictionaryAsync.success, editDictionaryAsync.success],
    (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.id]: payload
      }
    })
  )
  .handleAction(
    fetchApplicationDictionariesAsync.success,
    (state, { payload }) => ({
      ...state,
      byId: payload.records.reduce(
        (acc, dictionary) => ({
          ...acc,
          [dictionary.id]: { ...acc[dictionary.id], ...dictionary }
        }),
        state.byId
      )
    })
  );
