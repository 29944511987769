import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import ApplicationDTO from 'types/ApplicationDTO';
import { getErrorMessage } from 'services/network';
import * as api from 'services/api';
import StatisticsParams from 'types/StatisticsParams';
import StatisticsPeriod from 'types/StatisticsPeriod';
import { AppThunk } from 'store';

export const fetchStatisticsAsync = createAsyncAction(
  'developer/FETCH_STATISTICS_REQUEST',
  'developer/FETCH_STATISTICS_SUCCESS',
  'developer/FETCH_STATISTICS_FAILURE'
)<void, OpenAPI.ResponseMetric[], any>();

/**
 * Загружает статистику.
 * @param params Параметры статистики.
 * @param access_token Токен приложения.
 */
export const fetchStatistics = (
  params: StatisticsParams,
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<OpenAPI.ResponseMetric[]>> => async dispatch => {
  try {
    dispatch(fetchStatisticsAsync.request());
    const response = await api.getStatistics(params, access_token);
    dispatch(fetchStatisticsAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchStatisticsAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

/**
 * Устанавливает тип периода для статистики.
 * @param payload Тип периода.
 */
export const setStatisticsPeriod = createStandardAction(
  'developer/SET_STATISTICS_PERIOD'
)<StatisticsPeriod>();

/**
 * Устанавливает тип метрики для статистики.
 * @param payload Тип метрики.
 */
export const setStatisticsMetric = createStandardAction(
  'developer/SET_STATISTICS_METRIC'
)<StatisticsParams['metric']>();
