import { createReducer } from 'typesafe-actions';
import { ApplicationsState, ApplicationsAction } from './types';
import { fetchApplicationsAsync, setApplicationFilter } from './actions';

export const initialState: ApplicationsState = {
  byId: {},
  ids: []
};

export default createReducer<ApplicationsState, ApplicationsAction>(
  initialState
)
  .handleAction(fetchApplicationsAsync.success, (state, { payload }) => ({
    ...state,
    ids: payload.map(application => application.id),
    byId: payload.reduce(
      (acc, application) => ({ ...acc, [application.id]: application }),
      {}
    )
  }))
  .handleAction(setApplicationFilter, (state, { payload }) => ({
    ...state,
    filter: payload
  }));
