import React from 'react';
import clsx from 'clsx';

import './request-counter.scss';

type Props = {
  title: React.ReactNode;
  value: React.ReactNode;
} & React.ComponentProps<'div'>;

const RequestCounter: React.FC<Props> = ({
  title,
  value,
  className,
  ...restProps
}) => (
  <div className={clsx('request-counter', className)} {...restProps}>
    <div className="request-counter__title">{title}</div>
    <div className="request-counter__value">{value}</div>
  </div>
);

export default RequestCounter;
