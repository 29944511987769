import { createAsyncAction } from 'typesafe-actions';
import AdminCollectionDTO from 'types/AdminCollectionDTO';
import { AppThunk } from 'store';
import * as api from 'services/api';

export const fetchCollectionAsync = createAsyncAction(
  'admin/FETCH_COLLECTION_REQUEST',
  'admin/FETCH_COLLECTION_SUCCESS',
  'admin/FETCH_COLLECTION_FAILURE'
)<void, OpenAPI.Collection, any>();

/**
 * Загружает коллекцию с сообщениями.
 * @param id ID коллекции
 * @param token Токен пользователя.
 */
export const fetchCollection = (
  id: AdminCollectionDTO['id'],
  token: string
): AppThunk<Promise<OpenAPI.Collection>> => async dispatch => {
  try {
    dispatch(fetchCollectionAsync.request());
    const response = await api.getAdminCollection(id, token);
    dispatch(fetchCollectionAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionAsync.failure(error));
    throw error;
  }
};
