import { createReducer } from 'typesafe-actions';
import { PersonMatchingState, PersonMatchingAction } from './types';
import {
  fetchPersonMatchingAsync,
  acceptPersonMatchingAsync,
  declinePersonMatchingAsync
} from './actions';

export const initialState: PersonMatchingState = {
  isPending: false,
  error: null,
  ids: [],
  byId: {}
};

export default createReducer<PersonMatchingState, PersonMatchingAction>(
  initialState
)
  .handleAction(fetchPersonMatchingAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(fetchPersonMatchingAsync.success, (state, { payload }) => ({
    ...state,
    error: initialState.error,
    isPending: false,
    ids: payload.map(matching => matching.id),
    byId: payload.reduce(
      (acc, matching) => ({
        ...acc,
        [matching.id]: { ...acc[matching.id], ...matching }
      }),
      state.byId
    )
  }))
  .handleAction(fetchPersonMatchingAsync.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    isPending: false
  }))
  .handleAction(acceptPersonMatchingAsync.success, (state, { payload }) => ({
    ...state,
    byId: {
      ...state.byId,
      ...(state.byId[payload] && {
        [payload]: {
          ...state.byId[payload],
          person: true
        }
      })
    }
  }))
  .handleAction(declinePersonMatchingAsync.success, (state, { payload }) => ({
    ...state,
    byId: {
      ...state.byId,
      ...(state.byId[payload] && {
        [payload]: {
          ...state.byId[payload],
          person: false
        }
      })
    }
  }));
