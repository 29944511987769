import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Routes from 'types/Routes';

import Button from 'components/Button';
import Header from 'components/Header';

import './not-found-page.scss';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found-page">
      <Header />
      <div className="not-found-page__content">
        {t('Страница не найдена')}
        <Link className="not-found-page__link" to={Routes.INDEX}>
          <Button variant="outlined" theme="primary">
            {t('Вернуться на главную')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
