import React, { useRef, useEffect } from 'react';
import { usePrevious } from 'react-use';
import ReactLoadingBar from 'react-top-loading-bar';
import vars from 'static/styles/vars.scss';
import './loading-bar.scss';

type Props = {
  isPending?: boolean;
} & React.ComponentProps<typeof ReactLoadingBar>;

const LoadingBar: React.FC<Props> = ({ isPending, ...restProps }) => {
  const ref = useRef<ReactLoadingBar>(null);
  const prevPending = usePrevious(isPending);

  useEffect(() => {
    if (prevPending === isPending || !ref.current) return;
    isPending ? ref.current.continuousStart() : ref.current.complete();
  }, [isPending, prevPending]);

  return (
    <div className="loading-bar">
      <ReactLoadingBar color={vars['color-primary']} ref={ref} {...restProps} />
    </div>
  );
};

export default LoadingBar;
