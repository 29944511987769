import { createReducer, ActionType } from 'typesafe-actions';
import { CountersState, CountersAction } from './types';
import { fetchCountersAsync } from './actions';
import { readReportAsync } from '../reports/actions';

export const initialState: CountersState = {};

export default createReducer<
  CountersState,
  CountersAction | ActionType<typeof readReportAsync['success']>
>(initialState)
  .handleAction(fetchCountersAsync.success, (state, { payload }) => ({
    ...state,
    reports: payload.unviewed_messages,
    merges: payload.undecided_requests
  }))
  .handleAction(readReportAsync.success, state => ({
    ...state,
    reports: state.reports && state.reports - 1
  }));
