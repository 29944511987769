import { createAsyncAction } from 'typesafe-actions';

import ApplicationDTO from 'types/ApplicationDTO';
import CollectionDTO from 'types/CollectionDTO';
import DictionaryDTO from 'types/DictionaryDTO';

import * as api from 'services/api';
import CountableRecords from 'types/CountableRecords';
import CollectionsSearchParams from 'types/CollectionsSearchParams';
import DictionariesSearchParams from 'types/DictionariesSearchParams';
import { toCountableRecords } from 'services/network';
import { AppThunk } from 'store';

export const fetchApplicationAsync = createAsyncAction(
  'developer/FETCH_APPLICATION_REQUEST',
  'developer/FETCH_APPLICATION_SUCCESS',
  'developer/FETCH_APPLICATION_FAILURE'
)<ApplicationDTO['id'], ApplicationDTO, any>();

export const editApplicationAsync = createAsyncAction(
  'developer/EDIT_APPLICATION_REQUEST',
  'developer/EDIT_APPLICATION_SUCCESS',
  'developer/EDIT_APPLICATION_FAILURE'
)<void, ApplicationDTO, any>();

/**
 * Загружает информацию о приложении.
 * @param id ID приложения.
 * @param token Токен персоны.
 */
export const fetchApplication = (
  id: ApplicationDTO['id'],
  token: string
): AppThunk<Promise<ApplicationDTO>> => async dispatch => {
  try {
    dispatch(fetchApplicationAsync.request(id));
    const response = await api.getApplication(id, token);
    dispatch(fetchApplicationAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationAsync.failure(error));
    throw error;
  }
};

/**
 * Редактирует информацию о приложении.
 * @param id ID приложения.
 * @param data Информация приложения.
 * @param token Токен персоны.
 */
export const editApplication = (
  id: ApplicationDTO['id'],
  data: OpenAPI.RequestUpdateApplication,
  token: string
): AppThunk<Promise<ApplicationDTO>> => async dispatch => {
  try {
    dispatch(editApplicationAsync.request());
    const response = await api.updateApplication(id, data, token);
    dispatch(editApplicationAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(editApplicationAsync.failure(error));
    throw error;
  }
};

export const fetchApplicationCollectionsAsync = createAsyncAction(
  'developer/FETCH_APPLICATION_COLLECTIONS_REQUEST',
  'developer/FETCH_APPLICATION_COLLECTIONS_SUCCESS',
  'developer/FETCH_APPLICATION_COLLECTIONS_FAILURE'
)<void, CountableRecords<CollectionDTO>, any>();

export const fetchApplicationCollections = (
  access_token: ApplicationDTO['access_token'],
  params: CollectionsSearchParams = {}
): AppThunk<Promise<CountableRecords<CollectionDTO>>> => async dispatch => {
  try {
    dispatch(fetchApplicationCollectionsAsync.request());
    const response = await api.getApplicationCollections(access_token, {
      is_own: true,
      ...params
    });
    const data = toCountableRecords(response);
    dispatch(fetchApplicationCollectionsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationCollectionsAsync.failure(error));
    throw error;
  }
};

export const fetchApplicationDictionariesAsync = createAsyncAction(
  'developer/FETCH_APPLICATION_DICTIONARIES_REQUEST',
  'developer/FETCH_APPLICATION_DICTIONARIES_SUCCESS',
  'developer/FETCH_APPLICATION_DICTIONARIES_FAILURE'
)<void, CountableRecords<DictionaryDTO>, any>();

export const fetchApplicationDictionaries = (
  access_token: ApplicationDTO['access_token'],
  params: DictionariesSearchParams = {}
): AppThunk<Promise<CountableRecords<DictionaryDTO>>> => async dispatch => {
  try {
    dispatch(fetchApplicationDictionariesAsync.request());
    const response = await api.getApplicationDictionaries(access_token, {
      is_own: true,
      ...params
    });
    const data = toCountableRecords(response);
    dispatch(fetchApplicationDictionariesAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchApplicationDictionariesAsync.failure(error));
    throw error;
  }
};
