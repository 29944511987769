import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import { toCountableRecords } from 'services/network';
import * as api from 'services/api';
import { AppThunk } from 'store';
import AdminPersonsParams from 'types/AdminPersonsParams';
import AdminPersonDTO from 'types/AdminPersonDTO';

import { PersonsType, PersonsState } from './types';

export const fetchPersonsAsync = createAsyncAction(
  'admin/FETCH_PERSONS_REQUEST',
  'admin/FETCH_PERSONS_SUCCESS',
  'admin/FETCH_PERSONS_FAILURE'
)<void, NonNullable<PersonsState['results']>, any>();

export const fetchPersonAsync = createAsyncAction(
  'admin/FETCH_PERSON_REQUEST',
  'admin/FETCH_PERSON_SUCCESS',
  'admin/FETCH_PERSON_FAILURE'
)<void, AdminPersonDTO, any>();

export const fetchAppPersonAsync = createAsyncAction(
  'admin/FETCH_APP_PERSON_REQUEST',
  'admin/FETCH_APP_PERSON_SUCCESS',
  'admin/FETCH_APP_PERSON_FAILURE'
)<void, AdminPersonDTO, any>();

/**
 * Устанавливает тип персон.
 */
export const setPersonsType = createStandardAction('admin/SET_PERSONS_TYPE')<
  PersonsType
>();

/**
 * Загружает персоны.
 * @param type Тип персон.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const fetchPersons = (
  type: PersonsState['type'],
  token: string,
  params?: AdminPersonsParams
): AppThunk<Promise<
  ReturnType<typeof fetchPersonsAsync['success']>['payload']
>> => async dispatch => {
  try {
    dispatch(fetchPersonsAsync.request());
    const response = await api[
      type === PersonsType.PERSONS ? 'getAdminPersons' : 'getAdminAppPersons'
    ](token, params);
    const results: PersonsState['results'] = {
      type,
      data: toCountableRecords(response as AxiosResponse<AdminPersonDTO[]>)
    };
    dispatch(fetchPersonsAsync.success(results));
    return results;
  } catch (error) {
    console.error(error);
    dispatch(fetchPersonsAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает персону.
 * @param id ID персоны.
 * @param token Токен пользователя.
 */
export const fetchPerson = (
  id: AdminPersonDTO['id'],
  token: string
): AppThunk<Promise<AdminPersonDTO>> => async dispatch => {
  try {
    dispatch(fetchPersonAsync.request());
    const response = await api.getAdminPerson(id, token);
    dispatch(fetchPersonAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchPersonAsync.failure(error));
    throw error;
  }
};

/**
 * Загружает AppPerson.
 * @param id ID AppPerson.
 * @param token Токен пользователя.
 */
export const fetchAppPerson = (
  id: AdminPersonDTO['id'],
  token: string
): AppThunk<Promise<AdminPersonDTO>> => async dispatch => {
  try {
    dispatch(fetchAppPersonAsync.request());
    const response = await api.getAdminAppPerson(id, token);
    dispatch(fetchAppPersonAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchAppPersonAsync.failure(error));
    throw error;
  }
};
