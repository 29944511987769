import { ActionType } from 'typesafe-actions';
import CountableRecords from 'types/CountableRecords';
import AdminPersonDTO from 'types/AdminPersonDTO';

export enum PersonsType {
  PERSONS,
  APP_PERSONS
}

export type PersonsState = {
  isPending: boolean;
  type: PersonsType;
  results?: {
    type: PersonsState['type'];
    data: CountableRecords<AdminPersonDTO>;
  };
  personsById: Partial<Record<string, AdminPersonDTO>>;
  appPersonsById: Partial<Record<string, AdminPersonDTO>>;
};

export type PersonsAction = ActionType<typeof import('./actions')>;
