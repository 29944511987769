import { createReducer, ActionType } from 'typesafe-actions';
import uniqBy from 'lodash/uniqBy';

import { fetchFileValueSuccess } from 'store/user/values/actions';

import { CollectionState, CollectionAction } from './types';
import {
  fetchCompareFunctionsAsync,
  fetchCollectionValuesAsync,
  resetCollectionState,
  fetchCollectionValuesMoreAsync
} from './actions';

export const initialState: CollectionState = {
  compareFunctions: {},
  compareFunctionsIds: [],
  isPending: false
};

export default createReducer<
  CollectionState,
  CollectionAction | ActionType<typeof fetchFileValueSuccess>
>(initialState)
  .handleAction(fetchCompareFunctionsAsync.success, (state, { payload }) => ({
    ...state,
    compareFunctions: payload.reduce(
      (acc, compare) => ({ ...acc, [compare.key]: compare }),
      state.compareFunctions
    ),
    compareFunctionsIds: payload.map(compare => compare.key)
  }))
  .handleAction(
    [
      fetchCollectionValuesAsync.request,
      fetchCollectionValuesMoreAsync.request
    ],
    state => ({
      ...state,
      isPending: true,
      error: initialState.error
    })
  )
  .handleAction(
    [
      fetchCollectionValuesAsync.success,
      fetchCollectionValuesMoreAsync.success
    ],
    (state, { payload, type }) => {
      const newState = {
        ...state,
        isPending: false,
        count: payload.count
      };

      if (type === String(fetchCollectionValuesAsync.success)) {
        return {
          ...newState,
          values: payload.records.map(({ field, values }) => [field, values])
        };
      } else {
        const valuesMap = newState.values
          ? Object.fromEntries(newState.values)
          : {};

        payload.records.forEach(({ field, values }) => {
          valuesMap[field] = uniqBy(
            [...(valuesMap[field] || []), ...values],
            'id'
          );
        });

        return {
          ...newState,
          values: Object.entries(valuesMap)
        };
      }
    }
  )
  .handleAction(fetchCollectionValuesAsync.failure, (state, { payload }) => ({
    ...state,
    isPending: false,
    error: payload
  }))
  .handleAction(fetchFileValueSuccess, (state, { payload, meta }) => ({
    ...state,
    values: state.values?.map(([field, values]) => [
      field,
      values.map(value =>
        value.id === meta ? { ...value, file: payload } : value
      )
    ])
  }))
  .handleAction(resetCollectionState, () => initialState);
