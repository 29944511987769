import React from 'react';
import clsx from 'clsx';

import './card.scss';

type Props = React.ComponentProps<'div'>;

const Card: React.FC<Props> = ({ className, ...restProps }) => (
  <div className={clsx('card', className)} {...restProps} />
);

export default Card;
