import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import CollectionDTO from 'types/CollectionDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import Card from 'components/Card';
import DataRow from 'components/DataRow';
import { DataItem } from 'components/DataRow/DataRow';

import {
  getAccessTypeTitle,
  getAccessTypeColor,
  getFieldsCount
} from 'services/collections';
import { getCategoryName } from 'services/categories';

import './collection-list-item.scss';

type Props = {
  collection: CollectionDTO;
  application: ApplicationDTO;
  showAccess?: boolean;
} & React.ComponentProps<typeof Card>;

const CollectionListItem: React.FC<Props> = ({
  collection,
  application,
  className,
  showAccess = true,
  ...restProps
}) => {
  const color = getAccessTypeColor(collection.access_type);
  const { t } = useTranslation();

  const dataItems = useMemo<DataItem[]>(
    () => [
      {
        header: t('Категория'),
        body: getCategoryName(collection.category_object),
        style: { width: '50%' }
      },
      ...(showAccess
        ? [
            {
              header: t('Доступ'),
              body: getAccessTypeTitle(collection.access_type),
              style: { width: '23%' },
              bodyProps: {
                style: { color }
              }
            }
          ]
        : []),
      {
        header: t('Полей'),
        body: getFieldsCount(collection),
        style: { width: '12%' }
      },
      {
        header: t('Записей'),
        body: collection.values_count,
        style: { width: '15%', textAlign: 'right' }
      }
    ],
    [collection, color, showAccess, t]
  );

  return (
    <Card className={clsx('collection-list-item', className)} {...restProps}>
      <div
        style={{ backgroundColor: color }}
        className="collection-list-item__indicator"
      />
      <div className="collection-list-item__info">
        <div className="collection-list-item__name">
          {collection.name}
          {collection.object_oriented && (
            <span className="collection-list-item__object">Object</span>
          )}
        </div>
        <div className="collection-list-item__application">
          {application.name}
        </div>
      </div>
      <DataRow className="collection-list-item__data" items={dataItems} />
    </Card>
  );
};

export default CollectionListItem;
