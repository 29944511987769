import { createReducer } from 'typesafe-actions';
import { FieldsState, FieldsAction } from './types';
import { fetchFieldsAsync } from './actions';

export const initialState: FieldsState = {
  byId: {}
};

export default createReducer<FieldsState, FieldsAction>(
  initialState
).handleAction(
  fetchFieldsAsync.success,
  (state, { payload: [id, fields] }) => ({
    ...state,
    byId: {
      ...state.byId,
      [id]: fields
    }
  })
);
