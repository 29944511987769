import { createReducer } from 'typesafe-actions';
import groupBy from 'lodash/groupBy';
import { CollectionsState, CollectionsAction } from './types';
import { fetchCollectionsAsync, fetchCollectionAsync } from './actions';

export const initialState: CollectionsState = {
  byApplication: {}
};

export default createReducer<CollectionsState, CollectionsAction>(initialState)
  .handleAction(fetchCollectionsAsync.success, (state, { payload }) => ({
    ...state,
    byApplication: {
      ...state.byApplication,
      ...groupBy(payload, 'provider.id')
    }
  }))
  .handleAction(fetchCollectionAsync.success, (state, { payload }) => {
    const prevCollections = state.byApplication[payload.provider.id] || [];
    const contains = !!prevCollections.find(
      collection => collection.id === payload.id
    );

    return {
      ...state,
      byApplication: {
        ...state.byApplication,
        [payload.provider.id]: contains
          ? prevCollections.map(collection =>
              collection.id === payload.id
                ? { ...collection, ...payload }
                : collection
            )
          : [...prevCollections, payload]
      }
    };
  });
