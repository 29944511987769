import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { ValueType } from 'react-select/src/types';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import { compile } from 'path-to-regexp';

import { getApplications } from 'store/developer/applications/selectors';
import { getCurrentApplication } from 'store/developer/currentApplication/selectors';
import { setCurrentApplication } from 'store/developer/currentApplication/actions';
import { AppState } from 'store/rootReducer';
import { getPersonRole, getAvailableRoles } from 'store/person/selectors';
import { setPersonRole } from 'store/person/actions';

import ApplicationDTO from 'types/ApplicationDTO';
import PersonRole from 'types/PersonRole';
import Routes from 'types/Routes';

import { RU, EN } from 'i18n/languages';

import Header from './Header';

type Props = Omit<React.ComponentProps<typeof Header>, 'person'>;

const ConnectedHeader: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const person = useSelector((state: AppState) => state.person.data)!;
  const applications = useSelector(getApplications);
  const currentApplication = useSelector(getCurrentApplication);
  const role = useSelector(getPersonRole);
  const availableRoles = useSelector(getAvailableRoles);
  const handleChangeApplication = useCallback(
    (application: ValueType<ApplicationDTO>) => {
      const { id } = application as ApplicationDTO;

      if (currentApplication!.id !== id) {
        dispatch(setCurrentApplication(id));
        dispatch(replace(compile(Routes.DEVELOPER_APPLICATION)({ id })));
      }
    },
    [currentApplication, dispatch]
  );

  const { i18n } = useTranslation();
  const toggleLanguage = useCallback(
    () => i18n.changeLanguage(i18n.language === RU ? EN : RU),
    [i18n]
  );

  const handleChangeRole = useCallback(
    (role: PersonRole) => {
      dispatch(setPersonRole(role));
      dispatch(replace(Routes.INDEX));
    },
    [dispatch]
  );

  return (
    <Header
      applications={applications}
      currentApplication={currentApplication}
      person={person}
      onChangeApplication={handleChangeApplication}
      onLanguage={toggleLanguage}
      language={capitalize(i18n.language)}
      role={role}
      onChangeRole={handleChangeRole}
      availableRoles={availableRoles}
      {...props}
    />
  );
};

export default ConnectedHeader;
