import { combineReducers } from 'redux';

import { ReportsState } from './reports/types';
import { CountersState } from './counters/types';
import { MergesState } from './merges/types';
import { ApplicationFilterState } from './applicationFilter/types';
import { ApplicationState } from './application/types';
import { ApplicationsState } from './applications/types';
import { DictionaryState } from './dictionary/types';
import { DictionariesState } from './dictionaries/types';
import { CollectionState } from './collection/types';
import { CollectionsState } from './collections/types';
import { PersonsState } from './persons/types';
import { PersonApplicationsState } from './personApplications/types';
import { AppPersonCollectionsState } from './appPersonCollections/types';
import { AppPersonValuesState } from './appPersonValues/types';
import { FieldsState } from './fields/types';

import reports from './reports/reducer';
import counters from './counters/reducer';
import merges from './merges/reducer';
import applicationFilter from './applicationFilter/reducer';
import application from './application/reducer';
import applications from './applications/reducer';
import dictionary from './dictionary/reducer';
import dictionaries from './dictionaries/reducer';
import collection from './collection/reducer';
import collections from './collections/reducer';
import persons from './persons/reducer';
import personApplications from './personApplications/reducer';
import appPersonCollections from './appPersonCollections/reducer';
import appPersonValues from './appPersonValues/reducer';
import fields from './fields/reducer';
import applicationRequests from './applicationRequests/reducer';
import { ApplicationRequestsState } from './applicationRequests/types';

export type AdminState = {
  reports: ReportsState;
  counters: CountersState;
  merges: MergesState;
  applicationFilter: ApplicationFilterState;
  application: ApplicationState;
  applications: ApplicationsState;
  dictionary: DictionaryState;
  dictionaries: DictionariesState;
  collection: CollectionState;
  collections: CollectionsState;
  persons: PersonsState;
  personApplications: PersonApplicationsState;
  appPersonCollections: AppPersonCollectionsState;
  appPersonValues: AppPersonValuesState;
  fields: FieldsState;
  applicationRequests: ApplicationRequestsState;
};

export default combineReducers<AdminState>({
  reports,
  counters,
  merges,
  applicationFilter,
  application,
  applications,
  dictionary,
  dictionaries,
  collection,
  collections,
  persons,
  personApplications,
  appPersonCollections,
  appPersonValues,
  fields,
  applicationRequests
});
