import React from 'react';
import clsx from 'clsx';

import './avatar.scss';

type Props = {
  src: string;
} & React.ComponentProps<'div'>;

const Avatar: React.FC<Props> = ({ src, className, ...restProps }) => (
  <div
    className={clsx('avatar', className)}
    style={{ backgroundImage: src ? `url(${src})` : undefined }}
    {...restProps}
  />
);

export default Avatar;
