import { useState, useCallback, useMemo } from 'react';

/**
 * Хук для взаимодействия с локальным попапом.
 * @param initialValue Значение по умолчанию.
 */
export default function usePopup(initialValue: boolean = false) {
  const [isOpen, setOpen] = useState(initialValue);
  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);
  const toggle = useCallback(() => setOpen(prevOpen => !prevOpen), []);
  return useMemo(
    () => ({
      isOpen,
      open,
      toggle,
      close
    }),
    [close, isOpen, open, toggle]
  );
}
