import { createReducer } from 'typesafe-actions';
import { CollectionAccessState, CollectionAccessAction } from './types';
import {
  fetchCollectionAccessAsync,
  openCollectionAccess,
  closeCollectionAccess,
  editCollectionAccessAsync
} from './actions';

export const initialState: CollectionAccessState = {
  isPending: false
};

export default createReducer<CollectionAccessState, CollectionAccessAction>(
  initialState
)
  .handleAction(openCollectionAccess, (state, { payload }) => ({
    ...state,
    id: payload
  }))
  .handleAction(
    [fetchCollectionAccessAsync.request, editCollectionAccessAsync.request],
    state => ({
      ...state,
      error: initialState.error,
      isPending: true
    })
  )
  .handleAction(fetchCollectionAccessAsync.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    isPending: false
  }))
  .handleAction(
    [fetchCollectionAccessAsync.success, editCollectionAccessAsync.success],
    state => ({
      ...state,
      isPending: false
    })
  )
  .handleAction(closeCollectionAccess, () => initialState);
