import { createAsyncAction } from 'typesafe-actions';
import AdminReportDTO from 'types/AdminReportDTO';
import * as api from 'services/api';
import SupportMessagesParams from 'types/SupportMessagesParams';
import CountableRecords from 'types/CountableRecords';
import { toCountableRecords } from 'services/network';
import { AppThunk } from 'store';

export const fetchReportsAsync = createAsyncAction(
  'admin/FETCH_REPORTS_REQUEST',
  'admin/FETCH_REPORTS_SUCCESS',
  'admin/FETCH_REPORTS_FAILURE'
)<void, CountableRecords<AdminReportDTO>, any>();

export const readReportAsync = createAsyncAction(
  'admin/READ_REPORT_REQUEST',
  'admin/READ_REPORT_SUCCESS',
  'admin/READ_REPORT_FAILURE'
)<void, AdminReportDTO['id'], any>();

/**
 * Загружает жалобы.
 * @param token Токен пользователя.
 * @param params Параметры поиска.
 */
export const fetchReports = (
  token: string,
  params: SupportMessagesParams = {}
): AppThunk<Promise<CountableRecords<AdminReportDTO>>> => async dispatch => {
  try {
    dispatch(fetchReportsAsync.request());
    const response = await api.getAdminSupportMessages(token, params);
    const data = toCountableRecords(response);
    dispatch(fetchReportsAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchReportsAsync.failure(error));
    throw error;
  }
};

/**
 * Читает жалобу.
 * @param token Токен пользователя.
 * @param id ID жалобы.
 */
export const readReport = (
  token: string,
  id: AdminReportDTO['id']
): AppThunk<Promise<void>> => async dispatch => {
  try {
    dispatch(readReportAsync.request());
    await api.readAdminSupportMessage(token, id);
    dispatch(readReportAsync.success(id));
  } catch (error) {
    console.error(error);
    dispatch(readReportAsync.failure(error));
    throw error;
  }
};
