import { createReducer } from 'typesafe-actions';
import { AppPersonCollectionsState, AppPersonCollectionsAction } from './types';
import { fetchAppPersonCollectionsAsync } from './actions';

export const initialState: AppPersonCollectionsState = { byId: {} };

export default createReducer<
  AppPersonCollectionsState,
  AppPersonCollectionsAction
>(initialState).handleAction(
  fetchAppPersonCollectionsAsync.success,
  (state, { payload: [id, collections] }) => ({
    ...state,
    byId: {
      ...state.byId,
      [id]: collections
    }
  })
);
