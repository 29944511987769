import { createReducer, ActionType } from 'typesafe-actions';
import storage from 'redux-persist/lib/storage';
import { logout } from 'store/auth/actions';
import { PersonState, PersonAction } from './types';
import { fetchPersonAsync, setPersonRole } from './actions';

export const initialState: PersonState = {
  isPending: false,
  role: 'user'
};

export const personPersistConfig = {
  key: 'person',
  whitelist: ['role'],
  storage
};

export default createReducer<
  PersonState,
  PersonAction | ActionType<typeof logout>
>(initialState)
  .handleAction(fetchPersonAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(fetchPersonAsync.success, (state, { payload }) => ({
    ...state,
    data: payload,
    isPending: false
  }))
  .handleAction(fetchPersonAsync.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    isPending: false
  }))
  .handleAction(logout, () => initialState)
  .handleAction(setPersonRole, (state, { payload }) => ({
    ...state,
    role: payload
  }));
