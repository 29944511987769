import { createReducer } from 'typesafe-actions';
import { MergesState, MergesAction } from './types';
import {
  fetchMergesAsync,
  fetchMergeAsync,
  declineMergeAsync,
  acceptMergeAsync
} from './actions';

export const initialState: MergesState = {
  isPending: false,
  count: 0,
  byId: {},
  ids: []
};

export default createReducer<MergesState, MergesAction>(initialState)
  .handleAction(fetchMergesAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(fetchMergesAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    count: payload.count ?? state.count,
    ids: payload.records.map(merge => merge.id),
    byId: payload.records.reduce(
      (acc, merge) => ({ ...acc, [merge.id]: merge }),
      state.byId
    )
  }))
  .handleAction(fetchMergeAsync.success, (state, { payload }) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: payload
    }
  }))
  .handleAction(fetchMergesAsync.failure, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(
    [acceptMergeAsync.success, declineMergeAsync.success],
    (state, { payload, type }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload]: {
          ...state.byId[payload],
          decision:
            type === acceptMergeAsync.success.toString() ? 'accept' : 'decline',
          decided_at: new Date().toISOString()
        }
      }
    })
  );
