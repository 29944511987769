import { createReducer } from 'typesafe-actions';
import { CollectionState, CollectionAction } from './types';
import { editCollectionAsync, fetchCollectionAsync } from './actions';

export const initialState: CollectionState = {
  isPending: false
};

export default createReducer<CollectionState, CollectionAction>(initialState)
  .handleAction(
    [editCollectionAsync.request, fetchCollectionAsync.request],
    state => ({
      ...state,
      error: initialState.error,
      isPending: true
    })
  )
  .handleAction(
    [editCollectionAsync.failure, fetchCollectionAsync.failure],
    (state, { payload }) => ({
      ...state,
      error: payload,
      isPending: false
    })
  )
  .handleAction(
    [editCollectionAsync.success, fetchCollectionAsync.success],
    state => ({
      ...state,
      isPending: false
    })
  );
