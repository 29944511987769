import React from 'react';
import clsx from 'clsx';

import './label.scss';

type Props = {
  /**
   * Отключает встроенный margin.
   * По умолчанию: `false`.
   */
  disableMargin?: boolean;
} & React.ComponentProps<'label'>;

const Label: React.FC<Props> = ({
  className,
  disableMargin = false,
  ...restProps
}) => (
  <label
    className={clsx(
      'label',
      disableMargin && 'label--disable-margin',
      className
    )}
    {...restProps}
  />
);

export default Label;
