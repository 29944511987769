import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import PersonDTO from 'types/PersonDTO';
import * as api from 'services/api';
import PersonRole from 'types/PersonRole';
import { AppThunk } from 'store';

export const fetchPersonAsync = createAsyncAction(
  'FETCH_PERSON_REQUEST',
  'FETCH_PERSON_SUCCESS',
  'FETCH_PERSON_FAILURE'
)<void, PersonDTO, any>();

/**
 * Устанавливает роль пользователя.
 * @param payload Роль.
 */
export const setPersonRole = createStandardAction('SET_PERSON_ROLE')<
  PersonRole
>();

/**
 * Загружает персону.
 * @param access_token Токен персоны.
 */
export const fetchPerson = (
  access_token: string
): AppThunk<Promise<PersonDTO>> => async dispatch => {
  try {
    dispatch(fetchPersonAsync.request());
    const response = await api.getPerson(access_token);
    const person: PersonDTO = {
      id: response.data.Id,
      first_name: response.data.FirstName,
      last_name: response.data.LastName,
      middle_name: response.data.FatherName,
      avatar: response.data.Photo && response.data.Photo.Original
    };
    dispatch(fetchPersonAsync.success(person));
    return person;
  } catch (error) {
    console.error(error);
    dispatch(fetchPersonAsync.failure(error));
    throw error;
  }
};
