import React from 'react';
import clsx from 'clsx';

import PersonDTO from 'types/PersonDTO';
import Avatar from 'components/Avatar';

import './person-avatar.scss';

type Props = {
  person: PersonDTO;
} & Omit<React.ComponentProps<typeof Avatar>, 'src'>;

const PersonAvatar: React.FC<Props> = ({ person, className, ...restProps }) => (
  <Avatar
    className={clsx('person-avatar', className)}
    src={person.avatar!}
    {...restProps}
  >
    {!person.avatar &&
      person.last_name?.charAt(0)! + person.first_name?.charAt(0)!}
  </Avatar>
);

export default PersonAvatar;
