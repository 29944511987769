import PersonRole from 'types/PersonRole';
import i18n from 'i18n';

/**
 * Возвращает название роли.
 * @param role Роль.
 */
export const getRoleTitle = (role: PersonRole) =>
  ({
    user: i18n.t('Пользователь'),
    developer: i18n.t('Разработчик'),
    moderator: i18n.t('Модератор'),
    administrator: i18n.t('Администратор')
  }[role]);

/**
 * Возвращает роли.
 */
export const getRoles = (): PersonRole[] => ['user', 'developer'];
