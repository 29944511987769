import { createReducer, ActionType } from 'typesafe-actions';
import { fetchFileValueSuccess } from 'store/user/values/actions';
import uniqBy from 'lodash/uniqBy';

import { AppPersonValuesState, AppPersonValuesAction } from './types';
import {
  fetchAppPersonValuesMoreAsync,
  resetAppPersonValuesState,
  fetchAppPersonValuesAsync
} from './actions';

export const initialState: AppPersonValuesState = {
  isPending: false
};

export default createReducer<
  AppPersonValuesState,
  AppPersonValuesAction | ActionType<typeof fetchFileValueSuccess>
>(initialState)
  .handleAction(
    [fetchAppPersonValuesMoreAsync.request, fetchAppPersonValuesAsync.request],
    state => ({
      ...state,
      isPending: true
    })
  )
  .handleAction(fetchAppPersonValuesAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    count: payload.count,
    values: payload.records.map(({ field, values }) => [field, values])
  }))
  .handleAction(fetchAppPersonValuesMoreAsync.success, (state, { payload }) => {
    const valuesMap = state.values ? Object.fromEntries(state.values) : {};

    payload.records.forEach(({ field, values }) => {
      valuesMap[field] = uniqBy([...(valuesMap[field] || []), ...values], 'id');
    });

    return {
      ...state,
      isPending: false,
      count: payload.count,
      values: Object.entries(valuesMap)
    };
  })
  .handleAction(resetAppPersonValuesState, () => initialState)
  .handleAction(fetchFileValueSuccess, (state, { payload, meta }) => ({
    ...state,
    values: state.values?.map(([field, fieldValues]) => [
      field,
      fieldValues.map(value =>
        value.id === meta ? { ...value, file: payload } : value
      )
    ])
  }));
