import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';
import PersonRole from 'types/PersonRole';
import { getApplications } from 'store/developer/applications/selectors';
import { getTokenRole } from 'store/auth/selectors';

export const getPersonRole = createSelector(
  (state: AppState) => state.person,
  ({ role }) => role
);

export const getAvailableRoles = createSelector(
  getApplications,
  getTokenRole,
  (applications, tokenRole) => {
    const roles: PersonRole[] = ['user'];
    // Add `developer` role.
    applications.length && roles.push('developer');
    // Add `moderator` or `administrator` role.
    tokenRole && roles.push(tokenRole);
    return roles;
  }
);
