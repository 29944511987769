import { createAsyncAction } from 'typesafe-actions';

import * as api from 'services/api';

import PersonMatchingDTO from 'types/PersonMatchingDTO';
import { AppThunk } from 'store';

export const fetchPersonMatchingAsync = createAsyncAction(
  'user/FETCH_PERSON_MATCHING_REQUEST',
  'user/FETCH_PERSON_MATCHING_SUCCESS',
  'user/FETCH_PERSON_MATCHING_FAILURE'
)<void, PersonMatchingDTO[], any>();

export const acceptPersonMatchingAsync = createAsyncAction(
  'user/ACCEPT_PERSON_MATCHING_REQUEST',
  'user/ACCEPT_PERSON_MATCHING_SUCCESS',
  'user/ACCEPT_PERSON_MATCHING_FAILURE'
)<void, PersonMatchingDTO['id'], any>();

export const declinePersonMatchingAsync = createAsyncAction(
  'user/DECLINE_PERSON_MATCHING_REQUEST',
  'user/DECLINE_PERSON_MATCHING_SUCCESS',
  'user/DECLINE_PERSON_MATCHING_FAILURE'
)<void, PersonMatchingDTO['id'], any>();

/**
 * Загружает персоны приложений совпадающие с пользователем.
 * @param token Токен персоны.
 */
export const fetchPersonMatching = (
  token: string
): AppThunk<Promise<PersonMatchingDTO[]>> => async dispatch => {
  try {
    dispatch(fetchPersonMatchingAsync.request());
    const response = await api.getPersonMatching(token);
    dispatch(fetchPersonMatchingAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchPersonMatchingAsync.failure(error));
    throw error;
  }
};

/**
 * Принимает входящий реквест.
 * @param id ID реквеста.
 * @param token Токен персоны.
 */
export const acceptPersonMatching = (
  id: PersonMatchingDTO['id'],
  token: string
): AppThunk => async dispatch => {
  try {
    dispatch(acceptPersonMatchingAsync.request());
    await api.acceptPersonMatching(id, token);
    dispatch(acceptPersonMatchingAsync.success(id));
  } catch (error) {
    console.error(error);
    dispatch(acceptPersonMatchingAsync.failure(error));
    throw error;
  }
};

/**
 * Отклоняет входящий реквест.
 * @param id ID реквеста.
 * @param token Токен персоны.
 */
export const declinePersonMatching = (
  id: PersonMatchingDTO['id'],
  token: string
): AppThunk => async dispatch => {
  try {
    dispatch(declinePersonMatchingAsync.request());
    await api.declinePersonMatching(id, token);
    dispatch(declinePersonMatchingAsync.success(id));
  } catch (error) {
    console.error(error);
    dispatch(declinePersonMatchingAsync.failure(error));
    throw error;
  }
};
