import { createReducer } from 'typesafe-actions';
import { RequestsState, RequestsAction } from './types';
import {
  fetchRequestsAsync,
  fetchRequestAsync,
  acceptRequestAsync,
  declineRequestAsync,
  setRequestsFilter
} from './actions';

export const initialState: RequestsState = {
  count: 0,
  byId: {},
  ids: [],
  isPending: false,
  filter: null
};

export default createReducer<RequestsState, RequestsAction>(initialState)
  .handleAction(fetchRequestsAsync.request, state => ({
    ...state,
    isPending: true
  }))
  .handleAction(fetchRequestsAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    byId: payload.records.reduce(
      (acc, request) => ({
        ...acc,
        [request.id]: { ...acc[request.id], ...request }
      }),
      state.byId
    ),
    ids: payload.records.map(request => request.id),
    count: payload.count ?? state.count
  }))
  .handleAction(fetchRequestAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    byId: {
      ...state.byId,
      [payload.id]: {
        ...state.byId[payload.id],
        ...payload
      }
    }
  }))
  .handleAction(acceptRequestAsync.success, (state, { payload }) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload]: {
        ...state.byId[payload],
        decision: 'accept'
      }
    }
  }))
  .handleAction(declineRequestAsync.success, (state, { payload }) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload]: {
        ...state.byId[payload],
        decision: 'decline'
      }
    }
  }))
  .handleAction(setRequestsFilter, (state, { payload }) => ({
    ...state,
    filter: payload
  }));
