import { createAsyncAction, createAction } from 'typesafe-actions';
import * as api from 'services/api';
import { getErrorMessage } from 'services/network';
import AuthResponseDTO from 'types/AuthResponseDTO';
import { AppThunk } from 'store';

export const authPersonAsync = createAsyncAction(
  'AUTH_PERSON_REQUEST',
  'AUTH_PERSON_SUCCESS',
  'AUTH_PERSON_FAILURE'
)<void, AuthResponseDTO, any>();

/**
 * Авторизует персону.
 * @param code OAuth код.
 */
export const authPerson = (
  code: string
): AppThunk<Promise<string>> => async dispatch => {
  try {
    dispatch(authPersonAsync.request());
    const response = await api.getPersonToken(code);
    dispatch(
      authPersonAsync.success({
        id: Number(response.headers['x-person-id']),
        role: response.headers['x-person-role'],
        token: response.data
      })
    );
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(authPersonAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

/**
 * Logout персоны.
 */
export const logout = createAction('LOGOUT');
