import React from 'react';
import clsx from 'clsx';

import './data-row.scss';

export type DataItem = {
  /** Контент в хедере. */
  header?: React.ReactNode;
  /** Контент в теле. */
  body?: React.ReactNode;
  /** Пропсы для хедера. */
  headerProps?: React.ComponentProps<'div'>;
  /** Пропсы для тела. */
  bodyProps?: React.ComponentProps<'div'>;
} & React.ComponentProps<'div'>;

type Props = {
  items?: DataItem[];
} & React.ComponentProps<'div'>;

const DataRow: React.FC<Props> = ({ className, items = [], ...restProps }) => (
  <div className={clsx('data-row', className)} {...restProps}>
    {items.map(
      (
        {
          header,
          body,
          headerProps: { className: headerClassName, ...restHeaderProps } = {},
          bodyProps: { className: bodyClassName, ...restBodyProps } = {},
          className: itemClassName,
          ...restProps
        },
        i
      ) => (
        <div
          key={i}
          className={clsx('data-row__item', itemClassName)}
          {...restProps}
        >
          <div
            className={clsx('data-row__item-header', headerClassName)}
            {...restHeaderProps}
          >
            {header}
          </div>
          <div
            className={clsx('data-row__item-body', bodyClassName)}
            {...restBodyProps}
          >
            {body}
          </div>
        </div>
      )
    )}
  </div>
);

export default DataRow;

export const getItemVisibility = (visible: any) =>
  visible ? undefined : 'hidden';
