import { createReducer } from 'typesafe-actions';
import { ValuesState, ValuesAction } from './types';
import { fetchCollectionValuesAsync, fetchFileValueSuccess } from './actions';

export const initialState: ValuesState = {
  isPending: false,
  values: [],
  fields: {}
};

export default createReducer<ValuesState, ValuesAction>(initialState)
  .handleAction(fetchCollectionValuesAsync.request, () => ({
    ...initialState,
    isPending: true
  }))
  .handleAction(fetchCollectionValuesAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    fields: payload.reduce(
      (acc, { field_object }) => ({ ...acc, [field_object.id]: field_object }),
      state.fields
    ),
    values: payload.map(value => [value.field, value.values])
  }))
  .handleAction(fetchFileValueSuccess, (state, { payload, meta }) => ({
    ...state,
    values: state.values.map(([fieldId, values]) => [
      fieldId,
      values.map(value =>
        value.id === meta ? { ...value, file: payload } : value
      )
    ])
  }));
