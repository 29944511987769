import { createReducer } from 'typesafe-actions';
import storage from 'redux-persist/lib/storage';
import { CurrentApplicationState, CurrentApplicationAction } from './types';
import { setCurrentApplication } from './actions';

export const currentApplicationPersistConfig = {
  key: 'developer/currentApplication',
  whitelist: ['id'],
  storage
};

export const initialState: CurrentApplicationState = {};

export default createReducer<CurrentApplicationState, CurrentApplicationAction>(
  initialState
).handleAction(setCurrentApplication, (state, { payload }) => ({
  ...state,
  id: payload
}));
