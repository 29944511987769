import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import { AppThunk } from 'store';

export const fetchCountersAsync = createAsyncAction(
  'admin/FETCH_COUNTERS_REQUEST',
  'admin/FETCH_COUNTERS_SUCCESS',
  'admin/FETCH_COUNTERS_FAILURE'
)<void, OpenAPI.ResponseDashboardCounters, any>();

/**
 * Загружает счётчики.
 * @param token Токен пользователя.
 */
export const fetchCounters = (
  token: string
): AppThunk<Promise<OpenAPI.ResponseDashboardCounters>> => async dispatch => {
  try {
    dispatch(fetchCountersAsync.request());
    const response = await api.getAdminDashboardCounters(token);
    dispatch(fetchCountersAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCountersAsync.failure(error));
    throw error;
  }
};
