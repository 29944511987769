import React, { useMemo, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select/src/types';

import CollectionDTO from 'types/CollectionDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import CollectionAccessType from 'types/CollectionAccessType';

import Modal from 'components/Modal';
import Heading from 'components/Heading';
import CollectionListItem from 'components/CollectionListItem';
import ApplicationSearch from 'components/ApplicationSearch';
import Button from 'components/Button';
import Label from 'components/Label';
import Radio from 'components/Radio';
import CollectionPermission from 'components/CollectionPermission';

import {
  getAccessTypeRadioOptions,
  getAccessTypeOrder
} from 'services/collections';

import './collection-access-popup.scss';

export type CollectionAccessValues = {
  permissions: OpenAPI.RequestCollectionPermission[];
  access_type: CollectionAccessType;
};

type Props = {
  collection: CollectionDTO;
  application: ApplicationDTO;
  applications: ApplicationDTO[];
  permissions?: OpenAPI.ResponseCollectionPermission[];
  onSave?: (values: CollectionAccessValues) => any;
  isPending?: boolean;
  error?: React.ReactNode;
} & React.ComponentProps<typeof Modal>;

const CollectionAccessPopup: React.FC<Props> = ({
  className,
  collection,
  application,
  applications,
  permissions,
  onSave,
  isPending,
  error,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [applicationInput, setApplicationInput] = useState<string>('');
  const [localPermissions, setLocalPermissions] = useState<
    OpenAPI.RequestCollectionPermission[]
  >(permissions || []);
  const [accessType, setAccessType] = useState<CollectionAccessType>(
    collection.access_type
  );

  const applicationsMap = useMemo<Partial<Record<string, ApplicationDTO>>>(
    () =>
      applications.reduce(
        (acc, application) => ({ ...acc, [application.id]: application }),
        {}
      ),
    [applications]
  );

  const applicationsOptions = useMemo(
    () =>
      applications.filter(
        application =>
          !localPermissions.find(
            localPermissions => localPermissions.application === application.id
          ) && application.id !== collection.application
      ),
    [applications, collection.application, localPermissions]
  );

  const handleAddPermission = useCallback(
    (application: ValueType<ApplicationDTO>) =>
      setLocalPermissions(prev => [
        ...prev,
        {
          application: (application as ApplicationDTO).id,
          can_write: false
        }
      ]),
    []
  );

  const handleInputChange = useCallback(
    (value: string) => setApplicationInput(value),
    []
  );

  const handleAccessTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setAccessType(event.target.value as CollectionAccessType),
    []
  );

  const handleChangePermission = useCallback(
    (permission: OpenAPI.RequestCollectionPermission, can_write: boolean) => {
      setLocalPermissions(prev =>
        prev.map(item => (item === permission ? { ...item, can_write } : item))
      );
    },
    []
  );

  const handleDeletePermission = useCallback(
    (permission: OpenAPI.RequestCollectionPermission) =>
      setLocalPermissions(prev => prev.filter(item => item !== permission)),
    []
  );

  const handleSave = useCallback(
    () =>
      typeof onSave !== 'undefined' &&
      onSave({ permissions: localPermissions, access_type: accessType }),
    [accessType, localPermissions, onSave]
  );

  useEffect(() => {
    permissions && setLocalPermissions(permissions);
  }, [permissions]);

  return (
    <Modal
      className={clsx('collection-access-popup', className)}
      {...restProps}
    >
      <div className="collection-access-popup__header">
        <Heading className="collection-access-popup__title">
          {t('Доступ к коллекции')}
        </Heading>
        <CollectionListItem
          showAccess={false}
          collection={collection}
          application={application}
        />
        <div className="collection-access-popup__types">
          {getAccessTypeRadioOptions(collection.access_type).map(option => (
            <Label className="collection-access-popup__type" key={option.value}>
              <Radio
                onChange={handleAccessTypeChange}
                value={option.value}
                checked={option.value === accessType}
                disabled={option.disabled}
              >
                {option.label}
              </Radio>
            </Label>
          ))}
        </div>
      </div>
      <div className="collection-access-popup__body">
        {getAccessTypeOrder(accessType) <
          getAccessTypeOrder('public_write') && (
          <div className="collection-access-popup__permissions">
            <div className="collection-access-popup__permissions-title">
              {t('Индивидуальный доступ')}
            </div>
            <div className="collection-access-popup__applications">
              <ApplicationSearch
                options={applicationsOptions}
                className="collection-access-popup__applications-search"
                value={null}
                inputValue={applicationInput}
                onInputChange={handleInputChange}
                onChange={handleAddPermission}
              />
              <ul className="collection-access-popup__permissions-list">
                {localPermissions.map((permission, i) => (
                  <li
                    key={i}
                    className="collection-access-popup__permissions-list-item"
                  >
                    <CollectionPermission
                      permission={permission}
                      application={applicationsMap[permission.application]!}
                      onChange={handleChangePermission}
                      onDelete={handleDeletePermission}
                      accessType={accessType}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className="collection-access-popup__tools">
          <Button
            disabled={isPending}
            className="collection-access-popup__save"
            variant="contained"
            theme="primary"
            onClick={handleSave}
          >
            {t('Сохранить настройки')}
          </Button>
          {error && (
            <div className="collection-access-popup__error">{error}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CollectionAccessPopup;
