import { createReducer } from 'typesafe-actions';
import { DictionaryState, DictionaryAction } from './types';
import { editDictionaryAsync, fetchDictionaryAsync } from './actions';

export const initialState: DictionaryState = {
  isPending: false
};

export default createReducer<DictionaryState, DictionaryAction>(initialState)
  .handleAction(
    [editDictionaryAsync.request, fetchDictionaryAsync.request],
    state => ({
      ...state,
      isPending: true
    })
  )
  .handleAction(
    [editDictionaryAsync.failure, fetchDictionaryAsync.failure],
    (state, { payload }) => ({
      ...state,
      error: payload,
      isPending: false
    })
  )
  .handleAction(
    [fetchDictionaryAsync.success, editDictionaryAsync.success],
    state => ({
      ...state,
      isPending: false
    })
  );
