import { createReducer } from 'typesafe-actions';
import { DictionaryState, DictionaryAction } from './types';
import { createMergeAsync, fetchDictionaryAsync } from './actions';

export const initialState: DictionaryState = {
  isPending: false
};

export default createReducer<DictionaryState, DictionaryAction>(initialState)
  .handleAction(
    [createMergeAsync.request, fetchDictionaryAsync.request],
    state => ({
      ...state,
      isPending: true,
      error: initialState.error
    })
  )
  .handleAction(
    [createMergeAsync.success, fetchDictionaryAsync.success],
    state => ({
      ...state,
      isPending: false
    })
  )
  .handleAction(
    [createMergeAsync.failure, fetchDictionaryAsync.failure],
    (state, { payload }) => ({
      ...state,
      isPending: false,
      error: payload
    })
  );
