import React from 'react';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';

import './textarea.scss';

// Exclude invalid ref type.
type Props = Omit<React.ComponentProps<typeof TextareaAutosize>, 'ref'>;

const Textarea: React.FC<Props> = ({ className, ...restProps }) => (
  <TextareaAutosize
    minRows={3}
    maxRows={5}
    className={clsx('textarea', className)}
    {...restProps}
  />
);

export default Textarea;
