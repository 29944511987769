import { createReducer } from 'typesafe-actions';
import { PersonsState, PersonsAction, PersonsType } from './types';
import {
  setPersonsType,
  fetchPersonsAsync,
  fetchPersonAsync,
  fetchAppPersonAsync
} from './actions';

export const initialState: PersonsState = {
  isPending: false,
  type: PersonsType.PERSONS,
  personsById: {},
  appPersonsById: {}
};

export default createReducer<PersonsState, PersonsAction>(initialState)
  .handleAction(setPersonsType, (state, { payload }) => ({
    ...state,
    type: payload
  }))
  .handleAction(fetchPersonsAsync.request, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(fetchPersonsAsync.success, (state, { payload }) => ({
    ...state,
    isPending: false,
    results: payload
  }))
  .handleAction(fetchPersonsAsync.failure, state => ({
    ...state,
    isPending: false
  }))
  .handleAction(fetchPersonAsync.success, (state, { payload }) => ({
    ...state,
    personsById: {
      ...state.personsById,
      [payload.id]: {
        ...state.personsById[payload.id],
        ...payload
      }
    }
  }))
  .handleAction(fetchAppPersonAsync.success, (state, { payload }) => ({
    ...state,
    appPersonsById: {
      ...state.appPersonsById,
      [payload.id]: {
        ...state.appPersonsById[payload.id],
        ...payload
      }
    }
  }));
