import { createReducer, ActionType } from 'typesafe-actions';
import { CollectionsState, CollectionsAction } from './types';
import { fetchApplicationCollectionsAsync } from '../application/actions';
import { fetchCollectionAsync } from './actions';
import { readReportAsync } from '../reports/actions';

export const initialState: CollectionsState = {
  byId: {}
};

export default createReducer<
  CollectionsState,
  | CollectionsAction
  | ActionType<
      | typeof fetchApplicationCollectionsAsync['success']
      | typeof readReportAsync['success']
    >
>(initialState)
  .handleAction(
    fetchApplicationCollectionsAsync.success,
    (state, { payload }) => ({
      ...state,
      byId: payload.records.reduce(
        (acc, collection) => ({
          ...acc,
          [collection.id]: {
            ...acc[collection.id],
            ...collection
          }
        }),
        state.byId
      )
    })
  )
  .handleAction(fetchCollectionAsync.success, (state, { payload }) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: {
        ...state.byId[payload.id],
        ...payload
      }
    }
  }))
  .handleAction(readReportAsync.success, (state, { payload }) => ({
    ...state,
    // SLOW :c
    byId: Object.keys(state.byId).reduce(
      (acc, id) => ({
        ...acc,
        [id]: {
          ...state.byId[id]!,
          messages_list: state.byId[id]?.messages_list?.map(message =>
            message.id === payload
              ? { ...message, viewed_at: new Date().toISOString() }
              : message
          )
        }
      }),
      state.byId
    )
  }));
