import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getApplications } from 'store/developer/applications/selectors';
import { AppState } from 'store/rootReducer';

import {
  closeCollectionAccess,
  fetchCollectionAccess,
  editCollectionAccess
} from 'store/developer/collectionAccess/actions';
import { getCurrentApplication } from 'store/developer/currentApplication/selectors';
import { AppDispatch } from 'store';

import useApplication from 'hooks/useApplication';

import CollectionAccessPopup, {
  CollectionAccessValues
} from './CollectionAccessPopup';

type Props = Omit<
  React.ComponentProps<typeof CollectionAccessPopup>,
  'applications' | 'collection' | 'application' | 'isOpen'
>;

const ConnectedCollectionAccessPopup: React.FC<Props> = props => {
  const dispatch = useDispatch<AppDispatch>();
  const isPending = useSelector(
    (state: AppState) => state.developer.collectionAccess.isPending
  );
  const error = useSelector(
    (state: AppState) => state.developer.collectionAccess.error
  );
  const id = useSelector(
    (state: AppState) => state.developer.collectionAccess.id
  );
  const collection = useSelector(
    (state: AppState) => state.developer.collections.byId[id!]
  );
  const application = useApplication(collection && collection.application)!;
  const currentApplication = useSelector(getCurrentApplication);
  const applicationsList = useSelector(getApplications);
  const handleClose = useCallback(() => dispatch(closeCollectionAccess()), [
    dispatch
  ]);

  const handleSave = useCallback(
    (values: CollectionAccessValues) =>
      dispatch(
        editCollectionAccess(values, currentApplication!.access_token)
      ).then(handleClose),
    [currentApplication, dispatch, handleClose]
  );

  useEffect(() => {
    if (id && currentApplication) {
      dispatch(fetchCollectionAccess(id, currentApplication.access_token));
    }
  }, [currentApplication, dispatch, id]);

  return collection ? (
    <CollectionAccessPopup
      isOpen={!!id}
      collection={collection}
      application={application}
      applications={applicationsList}
      isPending={isPending}
      onRequestClose={handleClose}
      permissions={collection.permissions}
      onSave={handleSave}
      error={error}
      {...props}
    />
  ) : null;
};

export default ConnectedCollectionAccessPopup;
