import { combineReducers } from 'redux';

import { PersonMatchingState } from './personMatching/types';
import { ReportState } from './report/types';
import { CollectionsState } from './collections/types';
import { ValuesState } from './values/types';
import { ApplicationsState } from './applications/types';

import personMatching from './personMatching/reducer';
import report from './report/reducer';
import collections from './collections/reducer';
import values from './values/reducer';
import applications from './applications/reducer';

export type UserState = {
  personMatching: PersonMatchingState;
  report: ReportState;
  collections: CollectionsState;
  values: ValuesState;
  applications: ApplicationsState;
};

export default combineReducers<UserState>({
  personMatching,
  report,
  collections,
  values,
  applications
});
