import { ComponentProps } from 'react';
import Input from 'components/Input';
import createFieldControl from 'helpers/createFieldControl';

/**
 * Обертка над `Input` для использования c `react-final-form`.
 */
const FieldInput = createFieldControl<
  string,
  HTMLInputElement,
  ComponentProps<typeof Input>
>(Input);

export default FieldInput;
