import { useEffect, createContext, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DictionaryDTO from 'types/DictionaryDTO';

import { getCurrentApplication } from 'store/developer/currentApplication/selectors';
import { AppState } from 'store/rootReducer';
import { fetchDictionary } from 'store/developer/dictionary/actions';
import { AppDispatch } from 'store';

/**
 * Контекст для `useDictionary`.
 * Хранит id справочников, которые на данный момент запрашиваются.
 */
const UseDictionaryContext = createContext(
  new Map<DictionaryDTO['id'], void>()
);

/**
 * Хук для получения информации о справочнике.
 * @param id ID справочника.
 */
export default function useDictionary(id?: DictionaryDTO['id']) {
  const dispatch = useDispatch<AppDispatch>();
  const dictionary = useSelector(
    (state: AppState) => state.developer.dictionaries.byId[id!]
  );
  const currentApplication = useSelector(getCurrentApplication)!;
  const context = useContext(UseDictionaryContext);

  useEffect(() => {
    if (
      currentApplication &&
      id &&
      !context.has(id) &&
      (!dictionary || !dictionary.elements)
    ) {
      context.set(id);
      dispatch(
        fetchDictionary(id, currentApplication.access_token)
      ).finally(() => context.delete(id));
    }
  }, [context, currentApplication, dictionary, dispatch, id]);

  return dictionary;
}
