import React from 'react';
import clsx from 'clsx';

import './loader.scss';
import { ReactComponent as LoaderIcon } from './assets/loader.svg';

type Props = React.ComponentProps<'svg'>;

const Loader: React.FC<Props> = ({ className, ...restProps }) => (
  <LoaderIcon className={clsx('loader', className)} {...restProps} />
);

export default Loader;
