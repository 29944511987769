import { createAsyncAction, createAction } from 'typesafe-actions';
import { AppThunk } from 'store';
import * as api from 'services/api';
import AdminValuesParams from 'types/AdminValuesParams';
import { getErrorMessage, toCountableRecords } from 'services/network';
import CountableRecords from 'types/CountableRecords';
import { getValuesCount } from 'services/values';

export const resetCollectionState = createAction(
  'admin/RESET_COLLECTION_STATE'
);

export const fetchCompareFunctionsAsync = createAsyncAction(
  'admin/FETCH_COMPARE_FUNCTIONS_REQUEST',
  'admin/FETCH_COMPARE_FUNCTIONS_SUCCESS',
  'admin/FETCH_COMPARE_FUNCTIONS_FAILURE'
)<void, OpenAPI.ValuesCompareFunctions[], any>();

/**
 * Загружает функции для сравнения значений полей.
 * @param token Токен пользователя.
 */
export const fetchCompareFunctions = (
  token: string
): AppThunk<Promise<OpenAPI.ValuesCompareFunctions[]>> => async dispatch => {
  try {
    dispatch(fetchCompareFunctionsAsync.request());
    const response = await api.getAdminCompareFunctions(token);
    dispatch(fetchCompareFunctionsAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCompareFunctionsAsync.failure(error));
    throw error;
  }
};

export const fetchCollectionValuesAsync = createAsyncAction(
  'admin/FETCH_COLLECTION_VALUES_REQUEST',
  'admin/FETCH_COLLECTION_VALUES_SUCCESS',
  'admin/FETCH_COLLECTION_VALUES_FAILURE'
)<void, CountableRecords<OpenAPI.ResponseValues>, string>();

/**
 * Загружает значения коллекции.
 * @param id ID коллекции.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const fetchCollectionValues = (
  id: OpenAPI.Parameters.CollectionId,
  token: string,
  params?: AdminValuesParams
): AppThunk<Promise<
  CountableRecords<OpenAPI.ResponseValues>
>> => async dispatch => {
  try {
    dispatch(fetchCollectionValuesAsync.request());
    const response = await api.getAdminCollectionValues(id, token, params);
    const data = toCountableRecords(response);
    dispatch(fetchCollectionValuesAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionValuesAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

export const fetchCollectionValuesMoreAsync = createAsyncAction(
  'admin/FETCH_COLLECTION_VALUES_MORE_REQUEST',
  'admin/FETCH_COLLECTION_VALUES_MORE_SUCCESS',
  'admin/FETCH_COLLECTION_VALUES_MORE_FAILURE'
)<void, CountableRecords<OpenAPI.ResponseValues>, string>();

/**
 * Загружает ещё значения коллекции.
 * @param id ID коллекции.
 * @param token Токен пользователя.
 * @param params Параметры.
 */
export const fetchCollectionValuesMore = (
  id: OpenAPI.Parameters.CollectionId,
  token: string,
  params?: AdminValuesParams
): AppThunk<Promise<CountableRecords<OpenAPI.ResponseValues>>> => async (
  dispatch,
  getState
) => {
  try {
    const values = getState().admin.collection.values;
    dispatch(fetchCollectionValuesMoreAsync.request());
    const response = await api.getAdminCollectionValues(id, token, {
      ...params,
      offset: (params?.offset || 0) + (values ? getValuesCount(values) : 0)
    });
    const data = toCountableRecords(response);
    dispatch(fetchCollectionValuesMoreAsync.success(data));
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionValuesMoreAsync.failure(getErrorMessage(error)));
    throw error;
  }
};
