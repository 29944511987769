import i18n from 'i18n';

/**
 * Возвращает имя категории.
 * @param category Категория.
 */
export const getCategoryName = (category?: OpenAPI.ResponseCategory) =>
  category ? category.name : i18n.t('Не указана');

/**
 * Возвращает массив опций для выбора фильтра категории.
 * @param categories Категории.
 */
export const getCategoriesOptions = (
  categories: OpenAPI.ResponseCategory[]
) => [{ id: undefined, name: i18n.t('Все категории') }, ...categories];

/**
 * Возвращает массив опций для выбора категории справочника/коллекции.
 * @param categories Категории.
 */
export const getSelectableCategoriesOptions = (
  categories: OpenAPI.ResponseCategory[]
) => [{ id: null, name: i18n.t('Без категории') }, ...categories];
