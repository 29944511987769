import {
  createAction,
  createAsyncAction,
  createStandardAction
} from 'typesafe-actions';

import omit from 'lodash/omit';

import CollectionDTO from 'types/CollectionDTO';
import ApplicationDTO from 'types/ApplicationDTO';
import * as api from 'services/api';
import { CollectionAccessValues } from 'components/CollectionAccessPopup/CollectionAccessPopup';
import { getAccessTypeOrder } from 'services/collections';
import { getErrorMessage } from 'services/network';
import { AppThunk } from 'store';

export const fetchCollectionAccessAsync = createAsyncAction(
  'developer/FETCH_COLLECTION_ACCESS_REQUEST',
  'developer/FETCH_COLLECTION_ACCESS_SUCCESS',
  'developer/FETCH_COLLECTION_ACCESS_FAILURE'
)<void, CollectionDTO, any>();

export const editCollectionAccessAsync = createAsyncAction(
  'developer/EDIT_COLLECTION_ACCESS_REQUEST',
  'developer/EDIT_COLLECTION_ACCESS_SUCCESS',
  'developer/EDIT_COLLECTION_ACCESS_FAILURE'
)<void, CollectionDTO, string>();

/**
 * Загружает информацию о правах коллекции.
 * @param id ID коллекции.
 * @param access_token Токен приложения.
 */
export const fetchCollectionAccess = (
  id: CollectionDTO['id'],
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<CollectionDTO>> => async dispatch => {
  try {
    dispatch(fetchCollectionAccessAsync.request());
    const response = await api.getCollection(id, access_token);
    dispatch(fetchCollectionAccessAsync.success(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(fetchCollectionAccessAsync.failure(error));
    throw error;
  }
};

/**
 * Обновляет права коллекции.
 * @param values Значения прав доступа.
 * @param access_token Токен приложения.
 */
export const editCollectionAccess = (
  values: CollectionAccessValues,
  access_token: ApplicationDTO['access_token']
): AppThunk<Promise<CollectionDTO | undefined>> => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const data =
      state.developer.collections.byId[state.developer.collectionAccess.id!];

    if (!data) return;

    dispatch(editCollectionAccessAsync.request());

    if (
      getAccessTypeOrder(values.access_type) <
      getAccessTypeOrder('public_write')
    ) {
      // Создание/обновление/удаление permissions.
      const permissionsMap = data.permissions!.reduce<
        Partial<Record<number, OpenAPI.ResponseCollectionPermission>>
      >((acc, permission) => ({ ...acc, [permission.id]: permission }), {});

      const createOrUpdate = values.permissions.map(async permission => {
        const original =
          permissionsMap[
            (permission as OpenAPI.ResponseCollectionPermission).id
          ];

        if (!original) {
          return api.createCollectionPermission(
            data.id,
            permission,
            access_token
          );
        } else if (original.can_write !== permission.can_write) {
          // TODO: PUT?
          await api.deleteCollectionPermission(
            data.id,
            original.id,
            access_token
          );

          return api.createCollectionPermission(
            data.id,
            permission,
            access_token
          );
        }
      });

      const deletion = Object.keys(
        omit(
          permissionsMap,
          values.permissions.map(
            permission =>
              (permission as OpenAPI.ResponseCollectionPermission).id
          )
        )
      ).map(permissionId =>
        api.deleteCollectionPermission(
          data.id,
          Number(permissionId),
          access_token
        )
      );

      await Promise.all([...createOrUpdate, ...deletion]);
    }

    const response = await api.updateCollection(
      data.id,
      { access_type: values.access_type },
      access_token
    );

    dispatch(editCollectionAccessAsync.success(response.data));

    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(editCollectionAccessAsync.failure(getErrorMessage(error)));
    throw error;
  }
};

/**
 * Открывает права коллекции.
 * @param payload ID коллекции.
 */
export const openCollectionAccess = createStandardAction(
  'developer/OPEN_COLLECTION_ACCESS'
)<CollectionDTO['id']>();

/**
 * Закрывает права коллекции.
 */
export const closeCollectionAccess = createAction(
  'developer/CLOSE_COLLECTION_ACCESS'
);
